import SelectInput from "components/Inputs/SelectInput";
import React, { useEffect, useMemo, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import PrimaryInput from "components/Inputs/PrimaryInput";
import SecondaryInput from "components/Inputs/SecondaryInput";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { CreateSegmentSucess } from "../CreateSegmentSuccess";
import { segmentation } from "services/contactService";
import { useMutation, useQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import { getState } from "services/locationService";
import { checkInArray } from "lib/checkInArray";
import Checkbox from "components/Inputs/CheckBox";
import { HiOutlineChevronDown } from "react-icons/hi2";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .matches(/^\S*$/, "Name should not contain any whitespace")
    .required("Please complete this field."),
});

const enumeration = {
  booleanCondition: [
    { label: "options", value: "options" },
    { label: "is", value: "is" },
    { label: "is not", value: "isNot'" },
  ],
  multipleBoolean: [
    { label: "options", value: "options" },
    { label: "is", value: "is" },
    { label: "is not", value: "isNot'" },
    { label: "Contains", value: "contains" },
    { label: "Does not contain", value: "doesNotContain" },
  ],
  textBoolean: [
    { label: "options", value: "options" },
    { label: "bool", value: "bool" },
    { label: "range", value: "range" },
  ],
  locationBoolean: [
    { label: "options", value: "options" },
    { label: "In", value: "in" },
    { label: "Not in", value: "Not in" },
  ],
};

const option1 = [{ value: "", label: "Loading..." }];

const ManualSegment = () => {
  const navigate = useNavigate();
  const { sidebar } = useSideBarStore();
  const [selectedData, setSelectedData] = useState();
  const [openModal, setOpenModal] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);

  const { data: stateData } = useQuery(["getState"], () => getState("Nigeria"), {});
  const state = useMemo(() => {
    return stateData !== undefined
      ? stateData?.data?.states?.map((el) => ({
          label: el.name,
          value: el.name,
        }))
      : [];
  }, [stateData]);

  const filterOptions = [
    {
      id: 0,
      value: "values",
      label: "Select filter",
      condition: [],
      options: [],
    },
    {
      id: 1,
      value: "gender",
      label: "Gender",
      condition: enumeration.booleanCondition,
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
    },
    {
      id: 2,
      value: "age",
      label: "Age",
      condition: enumeration.booleanCondition,
      options: "date",
    },

    // {
    //   id: 4,
    //   value: "email",
    //   label: "Email address",
    //   condition: enumeration.multipleBoolean,
    //   options: "text",
    // },
    // {
    //   id: 3,
    //   value: "gender",
    //   label: "Gender",
    //   condition: enumeration.booleanCondition,
    //   options: [
    //     { label: "Male", value: "male" },
    //     { label: "Female", value: "female" },
    //   ],
    // },
    { id: 4, value: "location", label: "Location", condition: enumeration.locationBoolean, options: "location" },
    // {
    //   id: 5,
    //   value: "engagement level",
    //   label: "Engagement levels",
    //   condition: enumeration.booleanCondition,
    //   options: [
    //     { label: "Active", value: "active" },
    //     { label: "Inactive", value: "inactive" },
    //     { label: "Active lead", value: "active lead" },
    //     { label: "Inactive lead", value: "inactive lead" },
    //   ],
    // },
    // {
    //   id: 5,
    //   value: "recent purchase history",
    //   label: "Recent purchase history",
    //   condition: enumeration.booleanCondition,
    //   options: [
    //     { label: "Active", value: "active" },
    //     { label: "Inactive", value: "inactive" },
    //     { label: "Active lead", value: "active lead" },
    //     { label: "Inactive lead", value: "inactive lead" },
    //   ],
    // },
    // {
    //   id: 8,
    //   value: "eSubscriptionStatus",
    //   label: "email subscription status",
    //   condition: enumeration.booleanCondition,
    //   options: [
    //     { label: "Subscribed", value: "subscribed" },
    //     { label: "Unsubscribed", value: "unsubscribed" },
    //   ],
    // },
    // {
    //   id: 9,
    //   value: "tag",
    //   label: "Tag",
    //   condition: enumeration.booleanCondition,
    //   options: [
    //     { label: "Friends", value: "friends" },
    //     { label: "Family", value: "family" },
    //     { label: "Church goers", value: "church goers" },
    //     { label: "General people", value: "General people" },
    //   ],
    // },
    // {
    //   id: 10,
    //   value: "SMS subscription status",
    //   label: "SMS subscription status",
    //   condition: enumeration.booleanCondition,
    //   options: [
    //     { label: "Subscribed", value: "subscribed" },
    //     { label: "Unsubscribed", value: "unsubscribed" },
    //   ],
    // },
  ];

  console.log(selectedData);

  const handleSelectedOption = (state) => {
    setSelectedStates((prevStates) => {
      if (prevStates.includes(state)) {
        return prevStates.filter((item) => item !== state);
      } else {
        return [...prevStates, state];
      }
    });
  };

  const { mutate: mutateSegment } = useMutation(segmentation, {
    onSuccess: () => {
      Toast.success("segmentation created");
      handleSave(2);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const payload = {
      segmentName: data.name,
      fieldsSelector: data.fieldsSelector,
    };
    console.log(payload);
    // mutateSegment(payload);
  };

  const { handleChange, values, handleBlur, handleSubmit, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      name: "",
      description: "",
      fieldsSelector: [{ field: "", values: [""], condition: "" }],
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const disableButton = values.fieldsSelector.some(
    (filterObj) => !filterObj.field || !filterObj.values || !filterObj.condition,
  );

  const handleSave = (id) => {
    setOpenModal(openModal === id ? null : id);
  };
  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };

  const filterOptionData = (searchTerm) => {
    const data = filterOptions?.filter((item) => {
      const options = item?.value?.toLocaleLowerCase();
      return options.includes(searchTerm?.toLocaleLowerCase());
    });

    setSelectedData(data);
  };

  const addFilter = () => {
    if (values.fieldsSelector.length < 3) {
      setFieldValue("fieldsSelector", [...values.fieldsSelector, { field: "", values: [""], condition: "" }]);
    } else {
      Toast.error("You cannot add more than 3 filters");
    }
  };

  const removeFilter = (id) => {
    setFieldValue(
      "fieldsSelector",
      values.fieldsSelector.filter((input) => input.field !== id),
    );
  };

  // useEffect(() => {
  //   const newData = values.fieldsSelector?.map((filterObj) =>
  //     filterOptions?.find((item) => item.value === filterObj.field),
  //   );
  //   setSelectedData(newData);
  // }, [values.fieldsSelector]);

  useEffect(() => {
    values.fieldsSelector.forEach((filterObj) => {
      filterOptionData(filterObj.field);
    });
  }, [values.fieldsSelector]);

  console.log(selectedStates, "state selected");

  return (
    <div className="border p-5 bg-white rounded-md">
      <div
        onClick={() => navigate(-1)}
        className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
      >
        <FiChevronLeft />
        <p>Go back</p>
      </div>
      <div className="flex items-start space-x-2">
        <div className="w-[24px] mt-2 h-[24px] rounded-full bg-primary-800 text-white flex items-center justify-center">
          <p>1</p>
        </div>
        <div>
          <p className="text-[20px] font-[500]">Create new segment</p>
          <p>Enter a name and a short description for your new segment below.</p>
        </div>
      </div>
      <form action="">
        <div className="mt-5 flex items-center space-x-5">
          <div className="w-full">
            <SecondaryInput
              text="Segment name"
              placeholder="Enter segment name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              name="name"
              error={getError("name")}
              type="text"
            />
          </div>
          <div className="w-full">
            <SecondaryInput
              text="Description (optional)"
              placeholder="Describe your segment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              name="description"
              error={getError("description")}
              type="text"
            />
          </div>
        </div>
        <hr className="my-5" />
        <div className="flex items-start space-x-2 mt-5">
          <div className="w-[24px] mt-2 h-[24px] rounded-full bg-primary-800 text-white flex items-center justify-center">
            <p>2</p>
          </div>
          <div>
            <p className="text-[20px] font-[500]">Add segment filters</p>
            <p>Add relevant filter to segment your contacts</p>
          </div>
        </div>
        {values.fieldsSelector.map((item, i) => (
          <div>
            <div className="mt-5 flex items-center space-x-5" key={i}>
              <div className="flex items-center space-x-2 w-[40%]">
                <div className="w-full">
                  <SelectInput
                    name={`fieldsSelector.${i + 1 - 1}.field`}
                    options={filterOptions}
                    values={item.field}
                    onChange={handleChange}
                  />
                </div>
                {/* {item.field !== "" ? (
                  <div className="border border-gray-200 bg-gray-100 p-5 h-[40px] px-3 rounded flex items-center justify-center w-[40%]">
                    <p className="text-[14px]">{item.field}</p>
                  </div>
                ) : null} */}
              </div>
              <div className="w-[60%]">
                {item?.field !== "" ? (
                  <div className="flex items-center space-x-3">
                    {item.field === "custom fields" ? (
                      <div>
                        <SelectInput
                          name={`fieldsSelector.${i + 1 - 1}.field`}
                          options={
                            selectedData === undefined
                              ? option1
                              : [
                                  { label: "Shoe size", value: "shoe size" },
                                  { label: "Salary", value: "Salary" },
                                  { label: "Dress size", value: "dress size" },
                                ]
                          }
                          value={item.field}
                          onChange={handleChange}
                        />
                      </div>
                    ) : null}
                    <div>
                      <SelectInput
                        name={`fieldsSelector.${i + 1 - 1}.condition`}
                        options={selectedData === undefined ? option1 : selectedData[0]?.condition}
                        value={item.condition}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-[60%] relative">
                      {selectedData !== undefined && selectedData[0]?.options === "text" ? (
                        <SecondaryInput
                          name={`fieldsSelector.${i + 1 - 1}.values`}
                          placeholder="text"
                          onChange={(e) => setFieldValue(`fieldsSelector.${i + 1 - 1}.values`, [e.target.value])}
                          onBlur={handleBlur}
                          type="text"
                          value={item.values}
                        />
                      ) : selectedData !== undefined && selectedData[0]?.options === "location" ? (
                        <>
                          <div
                            className="flex items-center justify-between border-[1px] border-gray-300 rounded p-2 py-[10px] cursor-pointer "
                            onClick={() => handleModal(3)}
                          >
                            <p className="text-xs">
                              {selectedStates?.length > 0 ? <>{selectedStates?.length} Selected </> : "Select states"}
                            </p>

                            <HiOutlineChevronDown />
                          </div>
                          {openModal === 3 ? (
                            <div className="absolute top-10 bg-white border rounded-b-md w-full h-[300px] overflow-y-auto">
                              {state?.map((el, i) => (
                                <div
                                  key={`${i}`}
                                  className={`text-xs cursor-pointer p-2 flex items-center  space-x-3 ${
                                    checkInArray(el.value, selectedStates)
                                      ? "border-b-[1px] border-white"
                                      : "hover:bg-slate-100"
                                  }`}
                                  onClick={() => handleSelectedOption(el.value)}
                                >
                                  <Checkbox checked={checkInArray(el.value, selectedStates)} />

                                  <p className="text-[12px]">{el.label}</p>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </>
                      ) : selectedData !== undefined && selectedData[0]?.options === "date" ? (
                        <SecondaryInput
                          name={`fieldsSelector.${i + 1 - 1}.values`}
                          placeholder="text"
                          onChange={(e) => setFieldValue(`fieldsSelector.${i + 1 - 1}.values`, [e.target.value])}
                          onBlur={handleBlur}
                          type="date"
                          value={item.values}
                        />
                      ) : (
                        <div>
                          <SelectInput
                            name={`fieldsSelector.${i + 1 - 1}.values`}
                            options={selectedData === undefined ? option1 : selectedData[0]?.options}
                            value={item.values}
                            onChange={(e) => setFieldValue(`fieldsSelector.${i + 1 - 1}.values`, [e.target.value])}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {item.field !== "" && values.fieldsSelector.length > 1 && (
                  <div className="w-fit p-2 border rounded" onClick={() => removeFilter(item.field)}>
                    <RiDeleteBin6Line />
                  </div>
                )}
              </div>
            </div>
            {item.field !== "" && values.fieldsSelector.length < 3 && (
              <div>
                <p className="w-fit px-5 py-2 border" onClick={() => addFilter()}>
                  And
                </p>
              </div>
            )}
          </div>
        ))}
      </form>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <div></div>

        <div className="flex justify-between space-x-4">
          <GreyButton text="Cancel" onClick={() => navigate(-1)} />

          <BlueButton
            text={`Save`}
            // css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
            disabled={disableButton}
            onClick={handleSubmit}
          />
        </div>
      </div>
      {openModal === 2 ? <CreateSegmentSucess handleModal={() => setOpenModal(null)} /> : null}
    </div>
  );
};

export default ManualSegment;
