import { useEffect, useState } from "react";
import WelcomeModal from "./WelcomeModal";
import { useStore } from "store";
import Typography from "utils/typography";
import { useQuery } from "@tanstack/react-query";
import { recentActivities } from "services/surveyService";

import RecentCampaignsCard from "./cards/RecentCampaignsCard";
import DashImage from "assets/images/dashImage.png";
import useWorkspace from "hooks/useWorkspace";
import { getLocations } from "services/locationService";

export default function DefaultDashboard() {
  const { data: wsData } = useWorkspace();

  const { data, isLoading } = useQuery(["recentActivities"], recentActivities);

  // const objectFilter = data?.joinedData?.map((el) => el.hasOwnProperty("surveyId"));

  const { data: locationData } = useQuery(["getLocations"], () => getLocations(""));

  console.log(locationData, "locationData");

  const { onBoarding, setOnBoardingCount, onBoardingCount } = useStore();
  const [displayOnboarding, setDisplayOnboarding] = useState(false);

  useEffect(() => {
    setOnBoardingCount(1);
  }, [setOnBoardingCount]);

  useEffect(() => {
    if (onBoarding?.importContacts === false) {
      setDisplayOnboarding(true);
      localStorage.setItem("modal", 1);
    } else {
      setDisplayOnboarding(false);
    }
  }, [onBoarding?.importContacts]);

  return (
    <div className="flex flex-col justify-between h-[100%]">
      {onBoardingCount < 3 && displayOnboarding ? (
        <WelcomeModal handleModal={() => setDisplayOnboarding(false)} />
      ) : null}

      <div className="h-[230px] bg-[#B6DCFF] rounded-md">
        <div className="flex h-[100%] bg-dashBg w-[100%] justify-between">
          <div className="w-[50%] p-6 px-[5em] flex flex-col space-y-3 justify-center">
            <div className="bg-white w-[170px] flex items-center justify-center rounded-md py-2">
              <Typography.MicroText className="text-primary-800 font-semibold">
                Create, explore, grow!
              </Typography.MicroText>
            </div>
            <Typography.H4 className="text-lg font-medium">
              Welcome back, <span className="">{wsData?.name}</span>
            </Typography.H4>

            <Typography className="text-gray-500">Explore our key features and get valuable insights.</Typography>
          </div>

          <div
            className="w-[50%] flex items-center
           justify-center "
          >
            <img src={DashImage} alt="DashImage" className="h-[100%]" />
          </div>
        </div>
      </div>

      {/* <div className="flex justify-between gap-[1.5em] h-[280px]">
        <div className="w-[50%]">
          <div className="flex items-center justify-between py-4">
            <Typography.Text>Audience</Typography.Text>

            <Link to="/contacts/all-contacts?tab=2">
              <Typography.SubText className="text-primary-800 font-medium">See all</Typography.SubText>
            </Link>
          </div>

          <div className="w-[100%]">
            <AudienceCard />
          </div>
        </div>

        <div className="w-[50%] h-[100%]">
          <div className="flex items-center justify-between py-4">
            <Typography.Text>Insights</Typography.Text>

            <p></p>
           
          </div>

          <div className="w-[100%] h-[calc(100%-60px)]">
            <InsightCard />
          </div>
        </div>
      </div> */}

      <div className="my-4">
        {/* <Typography.Text className="py-4">Recent campaigns</Typography.Text> */}

        <RecentCampaignsCard
          recentSurveys={data?.recentSurveys}
          recentBroadCasts={data?.recentBroadCasts}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
