import { useMutation, useQuery } from "@tanstack/react-query";
import GreaterThan from "assets/images/greaterThan.svg";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useModalHooks from "hooks/modalHooks";
import { useCallback } from "react";
import SMSContent from "./SmsContent";
import SMSPreview from "./SMSPreview";
import { createSmsCampaign } from "services/campaignService";
import Toast from "config/toast";
import useLoggedInUser from "hooks/useLoggedInUser";
import { trackEvent } from "config/mixpanel";
import SaveAndExitModal from "pages/Broadcasts/common/SaveAndExitModal";
import { getCampaignDetails } from "services/campaignService";
import { queryClient } from "config/gateway";
import { saveAndExit } from "services/campaignService";
import PublishModal from "pages/Broadcasts/common/PublishModal";
import { SuccessModal } from "pages/Broadcasts/common/successModal";
import Audience from "./Audience";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const tabs = [
  { name: "Content", value: 0 },
  { name: "Audience", value: 1 },
  { name: "Publish", value: 2 },
];

const CreateSms = () => {
  const { user } = useLoggedInUser();
  const [step, setStep] = useState(0);
  const [closeModal] = useModalHooks();
  const { id } = useParams();
  const [open, setOpen] = useState(null);
  const [disableButton, setDisabledButton] = useState(true);
  const { sidebar } = useSideBarStore();

  const navigate = useNavigate();
  const [smsData, setSmsData] = useState({
    content: "",
    sender_name: "",
    campaign_name: "",
    campaignObjective: "",
    recipientArray: [],
    personal: 0,
  });

  console.log(smsData);

  const { mutate } = useMutation(createSmsCampaign, {
    onSuccess: () => {
      Toast.success("sms sent successfully");
      localStorage.removeItem("tagData");
      localStorage.removeItem("segmentData");
      handleModal();
      queryClient.invalidateQueries(["allSmsCampaigns"], ["onBoarding"]);
      handleModal(5);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveExit, isLoading: saveExitLoading } = useMutation(saveAndExit, {
    onSuccess: () => {
      Toast.success(`SMS saved successfully`);
      queryClient.invalidateQueries(["allSmsCampaigns"], ["onBoarding"]);
      localStorage.removeItem("tagData");
      localStorage.removeItem("segmentData");
      setTimeout(() => {
        navigate(`/broadcasts/sms`);
      }, 1000);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { data, isLoading: fetchDetails } = useQuery(["fetchCampaignId", id], () => getCampaignDetails(id), {
    enabled: !!id,
  });

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const disabled = [smsData.content, smsData.sender_name, smsData.campaignObjective].some((field) => field === "");

  console.log(disabled);

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <SMSContent
            data={smsData}
            existingData={data}
            setData={setSmsData}
            loading={fetchDetails}
            setStep={setStep}
          />
        );
      case 1:
        return <Audience data={smsData} setData={setSmsData} type="sms" />;
      case 2:
        return <SMSPreview data={smsData} setData={setSmsData} setStep={setStep} />;
      default:
    }
  };

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else if (step === 2) {
      setOpen(1);
    }
  };

  const updatedInfo = {
    campaign_channel: "sms",
    campaign_name: smsData.campaign_name,
    sender_name: smsData.sender_name,
    campaign_subject: "Mooyi Camp",
    content: smsData.content,
    recipient: {
      personalContacts: smsData.recipientArray.map((item) => item.name),
    },
    campaignObjective: smsData.campaignObjective,
    totalReceiver: smsData.personal,
    receiverGroup: {
      mooyiAud: 0,
      personal: smsData.personal,
    },
    campaign_data: smsData?.campaign_date,
    campaign_status: smsData?.campaign_status,
  };

  const proceedSubmit = async () => {
    trackEvent("publish_campaign", {
      email: user?.data?.email,
      campaignName: smsData.campaign_name,
      date: smsData.personal,
      isScheduledForLater: smsData.scheduled,
      campaignType: "SMS",
    });

    mutate(updatedInfo);
  };

  const handleSave = () => {
    saveExit({ campaignId: id, payload: updatedInfo });
  };

  const checkState = useCallback(() => {
    if (step === 0) {
      if (disabled === false) {
        setDisabledButton(false);
      } else setDisabledButton(true);
    } else if (step === 1) {
      if (smsData.personal !== 0) {
        setDisabledButton(false);
        localStorage.removeItem("segmentData");
        localStorage.removeItem("tagData");
        setOpen(2);
      } else {
        setDisabledButton(true);
      }
    }
  }, [disabled, smsData.personal, step]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  return (
    <div onClick={closeModal}>
      <div className="bg-white p-5 rounded-xl h-full">
        <div className="px-14">
          <div className="flex space-x-4 mt-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%]">
            {tabs.map((el, i) => {
              return (
                <div
                  className="flex items-center justify-between w-[45%]"
                  key={el.value}
                  onClick={() => moveToStep(el.value)}
                >
                  <div className="flex items-center">
                    <p
                      className={`${
                        step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                      } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                    >
                      {el.value + 1}
                    </p>
                    <p
                      className={`px-2 cursor-pointer text-s ${
                        step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                      } `}
                    >
                      {el.name}
                    </p>
                  </div>

                  {el.value === 2 ? null : <img src={GreaterThan} alt="GreaterThan" />}
                </div>
              );
            })}
          </div>
        </div>

        <div className={`w-[90%] mx-auto  my-[2em]`}>{displayStep()}</div>
      </div>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <GreyButton text="Back" onClick={() => (step !== 0 ? setStep(step - 1) : navigate(-1))} />

        <div className="flex justify-between space-x-4">
          <GreyButton text="Save & exit" onClick={() => handleModal(4)} disabled={disabled} />

          <BlueButton
            text={`${step === 2 ? "Publish" : "Next"}`}
            css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
            disabled={disableButton}
            onClick={handleNext}
          />
        </div>
      </div>
      {open === 1 ? (
        <PublishModal
          handleSubmit={proceedSubmit}
          setData={setSmsData}
          data={smsData}
          handleModal={handleModal}
          campType="SMS"
        />
      ) : null}
      {open === 4 ? (
        <SaveAndExitModal handleModal={() => handleModal(null)} handleSave={handleSave} loading={saveExitLoading} />
      ) : null}
      {open === 5 ? (
        <SuccessModal
          handleModal={() => setOpen(null)}
          handleSubmit={() => navigate("/broadcasts/sms/create")}
          handleDone={() => navigate("/broadcasts/sms")}
        />
      ) : null}
    </div>
  );
};

export default CreateSms;
