import React from "react";
import Modal from "components/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { useMutation } from "@tanstack/react-query";
import SecondaryInput from "components/Inputs/SecondaryInput";
import { editSegment } from "services/contactService";
import { useNavigate, useParams } from "react-router-dom";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name too short")
    .max(25, "Name too long (25 characters max)")
    .matches(/^\S*$/, "Name should not contain any whitespace")
    .required("Please complete this field."),
});

const initialValues = {
  name: "",
  description: "",
};

const EditSegmentationModal = ({ toggleModal, id }) => {
  const navigate = useNavigate();
  const { mutate: segmentEdit, isLoading: loadingEdit } = useMutation(editSegment, {
    onSuccess: () => {
      Toast.success("Segment name edited");
      queryClient.invalidateQueries(["allSegment"]);
      navigate(-1);
      toggleModal();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = (data) => {
    const payload = { name: data.name };
    segmentEdit({ payload, id });
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  return (
    <Modal title="Edit segment" onClose={toggleModal}>
      <div className="w-[516px] px-5">
        <p className="text-m font-semibold my-2">Enter segment details below.</p>
        <form action="">
          <div className="mt-5 space-y-5">
            <div className="w-full">
              <SecondaryInput
                text="Segment Name"
                placeholder="Enter segment name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                name="name"
                error={getError("name")}
                type="text"
              />
            </div>
            <div className="w-full">
              <SecondaryInput
                text="Description (optional)"
                placeholder="Describe your segment"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                name="description"
                error={getError("description")}
                type="text"
              />
            </div>
          </div>
          <div className="w-full my-5">
            <div className="flex justify-end space-x-5 mt-[3em]">
              <div>
                <GreyButton text="Cancel" onClick={toggleModal} />
              </div>
              <div className="flex space-x-2">
                <BlueButton
                  text="Save"
                  css=""
                  onClick={handleSubmit}
                  disabled={values.name === ""}
                  loading={loadingEdit}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditSegmentationModal;
