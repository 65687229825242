import React, { useState } from "react";
import ResponsesTab from "../components/analyticsTab/ResponsesTab";
import Details from "../components/analyticsTab/Details";
import Typography from "utils/typography";
import OverviewTab from "../components/analyticsTab/OverviewTab";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";
import GreyButton from "components/Button/GreyButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/insights/npx.png";
import { getInsightStatus } from "services/insightService";
import { useQuery } from "@tanstack/react-query";
import LoadingSkeleton from "../components/LoadingSkeleton";

const tabs = [
  { id: 1, name: "Overview" },
  { id: 2, name: "Responses" },
  { id: 3, name: "Survey details" },
];

const CSatHome = () => {
  const [step, setStep] = useState(1);
  const [openModal, setOpenModal] = useState(null);
  const [name, setName] = useState("");

  const handleModal = (id) => {
    setOpenModal(openModal === id ? null : id);
  };
  const handleTabChange = (e) => {
    setStep(e);
  };

  const { data: insightStatus, isLoading: loadingInsight } = useQuery(["getInsight"], () => getInsightStatus("csat"));

  const csatData = [
    {
      name: "Jun 24",
      satisfied: 4000,
      neutral: 2400,
      unsatisfied: 2400,
    },
    {
      name: "Jul 5",
      satisfied: 3000,
      neutral: 1398,
      unsatisfied: 2210,
    },
    {
      name: "Jul 10",
      satisfied: 2000,
      neutral: 9800,
      unsatisfied: 2290,
    },
    {
      name: "Jul 15",
      satisfied: 2780,
      neutral: 3908,
      unsatisfied: 2000,
    },
    {
      name: "Jul 22",
      satisfied: 1890,
      neutral: 4800,
      unsatisfied: 2181,
    },
    {
      name: "Jul 28",
      satisfied: 2390,
      neutral: 3800,
      unsatisfied: 2500,
    },
    {
      name: "Jul 30",
      satified: 3490,
      neutral: 4300,
      unsatisfied: 2100,
    },
  ];

  const responseOverview = [
    {
      id: "onlineSummary1",
      name: "Responses",
      image: totalScore,
      value: 800,
      color: "text-primary-800",
    },
    {
      id: "onlineSummary2",
      name: "Satisfied",
      image: promoters,
      value: 800,
      color: "text-green-500",
    },
    {
      id: "onlineSummary3",
      name: "Neutral",
      image: passives,
      value: 800,
      color: "text-yellow-500",
    },
    {
      id: "onlineSummary3",
      name: "Unsatisfied",
      image: detractors,
      value: 800,
      color: "text-red-500",
    },
  ];

  const onlineSummary = [
    {
      id: "onlineSummary1",
      name: "CSAT score",
      image: totalScore,
      value: 50,
    },
    {
      id: "onlineSummary2",
      name: "Satisfied",
      image: promoters,
      value: 50,
    },
    {
      id: "onlineSummary3",
      name: "Neutral",
      image: passives,
      value: 50,
    },
    {
      id: "onlineSummary3",
      name: "Unsatisfied",
      image: detractors,
      value: 50,
      color: "text-red-500",
    },
  ];

  const responses = [
    {
      id: 1,
      name: "Folashade Odubanjo-King",
      category: "Satisfied",
      responseCount: 10,
      value: 80,
      date: "24th June, 2024 | 5:00pm",
      response: "Your service is extremely positive and top-notch. Well done!",
    },
    {
      id: 2,
      name: "Folashade Odubanjo-King",
      category: "Satisfied",
      responseCount: 10,
      value: 80,
      date: "24th June, 2024 | 5:00pm",
      response: "Your service is extremely positive and top-notch. Well done!",
    },
    {
      id: 3,
      name: "Franka Brown",
      category: "Neutral",
      responseCount: 10,
      value: 80,
      date: "24th June, 2024 | 5:00pm",
      response: "Your service is extremely positive and top-notch. Well done!",
    },
    {
      id: 4,
      name: "Franka Brown",
      category: "Neutral",
      responseCount: 3,
      value: 80,
      date: "24th June, 2024 | 5:00pm",
      response: "Your service is extremely positive and top-notch. Well done!",
    },
    {
      id: 5,
      name: "John-Bosco Shining-star",
      category: "Unsatisfied",
      responseCount: 3,
      value: 80,
      date: "24th June, 2024 | 5:00pm",
      response: "Your service is extremely positive and top-notch. Well done!",
    },
  ];

  const displayStep = () => {
    switch (step) {
      case 1:
        return <OverviewTab overViewData={onlineSummary} graphData={csatData} insights={insightStatus?.insights[0]} />;
      case 2:
        return <ResponsesTab overViewData={responseOverview} responses={responses} />;
      case 3:
        return <Details />;
      default:
    }
  };
  return (
    <>
      {loadingInsight ? (
        <LoadingSkeleton />
      ) : (
        <div>
          {/* insightStatus?.insights?.length */}
          {true ? (
            <>
              <div className="flex space-x-4 mt-4">
                {tabs.map((el) => {
                  return (
                    <Typography.SubText
                      className={` pb-[4px] cursor-pointer ${
                        step === el.id
                          ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold "
                          : "text-[#6C6685]"
                      } `}
                      onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
                      key={el.id}
                    >
                      {el.name}
                    </Typography.SubText>
                  );
                })}
              </div>
              <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

              <div>{displayStep()}</div>
            </>
          ) : (
            <div>
              <div className="border-[1px] rounded-lg h-[240px] w-[60%] bg-white ">
                <div className=" bg-cover h-[100%] flex items-center justify-center ">
                  <div className="w-[60%] space-y-4">
                    <div className="w-fit px-3 py-1 bg-primary-50">
                      <Typography.SubText className="w-[100%] text-primary-800">Insights</Typography.SubText>
                    </div>
                    <Typography.H3 className="font-semibold text-primary-800">Get to know your customers</Typography.H3>

                    <Typography.SubText className="w-[80%]">
                      Send out surveys to gather information about the preferences and behaviours of your customers.
                    </Typography.SubText>

                    <BlueButton text="Get started" onClick={() => handleModal(1)} />
                  </div>

                  <div className="w-[23%] ">
                    <img src={researchGif} alt="researchGif" />
                  </div>
                </div>
              </div>
              {openModal === 1 ? (
                <Modal title="Customer Satisfaction Survey" onClose={handleModal}>
                  <div className="w-[440px]">
                    <Typography.SubText className="px-5">Give your survey a title</Typography.SubText>
                    <div className="mt-2 px-5">
                      <p className="font-[600] text-[14px] pb-2">Title</p>
                      <div className=" h-[40px] rounded-[8px] flex justify-between w-full items-center">
                        <SecondaryInput
                          placeholder="Enter title"
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-[1em] px-5">
                      <div></div>
                      <div className="flex gap-[1em] mt-[1em]">
                        <GreyButton
                          text="Cancel"
                          css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s"
                          onClick={handleModal}
                        />
                        <BlueButton text="Save" onClick={() => ""} />
                      </div>
                    </div>
                  </div>
                </Modal>
              ) : null}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CSatHome;
