import { create } from "zustand";

const useBranchingStore = create((set, get) => ({
  sectionIndex: 0,
  setSectionIndex: (presentSection) => set({ sectionIndex: presentSection }),

  branchingOptions: {
    option: "",
    section: "",
  },

  branchingQuestion: {
    questionName: "",
    options: [],
  },

  // setBranchingQuestion: (updatedQuestion) =>
  //   set((state) => ({
  //     branchingQuestion: {
  //       ...state.branchingQuestion,
  //       ...updatedQuestion,
  //     },
  //   })),

  setBranchingQuestion: (updatedQuestion) => set({ branchingQuestion: updatedQuestion }),

  // // Function to set/update the branching question
  // setBranchingQuestion: (updatedQuestion) =>
  //   set((state) => ({
  //     branchingQuestion: {
  //       ...state.branchingQuestion,
  //       ...updatedQuestion,
  //     },
  //   })),

  handleBranchingText: (value) =>
    set((state) => ({
      branchingQuestion: {
        ...state.branchingQuestion,
        questionName: value,
      },
    })),

  // Action to create a new branching option
  createBranchingOptionValues: () =>
    set((state) => {
      const newOptionIndex = state.branchingQuestion.options.length;

      // Create the new branching option with a default section value
      const newBranchingOption = {
        option: "", // Initialize with an empty string or default value
        section: `section${newOptionIndex + 1}`, // Default section value based on the index
      };

      return {
        branchingQuestion: {
          ...state.branchingQuestion,
          options: [...state.branchingQuestion.options, newBranchingOption],
        },
      };
    }),
  // Action to update a specific branching option value
  handleBranchingOptionValues: (index, value) =>
    set((state) => ({
      branchingQuestion: {
        ...state.branchingQuestion,
        options: state.branchingQuestion.options.map((option, i) =>
          i === index ? { ...option, option: value } : option,
        ),
      },
    })),

  deleteBranchingOptions: (optionIndex) =>
    set((state) => ({
      branchingQuestion: {
        ...state.branchingQuestion,
        options: state.branchingQuestion.options.filter((_, index) => index !== optionIndex),
      },
    })),

  surveyQuestion: {
    question: "Untitled Question",
    //remove
    questionSection: "",
    description: "",
    questionType: "text_response",
    optional: false,
    options: [],
  },

  sectionArray: [],

  setSectionArray: (questions) => set({ sectionArray: questions }),

  initializeSections: (answerArray) =>
    set(() => {
      // console.log(answerArray, "answerArray");
      const array = answerArray?.map((el, i) => ({
        sectionName: "",
        sectionOption: el,
        questions: [], // Initializes with the template question
      }));

      // console.log(array, "answerArray");
      return { sectionArray: array };
    }),

  getAllQuestions: () => {
    const { sectionArray } = get();

    return sectionArray.reduce((accumulatedQuestions, section) => {
      return [...accumulatedQuestions, ...section.questions];
    }, []);
  },

  transformQuestions: (data, branchingQuestion) => {
    const sectionMap = {};

    // Create a map of section to option from the branching question
    const sectionToOptionMap = {};
    branchingQuestion.options.forEach((option) => {
      sectionToOptionMap[option.section] = option.option;
    });

    data.forEach((question) => {
      const section = question.questionSection;

      if (!sectionMap[section]) {
        sectionMap[section] = {
          sectionName: "",
          sectionOption: sectionToOptionMap[section] || "", // Map section to option
          questions: [],
        };
      }

      // Create a shallow copy of the question object without questionId
      const { questionId, ...questionWithoutId } = question;
      sectionMap[section].questions.push(questionWithoutId);
    });

    Object.keys(sectionToOptionMap).forEach((section) => {
      if (!sectionMap[section]) {
        sectionMap[section] = {
          sectionName: "", // Added sectionName based on section
          sectionOption: sectionToOptionMap[section],
          questions: [], // Assign empty questions array
        };
      }
    });

    // Convert the sectionMap object into an array
    return Object.values(sectionMap);
  },

  organizeQuestions: (value, branchingQuestion) =>
    set(() => {
      const { sectionArray } = get();
      // Create a map from the branching question options for easy lookup
      const sectionMap = {};
      branchingQuestion?.options?.forEach((option) => {
        sectionMap[option.section] = option.option;
      });

      // Create a new sections array to avoid mutating the state directly
      const newSections = sectionArray?.map((section) => ({
        ...section,
        questions: [], // Clear existing questions
      }));

      // Place each question into the corresponding section in newSections array
      value?.forEach((question) => {
        const matchingOption = sectionMap[question.questionSection];
        if (matchingOption) {
          const section = newSections?.find((sec) => sec.sectionOption === matchingOption);
          if (section) {
            section.questions.push(question);
          }
        }
      });

      // console.log(branchingQuestion, " state.branchingQuestion", sectionArray, newSections);
      // Only update state if newSections is different from current state.sectionArray
      // if (!shallow(newSections, sectionArray)) {
      //   return { sectionArray: newSections };
      // }
      return newSections; // No state update if there's no change
    }),

  createSection: () => {
    set((state) => {
      // Create a new section object with provided values or defaults
      const newSection = {
        sectionName: "Untitled Section", // Default if empty
        sectionOption: "Default Option", // Default if empty
        questions: [], // Ensure questions is an array
      };

      // Create a new array with the added section
      const updatedSectionArray = [...state.sectionArray, newSection];

      // Return the updated state
      return { sectionArray: updatedSectionArray };
    });
  },

  handleTypeSelect: (value, questionIndex) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];

      if (state.sectionIndex < 0 || state.sectionIndex >= updatedSectionArray.length) {
        console.error("Invalid section index");
        return { sectionArray: updatedSectionArray };
      }

      const questions = [...updatedSectionArray[state.sectionIndex].questions];

      if (value === "likert") {
        questions[questionIndex] = {
          ...questions[questionIndex],
          questionType: value,
          questionSection: `section${state.sectionIndex + 1}`,
          options: {
            max: {
              value: 10,
              label: "",
            },
            min: {
              value: 1,
              label: "",
            },
          },
        };
      } else if (value === "matrix") {
        questions[questionIndex] = {
          ...questions[questionIndex],
          questionType: value,
          questionSection: `section${state.sectionIndex + 1}`,
          options: {
            row: [],
            column: [],
            multichoice: false,
          },
        };
      } else if (value === "rating") {
        questions[questionIndex] = {
          ...questions[questionIndex],
          questionType: value,
          questionSection: `section${state.sectionIndex + 1}`,
          options: {
            shape: "star",
            iconQuantity: "",
            label: {
              first: "",
              last: "",
            },
          },
        };
      } else if (value === "text_response") {
        const { options, ...questionWithoutOption } = questions[questionIndex];
        questions[questionIndex] = {
          ...questionWithoutOption,
          questionType: value,
          questionSection: `section${state.sectionIndex + 1}`,
        };
      } else {
        questions[questionIndex] = {
          ...questions[questionIndex],
          questionType: value,
          questionSection: `section${state.sectionIndex + 1}`,
          options: [],
        };
      }

      updatedSectionArray[state.sectionIndex].questions = questions;

      return { sectionArray: updatedSectionArray };
    }),

  createQuestion: () =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];

      // Ensure the sectionIndex is valid
      if (state.sectionIndex < 0 || state.sectionIndex >= updatedSectionArray.length) {
        console.error("Invalid section index");
        return { sectionArray: updatedSectionArray };
      }

      // Add the new question to the selected section
      updatedSectionArray[state.sectionIndex] = {
        ...updatedSectionArray[state.sectionIndex],
        questions: [
          ...updatedSectionArray[state.sectionIndex].questions,
          { ...state.surveyQuestion, questionSection: `section${state.sectionIndex + 1}` },
        ],
      };

      // console.log(updatedSectionArray, "updatedSectionArray")

      return { sectionArray: updatedSectionArray };
    }),

  duplicateQuestion: (questionIndex) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];

      // Ensure the sectionIndex is valid
      if (state.sectionIndex < 0 || state.sectionIndex >= updatedSectionArray.length) {
        console.error("Invalid section index");
        return { sectionArray: updatedSectionArray };
      }

      // Ensure the questionIndex is valid
      const questions = updatedSectionArray[state.sectionIndex].questions;
      if (questionIndex < 0 || questionIndex >= questions.length) {
        console.error("Invalid question index");
        return { sectionArray: updatedSectionArray };
      }

      // Clone the question to be duplicated
      const questionToDuplicate = { ...questions[questionIndex] };

      // Insert the duplicated question after the original one
      const updatedQuestions = [
        ...questions.slice(0, questionIndex + 1),
        questionToDuplicate,
        ...questions.slice(questionIndex + 1),
      ];

      // Update the selected section with the new questions array
      updatedSectionArray[state.sectionIndex] = {
        ...updatedSectionArray[state.sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  handleOptional: (questionIndex, presentValue) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];

      if (state.sectionIndex < 0 || state.sectionIndex >= updatedSectionArray.length) {
        console.error("Invalid section index");
        return { sectionArray: updatedSectionArray };
      }

      const questions = [...updatedSectionArray[state.sectionIndex].questions];

      if (questionIndex < 0 || questionIndex >= questions.length) {
        console.error("Invalid question index");
        return { sectionArray: updatedSectionArray };
      }

      questions[questionIndex] = {
        ...questions[questionIndex],
        optional: !presentValue,
      };

      updatedSectionArray[state.sectionIndex].questions = questions;

      return { sectionArray: updatedSectionArray };
    }),

  deleteQuestion: (questionIndex) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];

      // Ensure the sectionIndex and questionIndex are valid
      if (state.sectionIndex < 0 || state.sectionIndex >= updatedSectionArray.length) {
        console.error("Invalid section index");
        return { sectionArray: updatedSectionArray };
      }

      const questions = [...updatedSectionArray[state.sectionIndex].questions];

      if (questionIndex < 0 || questionIndex >= questions.length) {
        console.error("Invalid question index");
        return { sectionArray: updatedSectionArray };
      }

      // Remove the question at questionIndex
      questions.splice(questionIndex, 1);

      updatedSectionArray[state.sectionIndex] = {
        ...updatedSectionArray[state.sectionIndex],
        questions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  setSurveyQuestion: (index, name, value) =>
    set((state) => {
      const updatedArray = [...state.surveyQuestionsArray];
      updatedArray[index] = {
        ...updatedArray[index],
        [name]: value,
      };
      return { surveyQuestionsArray: updatedArray };
    }),

  editSectionName: (sectionIndex, newSectionName) => {
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];

      // Update the sectionName for the specific section
      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        sectionName: newSectionName,
      };

      return { sectionArray: updatedSectionArray };
    });
  },

  handleText: (name, value, sectionIndex, questionIndex) => {
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      // Update the specific question with the new value from the event
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        [name]: value,
      };

      // Update the section with the modified questions array
      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    });
  },

  addOptionToQuestion: (sectionIndex, questionIndex) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      const options = updatedQuestions[questionIndex]?.options || [];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: [...options, ""], // Add a new empty option
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  // Handle changes to option values
  handleOptionValues: (sectionIndex, questionIndex, optionIndex, value) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];
      const updatedQuestion = { ...updatedQuestions[questionIndex] };

      // Update the specific option
      updatedQuestion.options[optionIndex] = value;

      updatedQuestions[questionIndex] = updatedQuestion;
      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  // Delete a specific option
  deleteValueOptions: (sectionIndex, questionIndex, optionIndex) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      // Filter out the option at optionIndex
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: updatedQuestions[questionIndex]?.options?.filter((_, i) => i !== optionIndex),
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  handleLikertType: (sectionIndex, questionIndex, parent, name, value) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];
      const valueType = parent === "likertMax" ? "max" : "min";

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: {
          ...updatedQuestions[questionIndex].options,
          [valueType]: {
            ...updatedQuestions[questionIndex].options[valueType],
            [name]: name === "value" ? +value : value,
          },
        },
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  // Handle Rating Text Type options
  handleRatingTextType: (sectionIndex, questionIndex, name, value) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: {
          ...updatedQuestions[questionIndex].options,
          label: {
            ...updatedQuestions[questionIndex].options.label,
            [name]: value,
          },
        },
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  // Handle Rating Select Type options
  handleRatingSelectType: (sectionIndex, questionIndex, name, value) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: {
          ...updatedQuestions[questionIndex].options,
          [name]: name === "shape" ? value : +value,
        },
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  // Remove a specific survey question
  removeSurveyQuestion: (sectionIndex, questionIndex) =>
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      // Remove the specific question
      updatedQuestions.splice(questionIndex, 1);

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    }),

  // Add or remove options in Mooyi format
  addMooyiOptions: (sectionIndex, questionIndex, value) => {
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: updatedQuestions[questionIndex]?.options.includes(value)
          ? updatedQuestions[questionIndex]?.options.filter((el) => el !== value)
          : [...updatedQuestions[questionIndex]?.options, value],
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    });
  },

  // Create matrix options
  createMatrixOptions: (sectionIndex, questionIndex, key) => {
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: {
          ...updatedQuestions[questionIndex]?.options,
          [key]: [...(updatedQuestions[questionIndex]?.options[key] || []), ""],
        },
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    });
  },

  // Delete matrix options
  deleteMatrixOptions: (sectionIndex, questionIndex, optionIndex, key) => {
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: {
          ...updatedQuestions[questionIndex]?.options,
          [key]: updatedQuestions[questionIndex]?.options[key].filter((_, i) => i !== optionIndex),
        },
      };

      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    });
  },

  // Handle matrix values
  handleMatrixValues: (e, sectionIndex, questionIndex, newIndex, key) => {
    set((state) => {
      const updatedSectionArray = [...state.sectionArray];
      const updatedQuestions = [...updatedSectionArray[sectionIndex].questions];
      const updatedQuestion = { ...updatedQuestions[questionIndex] };

      updatedQuestion.options = {
        ...updatedQuestion.options,
        [key]: [
          ...updatedQuestion.options[key].slice(0, newIndex),
          e.target.value,
          ...updatedQuestion.options[key].slice(newIndex + 1),
        ],
      };

      updatedQuestions[questionIndex] = updatedQuestion;
      updatedSectionArray[sectionIndex] = {
        ...updatedSectionArray[sectionIndex],
        questions: updatedQuestions,
      };

      return { sectionArray: updatedSectionArray };
    });
  },
}));

export default useBranchingStore;
