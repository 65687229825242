export const parseStringToJson = (inputString) => {
  // Split the input string by two or more consecutive newlines (\n)
  const questionBlocks = inputString.split(/\n{2,}/);

  // Initialize an array to hold the result
  const result = [];

  // Iterate over each question block
  questionBlocks.forEach((block) => {
    // Use indexOf('\n') to efficiently split the block into question and options
    const firstLineBreak = block.indexOf("\n");

    let question, options;

    if (firstLineBreak === -1) {
      // No '\n' found, so no options, treat as text response
      question = block.trim();
      options = "";
      result.push({
        question: question,
        description: "",
        optional: false,
        options: options,
        questionType: "text_response",
      });
    } else {
      // Separate question and options
      question = block.substring(0, firstLineBreak).trim();
      options = block
        .substring(firstLineBreak + 1)
        .split("\n")
        .map((option) => option.trim())
        .filter(Boolean); // Filter out empty strings

      result.push({
        question: question,
        description: "",
        optional: false,
        ...(options.length && { options: options }),
        options: options.length ? options : "",
        questionType: options.length ? "multi_choice" : "text_response",
      });
    }
  });

  return result;
};

// Example usage:
// const inputString = "What is your name?\nayo\nisrael\n\n\nAre you alright \nYes\nno\n\n\n\nWhat is the time at your end";
// const jsonResult = parseStringToJson(inputString);

// console.log(JSON.stringify(jsonResult, null, 2));
