import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import ConfirmSenderIdEmail from "./ConfirmSenderIdEmail";
import ForgotPasswordSent from "./ForgotPasswordSent";
import ForgotPassword from "./ForgetPassword";

const Auth = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="*" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/register/:id" element={<Register />} />
      <Route exact path="/register/:id/:pId" element={<Register />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/reset-password/:token" element={<ResetPassword />} />
      <Route exact path="/confirm-email/:token" element={<VerifyEmail />} />
      <Route exact path="/verify-senderid/:token" element={<ConfirmSenderIdEmail />} />
      <Route exact path="/email-sent-password" element={<ForgotPasswordSent />} />
    </Routes>
  );
};

export default Auth;
