import ModalWithoutClose from "components/ModalWithoutClose";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
// import { QRCodeSVG } from "qrcode.react";
import BlueButton from "components/Button/BlueButton";
// import html2canvas from "html2canvas";
// import { saveAs } from "file-saver";
import GreyButton from "components/Button/GreyButton";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import FileUpload from "components/Inputs/FileUpload";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { QRCode } from "react-qrcode-logo";

const QrCodeModal = ({ surveyLink, handleModal }) => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState({
    logo: "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/profile_images/6424ab1e574d56bfeb0bc82b/Screenshot%202023-03-27%20at%2012.17.40%20PM.png",
    fgColor: "",
    bgColor: "",
  });

  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDownloadClick = () => {
    const canvas = document.getElementById("myQRCodeSVG");
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `your_name.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <ModalWithoutClose>
      <div className="w-[800px] h-[60vh] bg-white rounded-lg flex flex-col justify-between">
        <div className="flex justify-between items-center px-[2em] py-4 border-b-[1px]">
          <p>Generate QR code</p>

          <div className="bg-gray-200 text-gray-600 rounded-full p-2 cursor-pointer" onClick={handleModal}>
            <FaTimes />
          </div>
        </div>

        <div className="flex bg-[#FAFAFA] h-[72.5%] items-center">
          <div className="w-[60%]  flex flex-col items-center justify-center">
            <QRCode
              value={surveyLink} // here you should keep the link/value(string) for which you are generation promocode
              size={250} // the dimension of the QR code (number)
              // URL of the logo you want to use, make sure it is a dynamic url
              fgColor={data.fgColor || "#000"}
              logoHeight={40}
              logoWidth={40}
              logoOpacity={1}
              enableCORS={true}
              qrStyle="dots"
              eyeRadius={10}
              id={"myQRCodeSVG"}
            />
          </div>

          <div className="border-l-[1px] w-[40%] bg-white h-[100%] overflow-y-scroll p-4">
            <p>Customise your QR code</p>

            {/* <div className="p-4">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleModal(1)}>
                <p className="text-sm">Frame</p>

                {open === 1 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>

              <p className="text-xs text-gray-400 mt-2">Select a custom frame for your QR code</p>

              <hr className="w-[100%] mt-2" />
            </div> */}

            {/* <div className="p-4">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleModal(2)}>
                <p className="text-sm">Add Logo</p>

                {open === 2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>

              <p className="text-xs text-gray-400 mt-2">Add your business logo to personalize your QR code.</p>

              {open === 2 ? (
                <div className="mt-2">
                  <FileUpload name="logo" setData={setData} data={data} />
                </div>
              ) : null}

              <hr className="w-[100%] mt-2" />
            </div> */}

            <div className="p-4">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleModal(3)}>
                <p className="text-sm">Brand color</p>

                {open === 3 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>

              <p className="text-xs text-gray-400 mt-2">Add your brand color and identity to your survey.</p>

              {open === 3 ? (
                <PrimaryInput
                  type="text"
                  placeholder="#1D19AF"
                  onChange={handleChange}
                  // text=""
                  name="fgColor"
                  value={data.fgColor}
                />
              ) : null}

              <hr className="w-[100%] mt-2" />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between px-4 border-t-[1px] py-4">
          <div></div>

          <div className="flex space-x-4 ">
            <GreyButton onClick={handleDownloadClick} text="Copy Link" />
            <BlueButton onClick={() => handleDownloadClick()} text="Download" />
          </div>
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default QrCodeModal;
