import { useQuery } from "@tanstack/react-query";
import { fetchWorkspace } from "services/workspaceService";

const useWorkspace = () => {
  const { data } = useQuery(["defaultWorkspace"], fetchWorkspace);

  return {
    data,
  };
};

export default useWorkspace;
