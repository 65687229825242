import React, { useState } from "react";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import { getCurrentDate } from "lib/checkTime";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { publishSurvey } from "services/newSurveyService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import PreLoader from "components/Loader/PreLoader";
import publishOwl from "assets/images/publishOwl.png";
import Typography from "utils/typography";

const tabs = [
  {
    id: 1,
    name: "Publish now",
    description: "Your survey will be live and start collecting responses immediately.",
    value: "shareImmediately",
  },
  {
    id: 2,
    name: "Schedule for later",
    description: "Select a date and time for the survey to be published.",
    value: "scheduleForLater",
  },
];

const PublishModal = ({ handleModal, saveLeads }) => {
  const [type, setType] = useState("");
  const { id } = useParams();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const audienceType = params.get("audienceType");
  const locationArray = location.pathname.split("/");

  const [surveyData, setSurveyData] = useState({
    scheduleForLater: false,
    surveyDate: "",
    surveyTime: "",
  });

  const { mutate, isLoading } = useMutation(publishSurvey, {
    onSuccess: () => {
      handleModal(3);
      Toast.success("Survey Published");

      if (locationArray.includes("brand-tracking")) {
        navigate(`/research/brand-tracking/success/${id}`);
        queryClient.invalidateQueries(["brandTracking"]);
      } else if (locationArray.includes("online-survey")) {
        navigate(`/research/online-survey/success?id=${id}&audienceType=${audienceType}`);
      } else {
        navigate(`/research/field-interview`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const updateType = (x) => {
    setType(x);
    if (x === "scheduleForLater") {
      setSurveyData({
        ...surveyData,
        scheduleForLater: true,
      });
    } else {
      setSurveyData({
        ...surveyData,
        scheduleForLater: false,
      });
    }
  };

  const handleDate = (e) => {
    setSurveyData({
      ...surveyData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePublish = () => {
    mutate({
      surveyId: id,
      payload: {
        scheduleForLater: surveyData.scheduleForLater,
        saveLeads: saveLeads,
        publishDate:
          surveyData.scheduleForLater === true ? `${surveyData.surveyDate}T${surveyData.surveyTime}` : dayjs().format(),
      },
    });
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <>
      <Modal title="Publish survey" onClose={handleModal}>
        <div className=" w-[450px]">
          <div className="flex justify-center py-2 bg-primary-100 mt-3">
            <div className="w-[210px]">
              <img src={publishOwl} alt="" />
            </div>
          </div>

          <div className="p-5 space-y-3">
            <Typography.SubText className="font-semibold">
              When would you like to publish your survey?
            </Typography.SubText>

            <div className="space-y-3">
              {tabs.map((el) => {
                return (
                  <div className="flex items-start space-x-2 " key={el.id} onClick={() => updateType(el.value)}>
                    <div
                      className={`h-[20px] w-[20px] rounded-full border-[1px] mt-1 cursor-pointer flex items-center justify-center ${
                        el.value === type ? "border-primary-800" : ""
                      }`}
                    >
                      <div
                        className={`${el.value === type ? "bg-primary-800 rounded-full h-[12px] w-[12px]" : ""}`}
                      ></div>
                    </div>

                    <div>
                      <Typography.SubText className="font-semibold">{el.name}</Typography.SubText>
                      <Typography.MicroText>{el.description}</Typography.MicroText>
                    </div>
                  </div>
                );
              })}
            </div>

            {type === "scheduleForLater" && (
              <div className="flex space-x-4 justify-between w-[100%] my-[1em]">
                <div className="w-[50%]">
                  <SecondaryInput
                    text="Date"
                    step="2"
                    name="surveyDate"
                    type="date"
                    onChange={handleDate}
                    value={surveyData.surveyDate}
                    placeholder="Set date to send"
                    min={getCurrentDate()}
                  />
                </div>
                <div className="w-[50%]">
                  <SecondaryInput
                    text="Time"
                    placeholder="Set time to send"
                    onChange={handleDate}
                    type="time"
                    name="surveyTime"
                    value={surveyData.surveyTime}
                    min="08:00"
                    max="19:00"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between  border-t-[1px]  pt-4 px-4">
            <div></div>
            <div className="flex space-x-2">
              <GreyButton text="Cancel" onClick={() => handleModal(1)} type="submit" />

              {!surveyData.scheduleForLater ? (
                <BlueButton text="Confirm" onClick={handlePublish} disabled={type !== "" ? false : true} />
              ) : null}

              {surveyData.scheduleForLater ? (
                <BlueButton
                  text="Confirm"
                  onClick={handlePublish}
                  disabled={surveyData.surveyDate !== "" && surveyData.surveyTime !== "" ? false : true}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PublishModal;
