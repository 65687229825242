import { useMutation } from "@tanstack/react-query";
import { forgotPassword } from "services/authService";
import { useNavigate } from "react-router-dom";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import Logo from "assets/images/newLogo.png";
import { getEmail } from "helpers/authHelper";
import GreyButton from "components/Button/GreyButton";

export default function ForgotPasswordSent() {
  const email = getEmail();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(forgotPassword, {
    onError: (error) => {
      if (error === "Error fetching user!") {
        Toast.error("User with email doesn’t exist");
      } else {
        Toast.error(error);
      }
    },
    onSuccess: () => {
      Toast.success("Email sent!");
      navigate("/auth/email-sent-password");
    },
  });

  const handleSubmit = () => {
    const data = { email };
    mutate(data);
  };

  if (isLoading) {
    return <PreLoader />;
  }

  return (
    <div className="w-[90%] mx-auto py-5 relative">
      <div className="w-[90%] mx-auto flex flex-col">
        <div className="flex justify-center">
          <img src={Logo || ""} alt="Mooyi logo" className="w-[150px] mt-[1em]" />
        </div>

        <h1 className="font-semibold text-[28px] text-center mt-5">Reset your password</h1>
        <p className="text-[#737373] my-[10px] text-center">
          We’ve sent an email to <span className="text-primary-800">{email}</span> with a link to reset your password.
          Check your spam or inbox.
        </p>
        <div className="flex flex-col items-center">
          <div className="my-5 w-[50%] flex justify-center">
            <GreyButton text="Login" onClick={() => navigate("/auth/login")} />
          </div>

          <p>
            Didn't get it?{" "}
            <span className="text-primary-800 cursor-pointer" onClick={handleSubmit}>
              Resend email
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
