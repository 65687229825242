import React, { useEffect, useState } from "react";
import QrCodeImage from "assets/images/congratMascot.svg";
import GreyButton from "components/Button/GreyButton";
import { ImQrcode } from "react-icons/im";
import { PiLinkSimpleHorizontalBold } from "react-icons/pi";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FaFacebookF, FaLinkedin, FaTimes } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import QrCodeModal from "./QrCodeModal";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getInsightCost } from "services/insightService";
import Toast from "config/toast";
import { baseMooyiRewardsURL } from "helpers/getBaseUrl";

const QrCodeFlow = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(null);
  const [params] = useParams();

  const { data: insightCost } = useQuery(["getInsightCost", insightId], () => getInsightCost(insightId), {
    onError: (error) => {
      Toast.error(error);
    },
  });

  console.log(insightCost);

  // const surveyLink = `${baseMooyiRewardsURL}generated-link/${publishId}-SURVEY-${id}`

  const surveyLink = "";

  const insightId = params.get("insightId");
  const insightType = params.get("insightType");

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const copyFunc = async () => {
    await navigator.clipboard.writeText(`${surveyLink?.toString()}`);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      let showClicked = setTimeout(() => setCopied(!copied), 1500);
      return () => {
        clearTimeout(showClicked);
      };
    }
    return;
  });

  const closeModal = () => {
    if (insightType === "nps") {
      navigate(`/insights/nps`);
    } else if (insightType === "csat") {
      navigate(`/insights/csat`);
    } else {
      navigate(`/insights/customer-profile`);
    }
  };

  return (
    <div className="absolute h-[100vh] w-[100vw] left-0 right-0 bottom-0  z-30 bg-white flex justify-center items-center">
      <div className="flex flex-col items-center justify-center space-y-6">
        <div className="flex justify-between w-[100%]">
          <div></div>

          <FaTimes className="text-2xl text-gray-600 cursor-pointer" onClick={closeModal} />
        </div>
        <div className="flex items-center space-x-4">
          <img src={QrCodeImage} className="w-[80px]" alt="QrCodeImage" />
          <p className="text-2xl font-semibold">Congratulations!</p>
        </div>
        <p className="text-sm text-gray-600 mt-2">Your survey is now live! share your survey in multiple ways</p>

        <div className="flex space-x-3">
          <div className="h-[300px] w-[260px] border-[1px] rounded-lg bg-[#FAFAFA] flex items-center flex-col p-4 space-y-2">
            <div className="h-[40%] bg-white w-[100%] rounded-lg flex items-center justify-center">
              <div className="w-[50px] h-[50px] bg-primary-50 rounded-full flex justify-center items-center">
                <div className="bg-white w-[35px] h-[35px] rounded-full text-primary-800 flex items-center justify-center">
                  <PiLinkSimpleHorizontalBold className="text-xl" />
                </div>
              </div>
              {/* <ImQrcode className="text-6xl" /> */}
            </div>

            <p className="text-sm font-bold">Copy live link</p>
            <p className="text-xs text-gray-500 text-center">Share your unique link to your audience.</p>

            <div className="w-[100%] truncate flex items-center border border-neu-200 px-[6px] h-[40px] text-xs bg-white text-gray-500 rounded">
              <p> {surveyLink || "link unavailable..."}</p>
            </div>
            <GreyButton
              text={copied ? "Link copied!!" : "Copy link"}
              type="button"
              className="text-xs w-[70%]"
              onClick={copyFunc}
            />
          </div>

          <div className="h-[300px] w-[260px] border-[1px] rounded-lg bg-[#FAFAFA] flex items-center flex-col p-4 space-y-3 cursor-pointer">
            <div className="h-[40%] bg-white w-[100%] rounded-lg flex items-center justify-center">
              <ImQrcode className="text-6xl" />
            </div>

            <p className="text-sm font-bold">QR code</p>
            <p className="text-xs text-gray-500 text-center">
              Create a QR code for your survey and share it with your audience.
            </p>
            <GreyButton text="Generate QR code" type="button" className="text-xs" onClick={() => handleModal(1)} />
          </div>
        </div>

        <div className="flex w-[100%] items-center justify-center gap-4">
          <hr className="w-[33%]" />
          <p className="text-gray-400 w-[34%] text-sm text-center">or share your live link via</p>
          <hr className="w-[33%]" />
        </div>

        <div className="text-primary-800 flex gap-4">
          <WhatsappShareButton
            title="Hi there, could you spare a few minutes to complete a survey for us? Remember to claim your airtime reward after you’re done."
            separator=""
            url={surveyLink}
            className="w-full"
          >
            <div className="bg-gray-100 w-[50px] h-[40px] flex items-center justify-center rounded-md">
              <IoLogoWhatsapp className="text-primary-800 text-xl" />
            </div>
          </WhatsappShareButton>

          <LinkedinShareButton
            title="Hi there, could you spare a few minutes to complete a survey for us? Remember to claim your airtime reward after you’re done."
            url={surveyLink}
            className="w-full"
          >
            <div className="bg-gray-100 w-[50px] h-[40px] flex items-center justify-center rounded-md">
              <FaLinkedin className="text-primary-800 text-xl" />
            </div>
          </LinkedinShareButton>
          <FacebookShareButton
            url={surveyLink}
            quote={
              "Hi there, could you spare a few minutes to complete a survey for us? Remember to claim your airtime reward after you’re done."
            }
            hashtag="#enterscale #mooyiSurveys"
            className="w-full"
          >
            <div className="bg-gray-100 w-[50px] h-[40px] flex items-center justify-center rounded-md">
              <FaFacebookF className="text-primary-800 text-xl" />
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={surveyLink}
            title="Hi there, could you spare a few minutes to complete a survey for us? Remember to claim your airtime reward after you’re done."
            // hashtags="#enterscale #mooyiSurveys"
            className="w-full"
          >
            <div className="bg-gray-100 w-[50px] h-[40px] flex items-center justify-center rounded-md">
              <FaXTwitter className="text-primary-800 text-xl" />
            </div>
          </TwitterShareButton>
        </div>
      </div>
      {open === 1 ? <QrCodeModal handleModal={handleModal} surveyLink={surveyLink} /> : null}
    </div>
  );
};

export default QrCodeFlow;
