import React, { useEffect, useState } from "react";
import AllResponse from "./AllResponse";
import { Link, useParams } from "react-router-dom";
import { BsFillShareFill } from "react-icons/bs";
import { HiChevronLeft } from "react-icons/hi";
// import Toast from "config/toast";

import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchSummary } from "services/newSurveyService";
import { fetchByPublishId } from "services/newSurveyService";
import { fetchSingleOverview } from "services/newSurveyService";
// import { baseMooyiRewardsURL } from "helpers/getBaseUrl";

import { fetchCSV } from "services/newSurveyService";
import { removeEmptyKeysAndValues } from "lib/removeEmptyValueAndKey";
import { CustomButton } from "components/Button/CustomButton";
import { getResponses } from "services/newSurveyService";
import Details from "pages/Research/components/Analytics/Details";
import ShareModal from "pages/Research/components/Analytics/ShareModal";
import CSVModal from "pages/Research/components/Analytics/CSVModal";
import { getSingleResponse } from "services/newSurveyService";
import { getResponseStat } from "services/newSurveyService";
import { FiDownload } from "react-icons/fi";

const tabs = [
  { name: "All Responses", value: 0 },
  { name: "Details", value: 1 },
];

const FieldInterviewAnalytics = () => {
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(null);
  const { pubId, surveyId } = useParams();
  const [summaryData, setSummaryData] = useState();
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { mutate: mutateSummary, isLoading: isFetchingSummary } = useMutation(fetchSummary, {
    onSuccess: (data) => {
      setSummaryData(data);
    },
  });

  const { data: responses } = useQuery(["surveyResponses", surveyId, currentPage], () =>
    getResponses(surveyId, currentPage),
  );

  console.log(responses);
  const { data } = useQuery(
    ["fetchCSV"],
    () => fetchCSV(surveyId),

    {
      enabled: false,
      onSuccess: () => {},
    },
  );

  const [filterData, setFilterData] = useState({
    age: {},
    state: "",
    city: "",
    country: "",
    gender: "",
    earning: "",
    educationLevel: "",
    relationshipStatus: "",
    parentalStatus: "",
    employmentStatus: "",
    categoryOfInterest: "",
    occupation: "",
  });

  useEffect(() => {
    mutateSummary({
      surveyId: pubId,
      payload: {},
    });
  }, [mutateSummary, pubId]);

  // useEffect(() => {
  //   mutateAllSummary({
  //     surveyId: pubId,
  //     payload: {},
  //   });
  // }, [mutateAllSummary, pubId]);

  // const handleFilter = (filter, data) => {
  //   const currentFilterData = { ...filterData };

  //   if (filter === "age") {
  //     if (data === "N/A") {
  //       currentFilterData.age = {
  //         lte: 12,
  //         gte: 0,
  //       };
  //     } else {
  //       currentFilterData.age = {
  //         lte: +data.split("-")[1],
  //         gte: +data.split("-")[0],
  //       };
  //     }
  //   } else if (filter === "reset") {
  //     // Reset all filters

  //     setFilterData({
  //       age: "",
  //       state: "",
  //       city: "",
  //       country: "",
  //       gender: "",
  //       earning: "",
  //       educationLevel: "",
  //       relationshipStatus: "",
  //       parentalStatus: "",
  //       employmentStatus: "",
  //       categoryOfInterest: "",
  //       occupation: "",
  //     });
  //     // ... (rest of the keys)

  //     return;
  //   } else {
  //     // Check if the number of filled keys is less than or equal to 3
  //     const filledKeys = Object.keys(currentFilterData).filter((key) => currentFilterData[key] !== "").length;

  //     if (filledKeys <= 2 || currentFilterData[filter] !== "") {
  //       currentFilterData[filter] = data;
  //     }
  //   }

  //   setFilterData(currentFilterData);
  // };

  useEffect(() => {
    const newData = {
      surveyId: pubId,
      payload: removeEmptyKeysAndValues(filterData),
    };

    // console.log(newData)

    mutateSummary(newData);
  }, [filterData, mutateSummary, pubId]);

  const { data: overviewStat } = useQuery(["fetchingmetrics", surveyId], () => getResponseStat(surveyId));

  const statData = overviewStat?.data;

  // const { data: responsesData, isLoading: isFetchingResponses } = useQuery(
  //   ["fetchResponse", surveyId, currentPage],
  //   () => fetchResponse({ surveyId: surveyId, batch: currentPage }),
  // );

  // const { data: filterCoreData } = useQuery(["core", surveyId], () =>
  //   fetchDemoGraphics({
  //     surveyId: surveyId,
  //     flag: "core",
  //   }),
  // );

  // const { data: filterPersonalData } = useQuery(["personal", surveyId], () =>
  //   fetchDemoGraphics({
  //     surveyId: surveyId,
  //     flag: "personal",
  //   }),
  // );

  // const { data: filterWorkData } = useQuery(["work", surveyId], () =>
  //   fetchDemoGraphics({
  //     surveyId: surveyId,
  //     flag: "work",
  //   }),
  // );

  // const surveyLink = `${baseMooyiRewardsURL}generated-link/${pubId}-SURVEY-${surveyId}`;

  const { data: detailsData } = useQuery(["fetchByPublishId", pubId], () => fetchByPublishId(pubId));

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <AllResponse
            allData={responses?.data?.data}
            data={summaryData?.data}
            overview={statData}
            count={responses?.data?.count}
            isFetchingSummary={isFetchingSummary}
            filterData={filterData}
            setFilterData={setFilterData}
            currentPage={currentPage}
            pageCount={pageCount}
            setCurrentPage={setCurrentPage}
            setPageCount={setPageCount}
          />
        );
      case 1:
        return <Details data={detailsData?.data} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <Link to="/research/field-interview" className="flex items-center text-primary-800 space-x-2">
          <HiChevronLeft />
          <p className="text-s font-medium">Back to surveys</p>
        </Link>
        <div className="flex space-x-4">
          {/* <CustomButton text="Copy link" icon={<BiSolidCopy />} onClick={copyFunc} /> */}
          {/* <CustomButton text="Retarget" icon={<BsArrowRepeat />} /> */}
          {/* <CustomButton text="Get CSV" icon={<IoCloudDownload />} onClick={controlCSVModal} /> */}
          <CustomButton text="Download" icon={<FiDownload />} onClick={() => handleModal(1)} />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[6px] px-2 cursor-pointer ${
                step === el.value
                  ? "border-b-[3px]  border-primary-800 text-neutral-900 font-secondary font-semibold "
                  : "text-neutral-500"
              } `}
              onClick={el.value !== 3 ? () => handleTabChange(el.value) : null}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
      {open === 1 ? null : null}

      {open === 2 ? <CSVModal handleModal={handleModal} fileUrl={data?.data?.filePath} /> : null}
    </div>
  );
};

export default FieldInterviewAnalytics;
