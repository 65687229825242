import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import PreLoader from "components/Loader/PreLoader";
import Toast from "config/toast";
import { useEffect } from "react";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { GiCheckMark } from "react-icons/gi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { verifySenderId } from "services/workspaceService";
import Logo from "assets/images/newLogo.png";

export default function ConfirmSenderIdEmail() {
  const { mutate, isLoading, isError } = useMutation(verifySenderId, {
    onError: (error) => {
      Toast.error(error);
    },
    onSuccess: () => {
      Toast.success("Email id verified!, Redirecting to Login");
      navigate("/auth/login");
    },
  });

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    mutate(token.slice(6));
  }, [mutate, token]);

  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : !isLoading && isError ? (
        <div className="flex flex-col space-y-4 justify-center items-center w-[100%] mx-auto">
          <div className="flex justify-center">
            <img src={Logo || ""} alt="Mooyi logo" className="w-[150px] mt-[1em]" />
          </div>
          <AiOutlineIssuesClose className="text-red-500 text-[48px]" />

          <p className="text-[24px] font-[500]">Confirm senderID email failed.</p>

          <BlueButton text="Retry" onClick={() => window.location.reload()} />
        </div>
      ) : (
        <div className="flex flex-col space-y-4 justify-center items-center w-[100%] mx-auto">
          <GiCheckMark className="text-green text-[48px]" />

          <p className="text-[24px] font-[500]">Confirm senderID email successful. </p>
          <Link to="/auth/login">
            <BlueButton text=" Proceed to login" type="button" />{" "}
          </Link>
        </div>
      )}
    </>
  );
}
