import React from "react";
import moment from "moment";
import { campStatus } from "lib/campaignFunction";
import { toTitleCase } from "lib/titleCase";
import AudioPlayer from "components/AudioPlayer";
import Typography from "utils/typography";

const Details = ({ data }) => {
  console.log(data);
  return (
    <div className="mt-4 bg-white p-4 rounded-md border-[1px]">
      {/* <p className="py-2 text-[20px] font-[500]">Details</p> */}

      <div className="flex items-start justify-between space-x-5">
        <div className="w-[50%] p-5 border rounded-lg space-y-3 bg-white">
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Subject</p>
            <p className=" text-black font-[500]">{data?.campaignSubject}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Objective</p>
            <p className=" text-black font-[500]">{data?.campaignObjective}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Type</p>
            <p className=" text-black font-[500]">{toTitleCase(data?.CampaignChannel)}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Published</p>
            <p className=" text-black font-[500]">{moment(data?.campaignDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px] items-center">
            <p className="w-[30%] text-[#525252] font-[400]">Tag</p>
            <div className="flex space-x-4">
              {data?.audience?.personalContacts?.map((el, i) => {
                return (
                  <p key={i} className="text-[12px] p-2 bg-gray-200 rounded-md">
                    {el}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Status</p>
            <p className=" text-black font-[500]">{campStatus(data?.campaignStatus)}</p>
          </div>

          <div className="text-m flex justify-between border-b-[1px] p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Created</p>
            <p className=" text-black font-[500]">
              {data?.createdAt === undefined ? "" : moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
          <div className="text-m flex justify-between p-[12px]">
            <p className="w-[30%] text-[#525252] font-[400]">Unit spent</p>
            <p className=" text-[20px] font-[700] text-primary-700">{data.campaignCost}</p>
          </div>
        </div>
        <div className="w-[50%]">
          <div className="w-full h-[60px] flex items-center px-5 drop-shadow-lg bg-white">
            <Typography>Preview</Typography>
          </div>
          <div className="mt-2">
            <AudioPlayer url={data?.campaignContent} className="h-[300px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
