import React from "react";
import useSummaryStore from "store/useSummaryStore";
import Typography from "utils/typography";

const Customized = ({ data, questionOption, filterLength, questionsFilter, setQuestionsFilter, questionIndex }) => {
  const { setQuestionIndex } = useSummaryStore();

  const handleBarClick = ({ question, option, questionType }) => {
    setQuestionsFilter([
      // ...questionsFilter,
      {
        question,
        option,
        questionType,
      },
    ]);

    setQuestionIndex(questionIndex);
  };

  console.log(data, "Customized");

  return (
    <div className="space-y-3">
      {questionOption !== undefined ? (
        <div className="flex items-center justify-between py-2">
          <Typography.SubText className="font-semibold">
            {questionOption?.min?.value} - {questionOption?.min?.label}
          </Typography.SubText>

          <Typography.SubText className="font-semibold">
            {questionOption?.max?.value} - {questionOption?.max?.label}
          </Typography.SubText>
        </div>
      ) : null}

      {data?.map((el, i) => {
        return (
          <div
            key={`customBar${i}`}
            className="w-[100%] h-[35px] rounded-[4px] bg-primary-50 relative cursor-pointer"
            onClick={() =>
              handleBarClick({
                question: el.question,
                questionType: el.questionType,
                option: el.name,
              })
            }
          >
            {/* <div
              style={{ width: `${filterLength === 0 ? el.percentage : el.staticPercentage}%` }}
              className="bg-primary-300 bg-opacity-40 rounded-[4px] h-[100%] absolute left-0 z-[6] "
            ></div>

            <div className="flex items-center justify-between  absolute z-[7] w-[100%] top-[25%] px-4">
              <p className="text-xs">
                {el.name === "MooyiOption" ? (
                  "Others"
                ) : (
                  <>
                    {el.name.substring(0, 50)} {el.name.length > 50 ? "..." : ""}
                  </>
                )}
              </p>

              <div className="flex space-x-1 text-sm">
                <p className="text-xs">{filterLength === 0 ? el.percentage : el.staticPercentage}%</p>
                <p className="text-xs">({filterLength === 0 ? el.value : el?.staticValue})</p>
              </div>
            </div> */}

            <div
              style={{ width: `${el.percentage}%` }}
              className="bg-primary-300 bg-opacity-40 rounded-[4px] h-[100%] absolute left-0 z-[6] "
            ></div>

            <div className="flex items-center justify-between  absolute z-[7] w-[100%] top-[25%] px-4">
              <p className="text-xs">
                {el.name === "MooyiOption" ? (
                  "Others"
                ) : (
                  <>
                    {el.name.substring(0, 50)} {el.name.length > 50 ? "..." : ""}
                  </>
                )}
              </p>

              <div className="flex space-x-1 text-sm">
                <p className="text-xs">{el.percentage}%</p>
                <p className="text-xs">({el.value})</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Customized;
