import React, { useEffect, useRef, useState } from "react";
import Summary from "./Summary";
import { Link, useParams } from "react-router-dom";
import { BsFillShareFill } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import { HiChevronLeft } from "react-icons/hi";
import Toast from "config/toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchByPublishId } from "services/newSurveyService";
import { fetchDemoGraphics } from "services/newSurveyService";
import { fetchSingleOverview } from "services/newSurveyService";
import { fetchResponse } from "services/newSurveyService";
import { baseMooyiRewardsURL } from "helpers/getBaseUrl";
import { IoCloudDownload } from "react-icons/io5";
import { fetchCSV } from "services/newSurveyService";
import { removeEmptyKeysAndValues } from "lib/removeEmptyValueAndKey";
import { useLocation } from "react-router-dom";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";
import { fetchBrandSummary } from "services/newSurveyService";
import CSVModal from "pages/Research/components/Analytics/CSVModal";
import Responses from "pages/Research/components/Analytics/Responses";
import ShareModal from "pages/Research/components/Analytics/ShareModal";
import Details from "pages/Research/components/Analytics/Details";
import { queryClient } from "config/gateway";
import { IoIosRefreshCircle } from "react-icons/io";

const tabs = [
  { name: "Summary", value: 0 },
  { name: "Responses", value: 1 },
  { name: "Details", value: 2 },
];

const CustomButton = ({ onClick, text, icon }) => {
  return (
    <div
      onClick={onClick}
      className="bg-primary-50 px-6 text-primary-800  h-[40px] rounded-2xl flex items-center justify-center space-x-2 cursor-pointer"
    >
      {icon}
      <p className="text-s font-bold">{text}</p>
    </div>
  );
};

const BrandTrackingAnalytics = () => {
  const { state } = useLocation();
  const { user } = useLoggedInUser();
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(null);
  const { pubId, surveyId } = useParams();
  const [summaryData, setSummaryData] = useState();
  const [allData, setAllData] = useState();
  const hasRun = useRef(false);

  const { mutate: mutateSummary, isLoading: isFetchingSummary } = useMutation(fetchBrandSummary, {
    onSuccess: (data) => {
      setSummaryData(data);
    },
  });

  // const summary =
  //   summaryData && typeof summaryData === "object" && Object.keys(summaryData).length > 0 ? summaryData.data : [];

  // console.log(summary);

  const { mutate: mutateAllSummary } = useMutation(fetchBrandSummary, {
    onSuccess: (data) => {
      setAllData(data);
    },
  });

  const { data, refetch } = useQuery(
    ["fetchCSV"],
    () => fetchCSV(surveyId),

    {
      enabled: false,
      onSuccess: () => {},
    },
  );

  const controlCSVModal = () => {
    refetch();
    handleModal(2);
  };

  const [filterData, setFilterData] = useState({
    age: {},
    state: "",
    city: "",
    country: "",
    gender: "",
    earning: "",
    educationLevel: "",
    relationshipStatus: "",
    parentalStatus: "",
    employmentStatus: "",
    categoryOfInterest: "",
    occupation: "",
  });

  useEffect(() => {
    if (!hasRun.current && pubId) {
      mutateSummary({
        payload: {
          publishId: [pubId],
        },
      });
      hasRun.current = true; // Mark the effect as having run
    }
  }, [mutateSummary, pubId]);

  const handleFilter = (filter, data) => {
    const currentFilterData = { ...filterData };

    if (filter === "age") {
      if (data === "N/A") {
        currentFilterData.age = {
          lte: 12,
          gte: 0,
        };
      } else {
        currentFilterData.age = {
          lte: +data.split("-")[1],
          gte: +data.split("-")[0],
        };
      }
    } else if (filter === "reset") {
      // Reset all filters

      setFilterData({
        age: "",
        state: "",
        city: "",
        country: "",
        gender: "",
        earning: "",
        educationLevel: "",
        relationshipStatus: "",
        parentalStatus: "",
        employmentStatus: "",
        categoryOfInterest: "",
        occupation: "",
      });
      // ... (rest of the keys)

      return;
    } else {
      // Check if the number of filled keys is less than or equal to 3
      const filledKeys = Object.keys(currentFilterData).filter((key) => currentFilterData[key] !== "").length;

      if (filledKeys <= 2 || currentFilterData[filter] !== "") {
        currentFilterData[filter] = data;
      }
    }

    setFilterData(currentFilterData);
  };

  // const handleFilter = (filter, data) => {
  //   if (filter === "age") {
  //     if (data === "N/A") {
  //       setFilterData({
  //         ...filterData,
  //         age: {
  //           lte: 12,
  //           gte: 0,
  //         },
  //       });
  //     } else {
  //       setFilterData({
  //         ...filterData,
  //         age: {
  //           lte: +data.split("-")[1],
  //           gte: +data.split("-")[0],
  //         },
  //       });
  //     }
  //   } else if (filter === "reset") {
  //     setFilterData({
  //       age: "",
  //       state: "",
  //       city: "",
  //       country: "",
  //       gender: "",
  //       earning: "",
  //       educationLevel: "",
  //       relationshipStatus: "",
  //       parentalStatus: "",
  //       employmentStatus: "",
  //       categoryOfInterest: "",
  //       occupation: "",
  //     });

  //     return;
  //   } else {
  //     setFilterData({
  //       ...filterData,
  //       [filter]: data,
  //     });
  //   }
  // };
  //

  function removeEmptyItems(array) {
    const filteredArray = [];

    for (let item of array) {
      if (item && (typeof item !== "object" || Object.keys(item).length > 0)) {
        filteredArray.push(item);
      }
    }

    return filteredArray;
  }

  useEffect(() => {
    if (pubId && Object.values(removeEmptyItems(Object.values(filterData))).length > 0) {
      mutateAllSummary({
        payload: {
          publishId: [pubId],
          filter: {
            ...removeEmptyKeysAndValues(filterData),
          },
        },
      });
    }
  }, [filterData, mutateAllSummary, pubId]);

  const { data: overviewData } = useQuery(["fetchSingleOverview", surveyId], () => fetchSingleOverview(surveyId));

  const [currentPage, setCurrentPage] = useState(1);

  const { data: responsesData, isLoading: isFetchingResponses } = useQuery(
    ["fetchResponse", surveyId, currentPage],
    () => fetchResponse({ surveyId: surveyId, batch: currentPage }),
  );

  const { data: filterCoreData } = useQuery(["core", surveyId], () =>
    fetchDemoGraphics({
      surveyId: surveyId,
      flag: "core",
    }),
  );

  const { data: filterPersonalData } = useQuery(["personal", surveyId], () =>
    fetchDemoGraphics({
      surveyId: surveyId,
      flag: "personal",
    }),
  );

  const { data: filterWorkData } = useQuery(["work", surveyId], () =>
    fetchDemoGraphics({
      surveyId: surveyId,
      flag: "work",
    }),
  );

  const surveyLink = `${baseMooyiRewardsURL}generated-link/${pubId}-SURVEY-${surveyId}`;

  const copyFunc = async () => {
    trackEvent("share_report", {
      email: user?.data?.email,
      surveyName: state?.surveyName,
      surveyStatus: state?.surveyStatus,
      date: dayjs().format(),
    });

    await navigator.clipboard.writeText(`${surveyLink?.toString()}`);
    Toast.success("Link copied");
  };

  const { data: detailsData } = useQuery(["fetchByPublishId", pubId], () => fetchByPublishId(pubId));

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <Summary
            allData={allData?.data}
            data={summaryData?.data}
            overview={overviewData?.data}
            filterPersonalData={filterPersonalData?.data}
            filterCoreData={filterCoreData?.data}
            filterWorkData={filterWorkData?.data}
            handleFilter={handleFilter}
            isFetchingSummary={isFetchingSummary}
            filterData={filterData}
            setFilterData={setFilterData}
            filterLength={Object.values(removeEmptyItems(Object.values(filterData))).length}
          />
        );
      case 1:
        return (
          <Responses
            responseCount={overviewData?.data?.responses}
            responsesData={responsesData}
            isFetchingResponses={isFetchingResponses}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case 2:
        return <Details data={detailsData?.data} />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries(["fetchByPublishId"]);
    queryClient.invalidateQueries(["core"]);
    queryClient.invalidateQueries(["personal"]);
    queryClient.invalidateQueries(["work"]);
    queryClient.invalidateQueries(["fetchSingleOverview"]);

    mutateSummary({
      payload: {
        publishId: [pubId],
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <Link to="/research/brand-tracking" className="flex items-center text-primary-800 space-x-2">
          <HiChevronLeft />
          <p className="text-s font-medium">Back to surveys</p>
        </Link>
        <div className="flex space-x-4">
          <CustomButton text="Refresh" icon={<IoIosRefreshCircle />} onClick={handleRefresh} />
          <CustomButton text="Copy link" icon={<BiSolidCopy />} onClick={copyFunc} />
          {/* <CustomButton text="Retarget" icon={<BsArrowRepeat />} /> */}
          <CustomButton text="Get CSV" icon={<IoCloudDownload />} onClick={controlCSVModal} />
          <CustomButton text="Share" icon={<BsFillShareFill />} onClick={() => handleModal(1)} />
        </div>
      </div>
      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[6px] px-2 cursor-pointer ${
                step === el.value
                  ? "border-b-[3px]  border-primary-800 text-neutral-900 font-secondary font-semibold "
                  : "text-neutral-500"
              } `}
              onClick={el.value !== 3 ? () => handleTabChange(el.value) : null}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
      {open === 1 ? <ShareModal handleModal={handleModal} data={state} /> : null}

      {open === 2 ? <CSVModal handleModal={handleModal} fileUrl={data?.data?.filePath} data={state} /> : null}
    </div>
  );
};

export default BrandTrackingAnalytics;
