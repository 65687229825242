import { useQuery } from "@tanstack/react-query";
import SelectInput from "components/Inputs/SelectInput";
import { MdInfo } from "react-icons/md";
import { getAllSMSId } from "services/senderIdService";
import { useEffect, useState } from "react";
import { IoPersonCircleSharp } from "react-icons/io5";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { FiChevronDown } from "react-icons/fi";
import useLoggedInUser from "hooks/useLoggedInUser";
import * as Yup from "yup";
import { replacePlaceholders, serializeMessage } from "lib/personalizationFunctions";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useStore } from "store";
import PreLoader from "components/Loader/PreLoader";
import Tooltip from "components/Tooltip";

const SMSContent = ({ data, setData, existingData, loading }) => {
  const { data: sms, isLoading } = useQuery(["smsSenderIDs"], getAllSMSId);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [personalizationArray, setPersonalizationArray] = useState([]);
  const { user } = useLoggedInUser();
  const { smsTemplate } = useStore();

  const initialValues = {
    content: existingData?.campaignContent || data.campaignContent || "",
    sender_name: existingData?.senderId || data.senderId || "",
    campaign_name: existingData?.campaignName || data.campaignName || "",
    campaignObjective: existingData?.campaignObjective || smsTemplate.category || "",
    ...smsTemplate,
  };

  const validationSchema = Yup.object({
    content: Yup.string().required("Please fill in this field"),
    sender_name: Yup.string().required("Please fill in this field"),
    campaign_name: Yup.string().required("Please fill in this field"),
    campaignObjective: Yup.string().required("Please fill in this field"),
  });

  const onSubmit = async () => {
    console.log("new Data");
  };

  const { handleChange, values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const handleDropDown = () => {
    setOpen(!open);
  };

  const options = sms
    ?.filter((filtered) => filtered?.isActive)
    .map((el) => ({
      value: el.name,
      label: el?.name,
    }));

  const handleContent = (e) => {
    setCount(e.target.value.length);
    setFieldValue("content", e.target.value);

    // This function is to return the literals in the text into an array
    setPersonalizationArray(e.target.value?.match(/{{(.*?)}}/g) === null ? [] : e.target.value?.match(/{{(.*?)}}/g));
  };

  useEffect(() => {
    setData((prevData) => ({ ...prevData, ...values }));
  }, [setData, values]);

  const contactArray = [
    { value: "{{firstname}}", label: "First name" },
    { value: "{{lastname}}", label: "Last name" },
    { value: "{{fullname}}", label: "Full name" },
    // { value: "{{email}}", label: "Email" },
    // { value: "{{phone}}", label: "Phone" },
  ];

  const objectives = [
    { label: "Promotional", value: "promotional" },
    { label: "Transactional", value: "transactional" },
    { label: "Remainder", value: "remainder" },
    { label: "Educational", value: "educational" },
    { label: "Survey/Feedback", value: "survey/feedback" },
  ];

  const option1 = [{ value: "", label: "Loading..." }];

  const addToText = (word) => {
    setFieldValue("content", values.content + word);

    setPersonalizationArray([...personalizationArray, word]);
    handleDropDown();
  };

  if (loading && existingData !== undefined) {
    return <PreLoader />;
  }

  return (
    <div className="flex w-[100%] justify-between">
      <form onSubmit={handleSubmit} className="w-[50%]">
        <p className="text-[20px] text-[#0F172A] tracking-[-0.02em] font-semibold">Content</p>
        <p className="text-s font-[450] text-[#737373]">Enter the details of your message</p>
        <div>
          <div className="flex space-x-1 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">SMS Name</p>
          </div>
          <div>
            <PrimaryInput
              name="campaign_name"
              onChange={handleChange}
              value={values.campaign_name}
              placeholder="Enter the name of your message"
              error={getError("campaign_name")}
            />
          </div>
        </div>

        <div className="flex space-x-2 items-center mt-[1em]">
          <p className="text-gray-700 text-sm font-medium">SMS Sender ID</p>
          <Tooltip text="What name should be displayed to the receiver?" />
        </div>
        <SelectInput
          name="sender_name"
          options={
            isLoading
              ? option1
              : [
                  {
                    label: "Select a Sender ID",
                    value: "",
                  },
                  ...options,
                ]
          }
          disabled={isLoading ? true : false}
          css="h-[50px] rounded-[8px]"
          value={values.sender_name}
          onChange={handleChange}
          error={getError("sender_name")}
        />

        <div>
          <div className="flex space-x-2 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">Objectives</p>
            <Tooltip text="What is the aim of the message?" />
          </div>
          <SelectInput
            name="campaignObjective"
            options={
              isLoading
                ? option1
                : [
                    {
                      label: "Select objective",
                      value: "",
                    },
                    ...objectives,
                  ]
            }
            disabled={isLoading ? true : false}
            css="h-[50px] rounded-[8px]"
            value={values.campaignObjective}
            onChange={handleChange}
            error={getError("campaignObjective")}
          />
        </div>

        <div></div>

        <div className="flex space-x-2 items-center mt-[1em]">
          <p className="text-gray-700 text-sm font-medium">Add Personalisation</p>
          <Tooltip text="Personalise this message for each receiver. " />
        </div>

        <div className="relative mt-2">
          <div
            className="h-[45px] w-[100%] border-[1px] rounded-md flex items-center justify-between px-4 cursor-pointer"
            onClick={handleDropDown}
          >
            {personalizationArray?.length === 0 || personalizationArray === null ? (
              <p className="text-s text-grey">No personalisation added</p>
            ) : (
              <div className="flex space-x-2 w-[100%] overflow-x-scroll no-scrollbar">
                {replacePlaceholders(personalizationArray).map((el) => {
                  return (
                    <p className="text-ss bg-white border-[1px] border-[#BFCCDA] min-w-[80px]  px-2 rounded-[6px] h-[30px] flex items-center justify-center">
                      {el}
                    </p>
                  );
                })}
              </div>
            )}

            <FiChevronDown />
          </div>

          <div className="bg-white rounded-[6px] shadow-md absolute  top-[3.5em] w-[100%] z-10">
            {open &&
              contactArray.map((el) => {
                return (
                  <p className="border-b-[1px] p-2 text-ss cursor-pointer" onClick={() => addToText(el.value)}>
                    {el.label}
                  </p>
                );
              })}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">SMS Text</p>
            <p className="text-s text-[#525252]">{count} /160</p>
          </div>

          <div className="">
            <textarea
              name="content"
              id=""
              cols="30"
              value={values.content}
              onChange={handleContent}
              placeholder={"Enter a message"}
              rows="10"
              className="border-[1px] rounded-md w-[100%] h-[200px] resize-none p-2 outline-none text-sm placeholder:text-sm"
            />
          </div>

          {count >= 160 ? (
            <p className="text-[#D97706] text-s mt-[1em]">
              You have exceeded the standard text limit of 160 characters. Additional charges will apply.
            </p>
          ) : null}
        </div>
      </form>

      <div className="bg-[#FAFAFA] flex flex-col items-center w-[400px]">
        <div className="bg-white flex items-center justify-center drop-shadow-md py-3 px-2 w-full">
          <p>Preview</p>
        </div>
        <div className="border-[2px]  border-black bg-white  w-[300px] h-[60vh] items-end rounded-[48px] p-2 pb-0 mt-10">
          <div className="border-[2px]  border-[#D0D5DD]  w-[100%] h-[98%] items-end rounded-[40px] overflow-hidden">
            <div className="bg-[#F5F5F5] h-[90px] flex items-center justify-center flex-col space-y-1">
              <div className="bg-[#969AA5] w-[40px] h-[40px] mx-auto rounded-full flex justify-center items-center">
                <div className=" bg-[#969AA5]  flex items-center justify-center rounded-full overflow-hidden w-[40px] h-[30px]">
                  <IoPersonCircleSharp className="text-[28px] text-white" />
                </div>
              </div>

              <p className="text-s font-[400]">{values.sender_name || "Select a sender ID"}</p>
            </div>

            <div className="text-center space-y-[1px] mt-2 text-ss">
              <p>Text message</p>
              <p>{dayjs().format("h:mm a")}</p>
            </div>

            <div className="bg-[#E9E9EB] m-3 p-3 rounded-[10px] min-h-[100px]">
              <p className="text-s">
                {values.content.length < 1
                  ? "Enter your SMS text to see preview."
                  : serializeMessage(values.content, user?.data)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSContent;
