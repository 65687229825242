import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import React from "react";
import { useSearchParams } from "react-router-dom";

const BottomBar = ({ handleModal, step, setStep, handleSave, disableButton }) => {
  const [params] = useSearchParams();

  console.log(disableButton);
  return (
    <div
      className={`flex justify-between border-t-[1px] fixed bottom-0 right-0 px-4 h-[60px] items-center bg-white ${
        params.get("sidebar") === "true" ? "w-[calc(100vw-80px)] " : "w-[calc(100vw-250px)]"
      } transition ease-in`}
    >
      <div onClick={handleModal}>
        <GreyButton text="Save & Exit" />
      </div>

      <div className="flex justify-between space-x-4">
        {step !== 1 && <GreyButton text="Back" onClick={() => setStep(step - 1)} />}

        <BlueButton
          text={step === 3 ? "Publish" : "Next"}
          className="h-[40px] text-s"
          onClick={handleSave}
          disabled={disableButton}
        />
      </div>
    </div>
  );
};

export default BottomBar;
