import { useMutation, useQuery } from "@tanstack/react-query";
import GreaterThan from "assets/images/greaterThan.svg";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useModalHooks from "hooks/modalHooks";
import { useCallback } from "react";
import { createSmsCampaign } from "services/campaignService";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import useLoggedInUser from "hooks/useLoggedInUser";
import { trackEvent } from "config/mixpanel";
import Content from "./Content";
import Publish from "./Publish";
import Audience from "./Audience";
import PublishModal from "../../common/PublishModal";
import SaveAndExitModal from "../../common/SaveAndExitModal";
import { getCampaignDetails } from "services/campaignService";
import { queryClient } from "config/gateway";
import { saveAndExit } from "services/campaignService";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const tabs = [
  { name: "Content", value: 0 },
  { name: "Audience", value: 1 },
  { name: "Publish", value: 2 },
];

const CreateVoice = () => {
  const { user } = useLoggedInUser();
  const [step, setStep] = useState(0);
  const { id } = useParams();
  const [closeModal] = useModalHooks();
  const [open, setOpen] = useState(null);
  const [disableButton, setDisabledButton] = useState(true);
  const { sidebar } = useSideBarStore();

  const navigate = useNavigate();
  const [voiceData, setVoiceData] = useState({
    CampaignChannel: "roboCall",
    content: "",
    sender_name: "mooyi",
    campaign_name: "",
    campaignObjective: "",
    recipientArray: [],
    audioLength: 5,
    personal: 0,
  });

  const { mutate, isLoading } = useMutation(createSmsCampaign, {
    onSuccess: () => {
      Toast.success("voice call sent successfully");
      queryClient.invalidateQueries(["allVoiceCampaigns"]);
      localStorage.removeItem("tagData");
      localStorage.removeItem("segmentData");

      handleModal();
      navigate("/broadcasts/voice");
    },
    onError: (error) => {
      Toast.error(`${error}`);
    },
  });

  const { mutate: saveExit } = useMutation(saveAndExit, {
    onSuccess: () => {
      Toast.success(`Voice saved successfully`);
      localStorage.removeItem("tagData");
      localStorage.removeItem("segmentData");
      navigate(`/broadcasts/voice`);
      queryClient.invalidateQueries(["allVoiceCampaigns"], ["onBoarding"]);
    },
    onError: () => {
      Toast.error(`Voice call failed`);
    },
  });

  const { data, isLoading: loadDetails } = useQuery(["fetchVoiceId", id], () => getCampaignDetails(id), {
    enabled: !!id,
  });

  console.log(data, "new data");

  useEffect(() => {
    if (data) {
      setVoiceData({
        ...voiceData,
        campaign_name: data.campaignName || "",
        sender_name: data.senderId || "",
        campaignObjective: data.campaignObjective || "promotional",
        content: data.campaignContent || "",
        recipientArray: data.recipientArray || [],
      });
    }
  }, [data]);

  const handleSave = () => {
    saveExit({ campaignId: id, payload: updatedInfo });
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const displayStep = () => {
    switch (step) {
      case 0:
        return <Content data={voiceData} setData={setVoiceData} loading={loadDetails} id={id} />;
      case 1:
        return <Audience data={voiceData} setData={setVoiceData} />;
      case 2:
        return <Publish data={voiceData} setData={setVoiceData} />;
      default:
    }
  };

  const updatedInfo = {
    campaign_channel: "robocall",
    campaign_name: voiceData.campaign_name,
    campaign_purpose: voiceData.campaignObjective,
    sender_name: voiceData.sender_name,
    campaign_subject: "MooyiCamp",
    content: voiceData.content,
    audioLength: voiceData.audioLength,
    recipient: {
      personalContacts: voiceData.recipientArray.map((item) => item.name),
    },
    campaignObjective: voiceData.campaignObjective,
    totalReceiver: voiceData.personal,
    receiverGroup: {
      mooyiAud: 0,
      personal: voiceData.personal,
    },
    // campaign_data: voiceData?.campaign_date,
    // campaign_status: voiceData?.campaign_status,
  };

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else if (step === 2) {
      setOpen(1);
    }
  };

  const proceedSubmit = async () => {
    trackEvent("publish_campaign", {
      email: user?.data?.email,
      campaignName: voiceData.campaign_name,
      date: voiceData.personal,
      isScheduledForLater: voiceData.scheduled,
      campaignType: "robocall",
    });

    mutate(updatedInfo);
    // console.log(updatedInfo);
  };

  const checkState = useCallback(() => {
    if (step === 0) {
      if (voiceData.content !== "") {
        setDisabledButton(false);
      }
    } else if (step === 1) {
      if (voiceData.personal !== 0) {
        setDisabledButton(false);
        setOpen(2);
      } else {
        setDisabledButton(true);
      }
    }
  }, [step, voiceData.content, voiceData.personal]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  return (
    <div onClick={closeModal}>
      <div className="bg-white p-5 rounded-lg h-[100vh] overflow-y-auto mb-[4rem]">
        <div className="px-14">
          <div className="flex space-x-4 mt-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%]">
            {tabs.map((el, i) => {
              return (
                <div
                  className="flex items-center justify-between w-[45%]"
                  key={el.value}
                  onClick={() => moveToStep(el.value)}
                >
                  <div className="flex items-center">
                    <p
                      className={`${
                        step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                      } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                    >
                      {el.value + 1}
                    </p>
                    <p
                      className={`px-2 cursor-pointer text-s ${
                        step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                      } `}
                    >
                      {el.name}
                    </p>
                  </div>

                  {el.value === 2 ? null : <img src={GreaterThan} alt="GreaterThan" />}
                </div>
              );
            })}
          </div>
        </div>

        <div className={`w-[90%] mx-auto  my-[2em]`}>{displayStep()}</div>
      </div>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <GreyButton text="Back" onClick={() => (step === 0 ? navigate("/broadcasts/voice") : setStep(step - 1))} />

        <div className="flex justify-between space-x-4">
          <GreyButton
            text="Save & exit"
            onClick={() => handleModal(4)}
            disabled={(voiceData.content === "") | (voiceData.content === undefined) ? true : false}
          />

          <BlueButton
            text={`${step === 2 ? "Publish" : "Next"}`}
            css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
            disabled={disableButton}
            onClick={handleNext}
          />
        </div>
      </div>
      {open === 1 ? (
        <PublishModal
          handleSubmit={proceedSubmit}
          setData={setVoiceData}
          data={voiceData}
          handleModal={handleModal}
          campType="voice message"
        />
      ) : null}
      {open === 4 ? <SaveAndExitModal handleModal={() => handleModal(null)} handleSave={handleSave} /> : null}
    </div>
  );
};

export default CreateVoice;
