import { useMutation } from "@tanstack/react-query";
import Toast from "config/toast";
import EmptyState from "pages/Contacts/EmptyState";
import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import contactEmpty from "assets/icons/contacts/ContactEmpty.png";
import { formatDate, formatDateAlone } from "lib/formatDate";
import { RiFilter2Fill } from "react-icons/ri";
import { BsSearch } from "react-icons/bs";
import { Tag } from "assets/icons/contacts/Tag";
import { Delete } from "assets/icons/contacts/Delete";
import { MessagingIcon } from "assets/icons/sidebar";
import { SmsIcon } from "assets/svgs/SmsIcon";
import { Volume } from "assets/svgs/Volume";
import DeleteContact from "pages/Contacts/DeleteContact";
import AddToTagsModal from "pages/Contacts/AddToTagsModal";
import CreateTagModal from "pages/Contacts/CreateTagModal";
import { addContactToTag } from "services/contactService";
import { queryClient } from "config/gateway";
import { useFormik } from "formik";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { CreateTagSuccessModal } from "../Tags/CreateTagSuccessModal";
import EmailModal from "pages/Contacts/IndividualEmail/EmailModal";
import { SurveyIcon } from "assets/icons/sidebar";
import SurveyDetailsModal from "pages/Dashboard/quickActionModals/SurveyDetailsModal";
import { deleteContacts } from "services/contactService";
import Filter from "../components/filterComponent";
import SecondaryInput from "components/Inputs/SecondaryInput";
import PrimaryButton from "components/Button/PrimaryButton";
import ModalWithoutClose from "components/ModalWithoutClose";
import { IoCloseCircle } from "react-icons/io5";
import BlueButton from "components/Button/BlueButton";

const actionsIcons = [
  { id: 1, icon: MessagingIcon, label: "Email" },
  { id: 2, icon: SmsIcon, label: "SMS" },
  { id: 3, icon: Volume, label: "Voice" },
  { id: 4, icon: Tag, label: "Tag" },
  { id: 5, icon: Delete, label: "Delete" },
];

const tagIcons = [
  { id: 1, icon: MessagingIcon, label: "Email" },
  { id: 2, icon: SmsIcon, label: "SMS" },
  { id: 3, icon: Volume, label: "Voice" },
  { id: 4, icon: SurveyIcon, label: "Surveys" },
  { id: 5, icon: Delete, label: "Delete" },
];

const AllContacts = ({
  data,
  isLoading,
  isFetchingContacts,
  deleteContactsFunc,
  pageCountStart,
  pageCountEnd,
  currentPage,
  handlePrev,
  tableType,
  handleNext,
}) => {
  const navigate = useNavigate();
  const [selectArray, setSelectArray] = useState([]);
  const [tableData, setTableData] = useState();
  const [active, setActive] = useState(false);
  const [openModal, setopenModal] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [query, setQuery] = useState("");
  const [hover, setHover] = useState(null);
  const [openOrgFilter, setOpenOrgFilter] = useState(false);
  const [multiPhoneNo, setMultiPhoneNo] = useState([]);

  const handleHover = (id) => {
    setHover(hover === id ? null : id);
  };

  const handleOpenFilter = () => {
    setOpenOrgFilter(!openOrgFilter);
  };

  console.log(selectArray, "ids", multiPhoneNo, "Phone");

  const initialValues = {
    dateFrom: "",
    dateTo: "",
    updatedFrom: "",
    updatedTo: "",
    gender: [],
  };

  const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation(deleteContacts, {
    onSuccess: () => {
      Toast.success("Contact(s) deleted successfully!!");
      queryClient.invalidateQueries(["allContactTab"]);
      queryClient.invalidateQueries(["tagContacts"]);
      handleDelete();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const selectAll = (e) => {
    if (e.target.checked) {
      data?.contacts?.map((el) => selectArray.push(el._id));
      for (let i = 0; i < data?.contacts?.length; i++) {
        setSelectArray([...selectArray, data?.contacts[i]?._id]);
        setMultiPhoneNo([...selectArray, data?.contacts[i]?.phone]);
      }
    } else {
      setSelectArray([]);
      setMultiPhoneNo([]);
    }
  };

  const { mutate: addToTag } = useMutation(addContactToTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["allTags"]);
      Toast.success("contact Added Successfully");
      handleAddTag();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleAddToTags = () => {
    const payload = {
      contactIds: selectArray,
      tag: selectedTag,
    };

    addToTag(payload);
  };

  const stringArray = JSON.stringify(multiPhoneNo);

  const handleAddTag = (id) => {
    if ((id === 2) & active) {
      navigate(`/contacts/${stringArray}/sms`);
      // localStorage.setItem("contactArray", stringArray);
    } else if (id === 3 && active) {
      navigate("/broadcasts/voice/create");
      localStorage.setItem("contactArray", stringArray);
    } else setopenModal(openModal !== id && active ? id : null);
  };

  const handleChangeText = (e) => {
    setQuery(e.target.value);
  };

  const searchFilter = useCallback(
    (searchTerm) => {
      const dataSet = data?.contacts?.filter((item) => {
        const content = item?.firstname?.toLowerCase() || item?.lastname?.toLowerCase() || item?.email?.toLowerCase();
        return content?.includes(searchTerm?.toLowerCase());
      });

      setTableData(dataSet);
    },
    [data],
  );

  useEffect(() => {
    searchFilter(query);
  }, [query, searchFilter]);

  const deleteContact = () => {
    setActive(false);
    setSelectArray([]);
    setMultiPhoneNo([]);
    deleteMutate({ contactId: selectArray });
  };

  const checkArray = useCallback(() => {
    if (selectArray.length > 0) {
      setActive(true);
    } else if (selectArray.length <= 0) {
      setActive(false);
    }
  }, [selectArray.length, setActive]);

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectArray) {
      if (selectArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x?._id]);
      setMultiPhoneNo([...multiPhoneNo, x?.phone]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x?._id);
      const filterPhoneNumbers = multiPhoneNo?.filter((el) => el !== x?.phone);
      setSelectArray(filterArray);
      setMultiPhoneNo(filterPhoneNumbers);
    }
  };

  const handleDelete = (id) => {
    setopenModal(openModal !== id && active ? id : null);
  };

  const handleMore = (id) => {
    navigate(`/contacts/${id}`);
  };

  const columns = [
    {
      field: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => selectAll(e)}
          />
        </p>
      ),
    },
    { field: "firstname", header: "First name" },
    { field: "lastname", header: "Last name" },
    { field: "email", header: "Email Address" },
    { field: "phone", header: "Phone Number" },
    { field: "dateOfBirth", header: "Date of birth" },
    { field: "gender", header: "Gender" },
    // { field: "status", header: "Status" },
    { field: "tag", header: "Tag" },
    { field: "createdAt", header: "Date created" },
    { field: "updatedAt", header: "Date updated" },
  ];

  const onSubmit = async (data) => {
    console.log(data);
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const gender = [
    { id: 1, type: "Male" },
    { id: 2, type: "Female" },
  ];

  const [selectStatus, setSelectStatus] = useState([]);

  const handleSelect = (id) => {
    setSelectStatus((prevStatus) =>
      prevStatus.includes(id) ? prevStatus.filter((item) => item !== id) : [...prevStatus],
    );
  };

  console.log(selectStatus);

  const contactData = tableData?.map((item, i) => ({
    inputProps: (
      <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0 z-[100]">
        <input
          type="checkbox"
          name="recipient"
          id="recipient"
          checked={checkInArray(item?._id, selectArray)}
          className="pl-0 w-[15px] h-[15px] accent-purple"
          onChange={(e) => handleCheck(e, item)}
        />
      </p>
    ),
    id: item._id,
    firstname: (
      <p className="hover:underline" onClick={() => handleMore(item._id)}>
        {item.firstname}
      </p>
    ),
    lastname: (
      <p className="hover:underline" onClick={() => handleMore(item._id)}>
        {item.lastname}
      </p>
    ),
    email: item.email,
    phone: item.phone,
    dateOfBirth: formatDateAlone(item.birthday),
    gender: item.gender,
    // status: "status",
    tag: (
      <div className="flex items-center space-x-1">
        {item.tag.length > 2
          ? item.tag.slice(3).map((el, i) => (
              <div key={i} className="p-1 bg-gray-100 text-[12px] rounded">
                {i === 2 ? `+${item.tag.length - 2} more` : el}
              </div>
            ))
          : item.tag.map((el, i) => (
              <div key={i} className="p-1 bg-gray-100 text-[12px] rounded">
                {el}
              </div>
            ))}
      </div>
    ),
    createdAt: formatDate(item.createdAt),
    updatedAt: formatDate(item.updatedAt),
  }));

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  console.log(contactData);

  return (
    <div>
      <div className="p-4 flex items-center justify-between relative border-x w-full overflow-x-auto">
        <div className="flex items-center space-x-2">
          <p>Actions:</p>
          <div className="flex items-center space-x-2">
            {(tableType === "special" ? tagIcons : actionsIcons).map((el, i) => {
              const IconComponent = el.icon;
              return (
                <div
                  onClick={() =>
                    el.id === 5
                      ? handleDelete(5)
                      : el.id === 4
                      ? tableType === "special"
                        ? handleAddTag(7)
                        : handleAddTag(4)
                      : handleAddTag(el.id)
                  }
                  onMouseEnter={() => handleHover(el.id)}
                  onMouseLeave={() => setHover(null)}
                  className={`w-[40px] h-[40px] cursor-pointer rounded-[4px] border ${
                    active ? "border-primary-800" : ""
                  } flex items-center justify-center`}
                >
                  <IconComponent fill1={active ? "#95ADFF" : "#A3A3A3"} fill2={active ? "#1D19AF" : "#404040"} />
                  {hover === el.id ? (
                    <p
                      className={`bg-white drop-shadow-md text-[12px] ${
                        active ? "text-primary-800" : ""
                      } py-1 px-2 top-12 rounded absolute`}
                    >
                      {el.label}
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-white border w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
            <BsSearch className="text-[#9CA3AF]" />
            <input
              type="text"
              className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
              placeholder="Search contacts... "
              onChange={handleChangeText}
            />
          </div>

          <div className="flex space-x-3">
            <button
              className={`flex items-center text-gray-500 border rounded-[6px] px-[15px] py-[9px] text-s space-x-2`}
              onClick={handleOpenFilter}
            >
              <p>Filter by</p>
              <RiFilter2Fill />
            </button>
            {openOrgFilter ? (
              <Filter openOrgFilter={openOrgFilter} setOpenOrgFilter={setOpenOrgFilter}>
                <div>
                  <div className="space-y-2 text-[14px]">
                    <div className="flex items-center justify-between">
                      <p>Filter by</p>
                      <p className=" cursor-pointer" onClick={() => setOpenOrgFilter(false)}>
                        <IoCloseCircle />
                      </p>
                    </div>
                    <form action="">
                      <div className="space-y-2 text-[14px]">
                        <p>Gender</p>
                        <div className="flex justify-between items-center space-x-2 cursor-pointer">
                          {gender.map((items, i) => {
                            return (
                              <div
                                key={i}
                                className={`border-[0.5px] rounded-[8px] border-gray-200 w-full flex justify-center p-2 ${
                                  selectStatus.includes(items.id) ? "border-primary-800 text-primary-800" : ""
                                }`}
                                onClick={() => handleSelect(items.id)}
                              >
                                {items.type}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <p className="pt-4">Date added</p>
                      <div className="flex justify-between items-center space-x-2 pt-2">
                        <SecondaryInput
                          name="dateFrom"
                          value=""
                          text="From:"
                          type="date"
                          placeholder="From--:"
                          onChange={handleChange}
                        />
                        <SecondaryInput
                          name="dateTo"
                          value=""
                          text="To:"
                          type="date"
                          placeholder="To--:"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="pt-2 space-y-2 text-[14px]">
                        <p>Last updated</p>
                        <div className="flex justify-between items-center space-x-2">
                          <SecondaryInput
                            name="updatedFrom"
                            value=""
                            text="From:"
                            type="date"
                            placeholder="From--:"
                            onChange={handleChange}
                          />
                          <SecondaryInput
                            name="updatedTo"
                            value=""
                            text="To:"
                            type="date"
                            placeholder="To--:"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <hr className="border-gray-200 my-3" />
                  <div className="flex justify-between">
                    <div className="px-4 py-3 border flex items-center justify-center">Clear all</div>
                    <div className="w-fit">
                      <BlueButton text="Apply" type="submit" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </Filter>
            ) : null}
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="min-w-[100%] mb-4 border-b-[1px] bg-white overflow-x-auto w-screen">
          <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
            <tr className="h-[50px] border-r-[1px] rounded-[2px] overflow-hidden">
              {columns &&
                columns.map((head, i) => (
                  <th key={i} className="px-2 text-left border-l-[1px]">
                    {head.header}
                  </th>
                ))}
            </tr>
          </thead>
          {contactData?.length === 0 || contactData?.length === undefined ? null : (
            <tbody>
              {isLoading || isFetchingContacts || loadingDelete ? (
                <>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                    <tr
                      key={i}
                      className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] "
                    >
                      {columns?.map((col, i) => (
                        <td key={i} className="pl-2 border-l-[1px] relative">
                          <Skeleton />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {contactData &&
                    contactData?.map((row, i) => (
                      <tr
                        className="h-[50px] text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px] hover:bg-slate-50 cursor-pointer"
                        key={i}
                      >
                        {columns?.map((col, j) => (
                          <td key={j} className="px-4 border-l-[1px] relative">
                            {row[col.field] || "-"}
                          </td>
                        ))}
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          )}
        </table>
      </div>

      {contactData?.length > 0 && (
        <div className="flex justify-between items-center">
          <p className="text-s flex items-center space-x-1">
            {/* Showing {pageCountStart} to {pageCountEnd} of {data.totalContacts} results */}
            <span>preview per page</span> <span className="border py-1 px-3 flex items-center justify-center">10</span>
          </p>

          <div className="flex items-center space-x-4">
            <div
              onClick={pageCountStart === 1 || isFetchingContacts ? null : handlePrev}
              className={`hover:border py-1 px-2 ${
                pageCountStart === 1 || isFetchingContacts ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <MdKeyboardArrowLeft />
            </div>
            <div className="flex items-center space-x-1 text-s">
              <span className="border py-1 px-3 flex items-center justify-center">
                {pageCountStart} - {pageCountEnd}
              </span>{" "}
              <span>of</span> <span className="border py-1 px-3 flex items-center justify-center">{currentPage}</span>
            </div>
            <div
              onClick={pageCountEnd === data?.totalContacts || isFetchingContacts ? null : handleNext}
              className={`hover:border py-1 px-2 ${
                pageCountEnd === data?.totalContacts || isFetchingContacts
                  ? "text-gray-300 cursor-not-allowed"
                  : "cursor-pointer"
              }`}
            >
              <MdKeyboardArrowRight />
            </div>
            {/* <GreyButton
                type="button"
                text="Prev"
                disabled={pageCountStart === 1 || isFetchingContacts}
                onClick={handlePrev}
              />

              <GreyButton
                onClick={handleNext}
                disabled={pageCountEnd === data?.totalContacts || isFetchingContacts}
                text="Next"
                type="button" 
              /> */}
          </div>
        </div>
      )}

      {contactData?.length === 0 || contactData?.length === undefined ? (
        <tbody className="flex justify-center w-full mt-20">
          <EmptyState
            title="No Contacts Found."
            icon={contactEmpty}
            content={
              <p>
                You haven't added any contacts yet. <br /> Click the 'Create new Contacts' button to begin.
              </p>
            }
            link="/contacts/create"
            button="Create new contact"
          />
        </tbody>
      ) : null}
      {openModal === 1 ? <EmailModal toggleModal={handleAddTag} data={selectArray} /> : null}
      {openModal === 5 ? (
        <DeleteContact
          toggleModal={handleDelete}
          deleteContactsFunc={deleteContact}
          title="Delete contact(s)"
          content="Are you sure you want to delete? This action cannot be undone."
        />
      ) : null}
      {openModal === 3 ? (
        <AddToTagsModal
          toggleModal={handleAddTag}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          handleAddToTags={handleAddToTags}
        />
      ) : null}
      {openModal === 4 ? (
        <CreateTagModal toggleModal={() => handleAddTag(6)} closeModal={() => handleAddTag(4)} />
      ) : null}
      {openModal === 6 ? <CreateTagSuccessModal handleModal={() => handleAddTag(3)} /> : null}
      {openModal === 7 ? <SurveyDetailsModal handleOpen={handleAddTag} /> : null}
    </div>
  );
};

export default AllContacts;
