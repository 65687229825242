import React, { useCallback, useEffect, useState } from "react";
import GenerateLink from "./GenerateLink";
import { CONTACT_TYPE } from "data/constants/surveyConstants";
import AudienceModal from "./AudienceModal";
import SavedContact from "./SavedContact";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchBySurveyId } from "services/newSurveyService";
import { useQuery } from "@tanstack/react-query";
import TargetingOptions from "./TargetingOptions";
import Typography from "utils/typography";
import { FaPencilAlt } from "react-icons/fa";
import SurveyDetailsModal from "./SurveyDetailsModal";
import { LuDot } from "react-icons/lu";

const AudienceType = ({ step, setStep }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(null);
  const [params] = useSearchParams();

  const { data } = useQuery(["fetchBySurveyId-aud", id], () => fetchBySurveyId(id), {
    enabled: !!id,
  });

  const surveyData = data?.data;

  const audienceType = surveyData?.targetingOption?.audienceType || params.get("audienceType");
  const audienceCheck = params.get("audienceType");

  const [headingData] = useState({
    name: audienceType === CONTACT_TYPE.GENERATE_LINK ? "Add your expected responses." : "Set your targeting criteria",
  });

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const displayAudience = useCallback(() => {
    switch (params.get("audienceType")) {
      case CONTACT_TYPE.GENERATE_LINK:
        return <GenerateLink audienceType={audienceType} step={step} setStep={setStep} />;
      case CONTACT_TYPE.PERSONAL_CONTACT:
        return <SavedContact audienceType={audienceType} step={step} setStep={setStep} />;
      case CONTACT_TYPE.MOOYI_AUDIENCE || CONTACT_TYPE.CONNECT_API:
        return (
          <TargetingOptions
            step={step}
            setStep={setStep}
            resetAudience={() => handleModal(1)}
            headingName={headingData.name}
            editSurvey={() => handleModal("openSurveyModal")}
          />
        );
      case CONTACT_TYPE.CONNECT_API:
        return <TargetingOptions audienceType={audienceType} step={step} setStep={setStep} />;
      default:
    }
  }, [audienceType, params, setStep, step]);

  useEffect(() => {
    displayAudience();
  }, [displayAudience, audienceType]);

  return (
    <div>
      {open === 1 ? <AudienceModal handleModal={handleModal} from="step2" /> : null}

      {open === "openSurveyModal" ? (
        <SurveyDetailsModal handleOpen={handleModal} from="onlineSurvey" fromStep={true} />
      ) : null}

      {audienceCheck === "buy_targeted_response" ? null : (
        <div className="flex items-center justify-between py-1">
          <div className="flex items-center space-x-1">
            <Typography.SubText className="font-semibold">{headingData.name}</Typography.SubText>
            <LuDot className="text-2xl" />
            <div
              className="hover:bg-gray-200 p-2 cursor-pointer rounded"
              onClick={() => handleModal("openSurveyModal")}
            >
              <FaPencilAlt className="text-gray-600" />
            </div>
          </div>

          <div
            className="flex items-center justify-center text-xs  cursor-pointer border-[1px] border-primary-800 text-primary-800 w-[130px] h-[40px] rounded-md hover:bg-primary-50"
            onClick={() => handleModal(1)}
          >
            <p> Reset Audience</p>
          </div>
        </div>
      )}

      {displayAudience()}
    </div>
  );
};

export default AudienceType;
