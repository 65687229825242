import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const COLORS = ["#BDCEFF", "#95ADFF", "#6B7FFF", "#4852FF", "#2D28FF", "#251DE5", "#1D19AF", "#1F1E91", "#141254"];

const MatrixBarCharts = ({ data }) => {
  const keys = Object.keys(data[0]).filter((key) => key !== "name");

  // console.log(data, "MatrixBarCharts");

  return (
    <>
      {/* <div className="flex gap-4 flex-wrap mb-4">
        {data?.map((elm, index) => {
          return (
            <div key={`optionTag${index}`} className="flex items-center space-x-2 p-1 px-2 border-[1px] rounded-[4px]">
              <div className="w-[10px] h-[10px] rounded-sm" style={{ background: COLORS[index % COLORS.length] }}></div>
              <p className="text-ss">{elm.name === "MooyiOption" ? "Others" : elm.name}</p>
            </div>
          );
        })}
      </div> */}
      <div className="w-[100%] h-[400px] text-xs">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            barSize={35}
            margin={{
              top: 20,
              right: 30,
              left: -20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {keys.map((key, index) => (
              <Bar
                radius={[2, 2, 0, 0]}
                key={key}
                dataKey={key}
                stackId="a"
                fill={COLORS[index % 10]}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default MatrixBarCharts;

// const getColor = (index) => {
//   const colors = ["#BDCEFF", "#6B7FFF", "#95ADFF", "#2D28FF", "#86EFAC"];
//   return colors[index % colors.length];
// };
