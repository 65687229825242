import SelectInput from "components/Inputs/SelectInput";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { useStore } from "store";
import fileIcon from "assets/icons/broadcast/fileIcon.png";
import emptyAudio from "assets/icons/broadcast/emptyAudio.png";
import receiptIcon from "assets/icons/broadcast/receiptIcon.png";
import MediaUpload from "components/Inputs/customFileUpload/MediaUpload";
import { SaveIcon } from "assets/icons/broadcast/saveIcon";
import PreLoader from "components/Loader/PreLoader";
import AudioPlayer from "components/AudioPlayer";
import Tooltip from "components/Tooltip";
import Dropdown from "components/DropDown";
import { GoSync } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { textToSpeech } from "services/campaignService";
import Toast from "config/toast";

const Content = ({ data, setData, loading, id }) => {
  const [duration, setDuration] = useState(null);
  const { voiceTemplate } = useStore();
  const [selectType, setType] = useState(null);
  const [audioData, setAudioData] = useState();
  const [hover, setHover] = useState(null);
  const [voice, setVoice] = useState({
    voiceType: "",
    voiceText: "",
  });

  const handleHover = (id) => {
    setHover(id === hover ? null : id);
  };

  useEffect(() => {
    if (data) {
      setAudioData(data.content);
    }
  }, [data]);

  const handleClearAudio = () => {
    setData({
      ...data,
      content: "",
    });
  };

  useEffect(() => {
    if (voiceTemplate.content !== "") {
      handleSelectType(2);
      setVoice({
        ...voice,
        voiceText: voiceTemplate.content,
      });
    }
  }, [voiceTemplate.content]);

  const handleSelectType = (id) => setType(selectType !== id ? id : null);

  const initialValues = {
    content: data?.content || "",
    campaign_name: data?.campaign_name || "",
    campaignObjective: data?.campaignObjective || voiceTemplate.category || "",
    ...voiceTemplate,
  };

  const onSubmit = async () => {
    console.log("new Data");
  };

  const showAudio = !!audioData || !!data?.content;
  const audioPlay = data?.content || audioData?.audio || audioData;

  const { handleChange, values, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getDuration = (audioData) => {
      return new Promise((resolve) => {
        const audio = new Audio();
        audio.addEventListener("loadedmetadata", () => {
          resolve(audio.duration);
        });
        audio.src = audioData;
      });
    };

    getDuration(audioData).then((length) => {
      const roundedLength = Math.round(length);
      setDuration(roundedLength);
      setData((prevData) => ({ ...prevData, audioLength: roundedLength }));
    });
  }, [audioData]);

  console.log(duration, typeof duration, "duration");

  useEffect(() => {
    if (audioData) {
      setData((prevData) => ({ ...prevData, ...values, content: audioData?.audio ?? audioData }));
    }
  }, [audioData, setData, values]);

  const objectives = [
    { label: "Promotional", value: "promotional" },
    { label: "Transactional", value: "transactional" },
    { label: "Remainder", value: "remainder" },
    { label: "Educational", value: "educational" },
    { label: "Survey/Feedback", value: "survey/feedback" },
  ];

  const { mutate, isLoading } = useMutation(textToSpeech, {
    onSuccess: (data) => {
      Toast.success("Successful conversion to audio");
      console.log(data);
      setData((prevData) => ({ ...prevData, ...values, content: data?.file }));
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const type = [
    { name: "Upload a file", id: 1, icon: fileIcon },
    { name: "Text to speech", id: 2, icon: receiptIcon },
  ];

  if (loading && data !== undefined && id !== undefined) {
    return <PreLoader />;
  }

  const voiceType = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const handleDropdownSelect = (e) => {
    setVoice({
      ...voice,
      voiceType: e,
    });
  };

  const handleChangeText = (e) => {
    setVoice({
      ...voice,
      voiceText: e,
    });
  };

  const handleVoiceSubmit = () => {
    mutate({ txt: voice.voiceText });
  };

  return (
    <div className="flex w-[100%] justify-between">
      <form onSubmit={handleSubmit} className="w-[50%]">
        <p className="text-[20px] text-[#0F172A] tracking-[-0.02em] font-semibold">Content</p>
        <p className="text-s font-[450] text-[#737373]">Enter the details of your message</p>
        <div>
          <div className="flex space-x-1 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">Title</p>
          </div>
          <div>
            <PrimaryInput
              name="campaign_name"
              onChange={handleChange}
              value={values.campaign_name}
              placeholder="Enter the name of your message"
            />
          </div>
        </div>

        <div>
          <div className="flex space-x-2 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">Objectives</p>
            <Tooltip text="What is the aim of the message?" />
          </div>
          <SelectInput
            name="campaignObjective"
            options={[
              {
                label: "Select objective",
                value: "",
              },
              ...objectives,
            ]}
            css="h-[50px] rounded-[8px]"
            value={values.campaignObjective}
            onChange={handleChange}
          />
        </div>

        <div>
          <div className="flex space-x-2 items-center mt-[1em]">
            <p className="text-gray-700 text-sm font-medium">Type</p>
          </div>
          <div className="flex items-center space-x-5 w-full pt-2">
            {type.map((el, i) => (
              <div
                key={i}
                className={`border rounded-[8px] cursor-pointer ${
                  selectType === el.id && "border-primary-600 bg-[#EBF1FF]"
                } flex items-center px-4 py-3 w-full`}
                onClick={() => handleSelectType(el.id)}
              >
                <div
                  className={`w-[32px] h-[32px] p-2 ${
                    selectType === el.id ? "bg-white" : "bg-[#EBF1FF]"
                  } rounded-full flex items-center justify-center`}
                >
                  <img src={el.icon} alt="" className="" />
                </div>
                <div>{el.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          {selectType === 1 ? (
            <div>
              <div className="mt-[1.5em]">
                <p className="text-gray-700 text-sm font-medium">Audio file</p>
                <p className="text-gray-500 text-sm pt-2">Upload an audio file to continue.</p>
              </div>
              <div className="mt-3">
                <MediaUpload name="audio" data={audioData} setData={setAudioData} handleClear={handleClearAudio} />
              </div>
            </div>
          ) : selectType === 2 ? (
            <div>
              <div className="h-[200px]">
                <div>
                  <div className="w-full mt-5">
                    <div className="flex space-x-2 items-center ">
                      <p className="text-m font-[500]">Voice options</p>
                    </div>
                    <p className="text-[14px] py-1">Choose the voice your receiver hears when they play your message</p>
                    <div className="mt-2">
                      <Dropdown
                        tag={"Select voice"}
                        options={voiceType}
                        onSelect={(el) => handleDropdownSelect(el.value)}
                        value={voice.voiceType}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-5">
                    <div className="flex space-x-2 items-center ">
                      <p className="text-m font-[500]">Text</p>
                    </div>
                    <p className="text-[14px] py-1 flex items-center space-x-1">
                      Type in your message and click on the convert icon({" "}
                      <span>
                        <GoSync />
                      </span>{" "}
                      )process your text
                    </p>
                    <div className="mt-2 border rounded-md overflow-hidden">
                      <textarea
                        name="voice"
                        id="voice"
                        cols="30"
                        rows="5"
                        placeholder="Type in your message"
                        className="w-full mr-4 p-2 outline-none text-s placeholder:text-s resize-none"
                        onChange={(e) => handleChangeText(e.target.value)}
                        value={voice.voiceText}
                      ></textarea>
                      <div className="w-full px-5 py-1 flex justify-end border-t relative">
                        <div
                          className="w-[50px] h-[40px] rounded flex items-center cursor-pointer border justify-center bg-gray-50 hover:bg-primary-100 hover:border-primary-800 hover:text-primary-800"
                          onClick={handleVoiceSubmit}
                          onMouseEnter={() => handleHover(1)}
                          onMouseLeave={() => handleHover(null)}
                        >
                          <GoSync />
                        </div>
                        {hover === 1 ? (
                          <div className="bg-white drop-shadow-sm w-fit px-2 py-1 flex items-center justify-center absolute rounded-md border top-[-10px] text-xs text-primary-800">
                            <p>convert</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </form>

      <div className=" w-[45%] bg-gray-50">
        <div className="bg-white flex items-center justify-center drop-shadow-md py-3 px-2 w-full">
          <p>Preview</p>
          {/* <div className={`border rounded p-2 w-fit ${showAudio ? "bg-[#EBF1FF] border-primary-800" : ""}`}>
            <SaveIcon fill1={showAudio ? "#95ADFF" : "#D4D4D4"} fill2={showAudio ? "#1D19AF" : "#404040"} />
          </div> */}
        </div>
        <div className="mt-2 p-4">
          {!showAudio ? (
            <div className="flex justify-center mt-[5rem]">
              <div>
                <img src={emptyAudio} alt="Empty audio" className="w-[99px] h-[99px]" />
                <p className="text-center pt-3">No preview yet</p>
              </div>
            </div>
          ) : (
            <AudioPlayer url={audioPlay} className="h-[300px]" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
