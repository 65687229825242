export const surveyTemplates = [
  {
    id: 0,
    surveyName: "",
    surveyCategory: "",
    surveyDescription: "",
    coverImage: "",
    reward: 0,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [],
  },
  {
    id: 1,
    surveyName: "Concept Test",
    surveySubTitle: "Tailor questions to fit your concept and gather valuable insights from participants.",
    surveyCategory: "Concept Test",
    surveyDescription:
      "Tailor the specific questions and options to the concept you are testing and the information you want to gather from the survey participants. Including a mix of rating scales, open-ended questions, and other questions that suit your needs is good practice.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Concept%20Test.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How well do you understand the concept? (Scale 1-5)",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Not at all 😞", "A little bit 😕", "So-so 😐", "Quite well 🙂", "Very well 😄"],
      },
      {
        question: "Is the concept relevant to your needs or interests? (Scale 1-5)",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Not at all 😞", "A little bit 😕", "So-so 😐", "Quite relevant 🙂", "Very relevant 😄"],
      },
      {
        question: "What are the main benefits of the concept? ",
        description: "(Share your thoughts)",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Rank these features in order of importance: ",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Cost-effectiveness", "Ease of use", "Performance", "Flexibility", "Customisation options"],
      },
      {
        question: "How likely are you to use or adopt this concept?",
        description: "Choose one:",
        questionType: "single_choice",
        optional: false,
        options: ["Very likely 😄", "Likely 🙂", "Neutral 😐", "Unlikely 😕", "Very unlikely 😞"],
      },
      {
        question: "Do you have any suggestions to improve the concept? ",
        description: "Share your ideas",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "How does this concept compare to its competitors in terms of uniqueness and advantages? ",
        description: "Share your thoughts",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 2,
    surveyName: "Ad Test",
    surveyCategory: "Ad Test",
    surveySubTitle: "Select creative concepts with the most potential for impactful results.",
    surveyDescription:
      "Remember that you can customise these questions based on the specific ad you’re testing or what you’re trying to discover. Simplicity helps ensure participants understand and provide valuable feedback.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Survey%20template%20cover%207.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How did you feel about the ad?",
        description: "Choose One",
        questionType: "single_choice",
        optional: false,
        options: ["Loved it! 😄", "Liked it 🙂", "It was okay 😐", "Didn't like it 😕", "Hated it 😞"],
      },
      {
        question: "Was the main message clear? ",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: [
          "Yes, very clear 👍",
          "Yes, somewhat clear 🙂",
          "Not sure 😐",
          "Not very clear 😕",
          "No, not clear at all 😞",
        ],
      },
      {
        question: "Do you remember which brand the ad was for?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Yes 👍", "No 😕"],
      },
      {
        question: "Did the ad catch your attention? ",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Very much! 😄", "Yes 🙂", "Neutral 😐", "Not really 😕", "Not at all 😞"],
      },
      {
        question: "What would you do after seeing the ad?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: [
          "Visit the website 👍",
          "Make a purchase 🙂",
          "Share with friends/family 😊",
          "Sign up for more information 😊",
          "Not likely to take any action 😕",
        ],
      },
      {
        question: "What would you change or improve about the ad?",
        description: "Share your ideas!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "How likely are you to share this ad with others?",
        description: "",
        questionType: "single_choice",
        optional: false,
        options: ["Very likely 😄", "Likely 🙂", "Neutral 😐", "Unlikely 😕", "Very unlikely 😞"],
      },
    ],
  },
  {
    id: 3,
    surveyName: "Product Test",
    surveyCategory: "Product Test",
    surveySubTitle: "Pilot new ideas before launch. Build with incredible market fit from day one.",
    surveyDescription:
      "The questions address different areas of product testing, such as overall satisfaction, usability, feedback on features and faults, purchase intent, and recommendations. You may customise and elaborate on these questions to fit your product and testing goals.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/New%20Product%20Development.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How much do you like the product?",
        description: "Choose One",
        questionType: "single_choice",
        optional: false,
        options: ["Loved it! 😄", "Liked it 🙂", "It was okay 😐", "Didn't like it 😕", "Hated it 😞"],
      },
      {
        question: "Was it easy to use the product?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Super easy 👍", "Easy 🙂", "So-so 😐", "Not very easy 😕", "Not easy at all 😞"],
      },
      {
        question: "What did you like the most about the product?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Do you have any ideas to make the product even better? ",
        description: "Share your ideas!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
      {
        question: "Will you buy this product again?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely 😄", "Yes, maybe 🙂", "Not sure 😐", "Probably not 😕", "No, never 😞"],
      },
      {
        question: "Would you recommend this product to others?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Yes, definitely 😄",
          "Yes, but with some reservations 🙂",
          "Not sure 😐",
          "No, not really 😕",
          "No, not at all 😞",
        ],
      },
      {
        question: "How does this product compare to others you've tried before?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 4,
    surveyName: "Feedback from Local Communities",
    surveyCategory: "Feedback from local Communities",
    surveySubTitle: "Gather feedback from residents on their community involvement.",
    surveyDescription:
      "These questions aim to gather feedback from residents to understand their level of community involvement, awareness of initiatives, preferred communication channels, concerns, service satisfaction, and suggestions for improving community engagement. Customise these questions as needed to suit the specific context and goals of the survey.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Feedback%20from%20Local%20Communities.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How often do you participate in community events or activities?",
        description: "Choose One",
        questionType: "single_choice",
        optional: false,
        options: ["Regularly 😄", "Occasionally 😊", "Rarely 😐", "Never 😞"],
      },
      {
        question: "How aware are you of what's happening in our community?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Very aware 😄", "Somewhat aware 🙂", "Kind of aware 😐", "Not aware at all 😞"],
      },
      {
        question: "How do you like to get updates about community events? ",
        description: "Select all that apply.",
        questionType: "multi_choice",
        optional: false,
        options: [
          "Social media 😊",
          "Local newspapers 😊",
          "Community website 😊",
          "Flyers/posters 😊",
          "Email newsletters 😊",
        ],
      },
      {
        question: "What are the most important concerns or issues in our community?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "How happy are you with community services and facilities?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Very dissatisfied 😞", "Dissatisfied 😞", "Neutral 😐", "Satisfied 🙂", "Very satisfied 😄"],
      },
      {
        question: "Are there enough chances for residents to share opinions and give feedback on community matters?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Yes, definitely 😄",
          "Yes, to some extent 🙂",
          "Not sure/Neutral 😐",
          "No, not really 😕",
          "No, not at all 😞",
        ],
      },
      {
        question: "How can we make community engagement better?",
        description: "Share your ideas!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },
  {
    id: 5,
    surveyName: "Competitor Analysis",
    surveyCategory: "Competitor Analysis",
    surveySubTitle: "Benchmark your brand against other players in the market.",
    surveyDescription:
      "These questions aim to gather feedback and insights on competitor awareness, usage, perceived quality and pricing, differentiation factors, and preferences. Customise the questions to match the specific competitors and market dynamics relevant to your research.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Competitor%20Analysis.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "Are you aware of our main competitors in the market?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, very aware 😃", "Yes, somewhat aware 😐", "No, not aware 😕"],
      },
      {
        question: "Have you used products or services from our competitors?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, often 👍", "Yes, sometimes 😊", "No, never 👎"],
      },
      {
        question: "How do you like to get updates about community events? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Worse 😞", "Slightly worse 😕", "About the same 😐", "Slightly better 🙂", "Better! 😄"],
      },
      {
        question: "How do our prices compare to our main competitors?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Cheaper! 😄", "About the same 😐", "More expensive 😕"],
      },
      {
        question: "What makes our products/services stand out compared to our main competitors?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "What are our main competitors' weaknesses or areas for improvement?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "Which brand would you prefer to purchase from if you had to choose?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Our brand 👍", "A competitor's brand 😕", "It depends on the product/service 🤔"],
      },
    ],
  },
  {
    id: 6,
    surveyName: "Customer Feedback",
    surveyCategory: "Customer Feedback",
    surveySubTitle: "Get quality customer feedback you can quickly act on.",
    surveyDescription:
      "These questions aim to gather feedback on overall satisfaction, customer service, specific experiences, likelihood to recommend and continue as a customer, problem resolution, and suggestions for improvement. Customise the questions to align with your specific business and customer feedback goals.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Customer%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How happy are you with your recent purchase?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Very happy 😄", "Happy 🙂", "Okay 😐", "Not so happy 😕", "Not happy at all 😞"],
      },
      {
        question: "How was your recent customer service experience with us?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Excellent! 🌟", "Good 😊", "Okay 😐", "Not so good 😕", "Not good at all 😞"],
      },

      {
        question: "Do you have any ideas to make our products/services better?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "Would you recommend us to your friends?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes! 👍", "Maybe 🤔", "Not sure 😐", "No, not really 👎"],
      },
      {
        question: "If you faced any issues, were they solved well?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, they were fixed! 👍", "Kind of 🤔", "Not really 😕", "They were not fixed at all 😞"],
      },

      {
        question: "Will you continue using our products/services? ",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely! 👍", "Maybe 🤔", "Not sure 😐", "No, not anymore 👎"],
      },

      {
        question: "Do you have any other suggestions or ideas to improve your experience with us?",
        description: "Feel free to share!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },

  {
    id: 7,
    surveyName: "Event Feedback",
    surveyCategory: "Event Feedback",
    surveySubTitle: "Send out a post-event survey to get your guest feedback.",
    surveyDescription:
      "These questions aim to gather feedback on various aspects of the event, including overall experience, organisation, content, venue, staff, timing, and the likelihood of attending future events. Customise the questions to fit your specific event and feedback objectives.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Event%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgTwo",
    questions: [
      {
        question: "How much did you enjoy the event?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Loved it! 😄", "Liked it 🙂", "It was okay 😐", "Didn't like it 😕", "Hated it 😞"],
      },
      {
        question: "Was the event well-organized?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, very well! 👍", "Yes, somewhat 🤔", "Not really 😕", "No, not at all 👎"],
      },

      {
        question: "What was your favourite thing about the event?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "How did you feel about the event venue and facilities?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Awesome! 🌟", "Good 😊", "Okay 😐", "Not great 😕", "Terrible 😞"],
      },
      {
        question: "How friendly and helpful were the event staff and volunteers?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: [
          "Super friendly and helpful! 😄",
          "Friendly and helpful 😊",
          "Neutral 😐",
          "Not very friendly or helpful 😕",
          "Very unfriendly and unhelpful 😞",
        ],
      },

      {
        question: "Was the event too short, too long, or just right in time?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Just right 👍", "Too short 🏃‍♂️", "Too long 🐢"],
      },

      {
        question: "Will you come to a similar event in the future?",
        description: "Choose one",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely! 👍", "Maybe 🤔", "Not sure 😐", "No, not interested 👎"],
      },
    ],
  },

  {
    id: 8,
    surveyName: "Pricing Research",
    surveyCategory: "Pricing Research",
    surveySubTitle: "Gather data on customer behaviour, market trends, and competitor pricing.",
    surveyDescription:
      "Keep the language simple and easy to understand to ensure clear and accurate participant feedback. Customise the questions as needed to fit your research objectives and the preferences of your target audience.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Pricing%20Research.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How much does the price matter when you decide to buy something?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["A lot! 😄", "Somewhat 🤔", "Not sure 😐", "Not much 😕", "Not at all 😞"],
      },
      {
        question: "Are our products/services a good deal for your price?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Definitely! 👍", "Yes, they're okay 🙂", "I'm not sure 😐", "Not really 😕", "No, not at all 😞"],
      },

      {
        question: "Compared to other similar things you've seen, are our prices:",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Lower 👍", "About the same 🙂", "Higher 👎"],
      },

      {
        question: "Does the price match what we offer? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Perfect match! 👍",
          "Kind of matches 🙂",
          "Not sure 😐",
          "It doesn't really match 😕",
          "It's way off 👎",
        ],
      },

      {
        question: "How easy is it for you to afford our products/services? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Very easy 😄", "Okay 😊", "Not sure 😐", "It's a bit hard 😕", "It's very tough 😞"],
      },

      {
        question: "How does our pricing affect what you think about our brand? ",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "What price changes would make you more likely to buy from us?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },
    ],
  },

  {
    id: 9,
    surveyName: "Affiliate Program Feedback",
    surveyCategory: "Affiliate Program Feedback",
    surveySubTitle: "Gather data on satisfaction, ease of use, & effectiveness in the affiliate program.",
    surveyDescription:
      "These questions aim to gather feedback on overall satisfaction, ease of use, the effectiveness of marketing materials, support experience, and areas for improvement in the affiliate program. Customise the questions to align with your affiliate program and feedback goals.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Affiliate%20Program%20Feedback.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How much do you like our affiliate program? ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Love it! 😄", "Like it 🙂", "It's okay 😐", "Not really 😕", "Don't like it 😞"],
      },
      {
        question: "Was it easy to sign up and get started as an affiliate?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Super easy 👍", "Easy 🙂", "Not sure 😐", "Kind of hard 😕", "Really hard 😞"],
      },

      {
        question: "How useful are our affiliate marketing materials and resources?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Very useful 👍",
          "Quite useful 🙂",
          "Somewhat useful 😐",
          "Not very useful 😕",
          "Not useful at all 😞",
        ],
      },

      {
        question: "Did you need any help with our affiliate program?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, often 👎", "Yes, sometimes 😕", "No, never 👍"],
      },

      {
        question: "How helpful is our support team?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Super helpful 👍", "Helpful 🙂", "Neutral 😐", "Not very helpful 😕", "Not helpful at all 😞"],
      },

      {
        question: "What would you like us to improve in our affiliate program?",
        description: "Share your thoughts!",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "Would you recommend our affiliate program to others?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: ["Yes, definitely 👍", "Maybe 🤔", "No, not really 👎"],
      },
    ],
  },

  {
    id: 10,
    surveyName: "Consumer Profiling",
    surveyCategory: "Consumer Profiling",
    surveySubTitle: "Get quality customer feedback you can quickly act on.",
    surveyDescription: "Which of these products have you purchased in the past 3 months?",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Consumer%20Profiling.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How often do you use  >INSERT YOUR PRODUCT CATEGORY< ",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "A few times a day",
          "Once a day",
          "A few times a week",
          "Once a week",
          "A few times a month",
          "Once a month",
          "Less often",
        ],
      },
      {
        question:
          "Please rank the following on how important or unimportant they are when deciding which  >INSERT YOUR PRODUCT CATEGORY< to buy. 1 = most important, 9 = least important",
        description: "Choose one option",
        questionType: "likert",
        optional: false,
        options: {
          max: {
            label: "Most important",
            value: 9,
          },
          min: {
            label: "Least important",
            value: 1,
          },
        },
      },

      {
        question: "How do you prefer to shop for >INSERT YOUR PRODUCT CATEGORY<?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "I prefer to buy things in person",
          "I prefer to buy things online",
          "I have no preference for online or offline",
          "I prefer to buy things over the phone ",
        ],
      },

      {
        question: "Did you need any help with our affiliate program?",
        description: "Choose one option",
        questionType: "multi_choice",
        optional: false,
        options: [
          "Friends/family",
          "Money",
          "Physical health",
          "Spiritual life",
          "Mental health",
          "Having new experiences",
          "Status/promotion",
          "Having fun",
          "Feeling safe",
        ],
      },
    ],
  },

  /*
{
  question: "On average, how many days per week do you drink beer?",
  description: "Choose one option",
  questionType: "single_choice",
  optional: false,
  options: [
    "None",
    "1 day",
    "2 days",
    "3 days",
    "4 days or more"
  ],
},
{
  question: "What time of day do you most often consume beer?",
  description: "Choose one option",
  questionType: "single_choice",
  optional: false,
  options: [
    "Morning",
    "Afternoon",
    "Evening",
    "Night",
    "No specific time"
  ],
},
{
  question: "What factors influence your choice of beer selection? (Select all that apply)",
  description: "Choose all that apply",
  questionType: "multi_choice",
  optional: false,
  options: [
    "Flavor profile",
    "Brand reputation",
    "Price",
    "Alcohol content",
    "Availability",
    "Recommendations from others"
  ],
},
{
  question: "How often do you try new or different types of beer?",
  description: "Choose one option",
  questionType: "single_choice",
  optional: false,
  options: [
    "Very often",
    "Often",
    "Occasionally",
    "Rarely",
    "Never"
  ],
}

*/

  {
    id: 11,
    surveyName: "Jobs to be done Survey",
    surveyCategory: "Jobs to be done",
    surveySubTitle: "Gather data on user activities within a specified timeframe.",
    surveyDescription: "Looking at the list below, please select your activities in the past 12 months.",
    coverImage:
      "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Jobs%20to%20be%20done%20Survey.png",
    reward: 100,
    campaignType: "",
    bg: "bg-bgOne",
    questions: [
      {
        question: "How often have you carried out [INSERT ACTIVITY] in the last 12 months?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Everyday",
          "A few times a week",
          "Every week",
          "A few times in a month",
          "Once a month",
          "A few times a year",
          "Once every six months",
          "Once a year",
        ],
      },
      {
        question: "How much time would you typically spend on this [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Less than 30 mins",
          "30 - 59 mins",
          "1 hr - 2 hr 59 mins",
          "3 hrs - 4 hr 59 mins",
          "5 hrs - 11hrs 59 mins",
          "12 hrs +",
        ],
      },
      {
        question: "How important or unimportant is carrying out this [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Very important",
          "Somewhat important",
          "Neither important nor unimportant",
          "Somewhat unimportant",
          "Very unimportant",
        ],
      },

      {
        question: "How satisfied or dissatisfied do you feel when carrying out this [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "single_choice",
        optional: false,
        options: [
          "Very satisfied",
          "Somewhat satisfied",
          "Neither satisfied nor dissatisfied",
          "Somewhat dissatisfied",
          "Very dissatisfied",
        ],
      },

      {
        question: "What is the best thing about carrying out [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "How does carrying out [INSERT ACTIVITY] make you feel?",
        description: "Choose one option",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question: "What problems or challenges do you encounter while carrying out [INSERT ACTIVITY]?",
        description: "Choose one option",
        questionType: "text_response",
        optional: false,
        options: [],
      },

      {
        question:
          "Please rank these factors in order of importance when determining whether to buy [PRODUCT / SERVICE].",
        description: "Choose one option",
        questionType: "likert",
        optional: false,
        options: {
          max: {
            label: "Most important",
            value: 9,
          },
          min: {
            label: "Least important",
            value: 1,
          },
        },
      },
    ],
  },

  // {
  //   id: 12,
  //   surveyName: "Beer Survey",
  //   surveyCategory: "Consumer Profile",
  //   surveyDescription: "This question is tailored folks who take beer",
  //   surveySubTitle: "Gather data on customers who take beer.",
  //   coverImage:
  //     "https://mooyi-prod-storage.fra1.digitaloceanspaces.com/Mooyi_FB_Assets/online_survey_template_png/Consumer%20Profiling.png",
  //   reward: 100,
  //   campaignType: "",
  //   bg: "bg-bgOne",
  //   questions: [
  //     {
  //       question: "On average, how many days per week do you drink beer?",
  //       description: "Choose one option",
  //       questionType: "single_choice",
  //       optional: false,
  //       options: ["None", "1 day", "2 days", "3 days", "4 days or more"],
  //     },
  //     {
  //       question: "What time of day do you most often consume beer?",
  //       description: "Choose one option",
  //       questionType: "single_choice",
  //       optional: false,
  //       options: ["Morning", "Afternoon", "Evening", "Night", "No specific time"],
  //     },
  //     {
  //       question: "What factors influence your choice of beer selection? (Select all that apply)",
  //       description: "Choose all that apply",
  //       questionType: "multi_choice",
  //       optional: false,
  //       options: [
  //         "Flavor profile",
  //         "Brand reputation",
  //         "Price",
  //         "Alcohol content",
  //         "Availability",
  //         "Recommendations from others",
  //       ],
  //     },
  //     {
  //       question: "How often do you try new or different types of beer?",
  //       description: "Choose one option",
  //       questionType: "single_choice",
  //       optional: false,
  //       options: ["Very often", "Often", "Occasionally", "Rarely", "Never"],
  //     },
  //   ],
  // },
];
