import React, { useState } from "react";
import { IndividualEntry, Upload } from "assets/images/contacts";
import { Link, useNavigate } from "react-router-dom";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { IoChevronBackOutline } from "react-icons/io5";
import Typography from "utils/typography";
// import { Facebook } from "assets/images/contacts";
// import { Upload } from "assets/images/contacts";
// import { IndividualEntry } from "assets/images/contacts";

const cards = [
  {
    id: 1,
    title: "Individual entry",
    text: "Manually add your contacts one at a time.",
    img: IndividualEntry,
    fill1: "#95ADFF",
    fill2: "#6B7FFF",
    link: "/contacts/create",
  },
  {
    id: 2,
    title: "Upload a list",
    text: "Import a list of people in CSV or XLSV format.",
    img: Upload,
    fill1: "#DAE5FF",
    fill2: "#BDCEFF",
    link: "/contacts/upload",
  },
  // {
  //   id: 3,
  //   title: "Facebook leads import",
  //   text: "Easily import your leads from Facebook with one click.",
  //   img: Facebook,
  //   fill1: "#3E8EF5",
  //   fill2: "#1877F2",
  //   link: "/contacts/facebook",
  // },
];

const ContactSetup = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [route, setRoute] = useState("");
  const [hover, setHover] = useState(null);

  const updateRoute = (x, y) => {
    setValue(x);
    setRoute(y);
  };

  const handleIndex = (id) => {
    setValue(id === value ? null : id);
  };
  const handleHover = (id) => {
    setHover(id === value ? null : id);
  };

  const handleNext = () => {
    navigate(route);
  };
  return (
    <div className="w-[100%] h-screen border-[1px] rounded-md bg-white flex flex-col items-center p-4  pt-[3em]">
      <div
        className="w-full flex justify-start items-center text-[14px] text-primary-800 space-x-2 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <IoChevronBackOutline />
        <p>Back to contacts</p>
      </div>
      <p className="text-xl font-semibold mt-5">How do you want to add your contacts?</p>

      <div className="flex gap-[3em] w-[90%] mx-auto my-[2em] justify-center">
        {cards.map((el, i) => {
          const Images = el.img;
          return (
            <div
              key={i}
              className={`rounded-lg border overflow-hidden cursor-pointer ${
                (value === el.id) | (hover === el.id) && "border-primary-800"
              } w-[268px] relative`}
              onClick={() => updateRoute(el.id, el.link)}
              onMouseEnter={() => handleHover(el.id)}
              onMouseLeave={() => handleHover(null)}
            >
              <div className="absolute top-5 right-4">
                <div
                  className={`rounded-full p-1 flex items-center justify-center border w-[20px] h-[20px] ${
                    value === el.id ? "border-blue-800" : ""
                  } `}
                >
                  {value === el.id ? <div className={`rounded-full border p-1 bg-blue-800`}></div> : <div></div>}
                </div>
              </div>

              <div>
                <Images fill={(value === el.id) | (hover === el.id) ? el.fill2 : el.fill1} />
              </div>
              <div className="p-5 text-center">
                <p className={`font-[500] text-[16px] ${value === el.id && "text-primary-800"}`}>{el.title}</p>
                <Typography.SubText className="text-[16px] text-gray-500">{el.text}</Typography.SubText>
              </div>
            </div>
          );
        })}
      </div>

      <p className="text-sm text-neutral-600 font-semibold">Need help getting started?</p>

      <div className="text-primary-800 text-xs flex space-x-3 items-center mt-3">
        <a href="https://usemooyi.com/faq" target="_blank" rel="noopener noreferrer">
          Visit our FAQ
        </a>

        <span className="border-neutral-300 border-l-[1px] h-[20px]"></span>

        <a href="https://www.youtube.com/channel/UC-KbRjjhExwbHkOurEtdNFQ" target="_blank" rel="noopener noreferrer">
          Watch tutorial video
        </a>
      </div>

      <div className="border-t-[1px] bg-white h-[50px] w-[calc(100vw-236px)] absolute bottom-0 right-0 flex items-center justify-between px-6 z-20">
        <Link to="/contacts">
          <GreyButton text="Cancel" css="h-[35px]" />
        </Link>

        <BlueButton text="Next" css="h-[35px]" onClick={handleNext} disabled={value === null} />
      </div>
    </div>
  );
};

export default ContactSetup;
