import { Tag } from "assets/icons/contacts/Tag";
import { ContactIcon } from "assets/icons/sidebar";
import React, { useEffect, useMemo, useState } from "react";
import AllContacts from "./AllContacts";
import Tags from "./Tags";
// import CustomFields from "./CustomFields";
import { Segment } from "assets/icons/contacts/Segment";
// import { FileAddin } from "assets/icons/contacts/FileAddin";
import { useNavigate, useSearchParams } from "react-router-dom";
import Toast from "config/toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HiChevronDown, HiChevronRight } from "react-icons/hi2";
import useOutsideClick from "hooks/useOutsideClick";
import { getAllContacts } from "services/contactService";
import CreateCustomFieldModal from "./CustomFields/CreateCustomFieldModal";
import { CreateCustomSuccessModal } from "./CustomFields/CreateCustomSuccessModal";
import { useContactStore } from "store/surveyStore/contactStates";
import { getAllTags } from "services/contactService";
import { getAllSegments } from "services/contactService";
// import { getAllCustomFields } from "services/contactService";
import { createCustom } from "services/contactService";
import Segments from "./Segments";
import AllLeads from "./Leads";
import Typography from "utils/typography";
import { getAllLead } from "services/contactService";

const ContactTabs = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [dropDown, setDropdown] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setopenModal] = useState(null);
  const [searchVal] = useState("");
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const { contactTabs, setContactsTabs } = useContactStore();
  const [sortBy] = useState("name");

  const tabs = [
    { id: 1, name: "All contacts", value: 0, icon: ContactIcon, count: contactTabs?.allContacts },
    { id: 2, name: "All leads", value: 1, icon: ContactIcon, count: contactTabs?.allLeads },
    { id: 3, name: "Tags", value: 2, icon: Tag, count: contactTabs?.tags },
    { id: 4, name: "Segments", value: 3, icon: Segment, count: contactTabs?.segment },
    // {
    //   id: 5,
    //   name: "Custom-fields",
    //   value: 4,
    //   icon: FileAddin,
    //   count: (contactTabs?.custom === null) | (contactTabs?.custom === undefined) ? 0 : contactTabs?.custom,
    // },
  ];

  // ============================ Query Functions ===========================================

  const {
    data: contact,
    isLoading: loadingContacts,
    isFetching: isFetchingContacts,
  } = useQuery(
    ["allContactTab", contactCurrentPage, searchVal],
    () => getAllContacts(contactCurrentPage, "name", searchVal.toLowerCase()),

    {
      onSuccess: (data) => {
        setContactsTabs({ allContacts: data?.totalContacts });
      },
      onError: (error) => {
        Toast.error(error);
      },
    },
  );
  const {
    data: tag,
    isLoading: loadingTags,
    isFetching: isFetchingTags,
  } = useQuery(["allTags", sortBy], () => getAllTags(sortBy), {
    onSuccess: (data) => {
      setContactsTabs({ tags: data?.length });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const {
    data: newAllLeads,
    isLoading: loadingLeads,
    isFetching: isFetchingLeads,
  } = useQuery(["newAllLeads"], getAllLead, {
    onSuccess: (data) => {
      setContactsTabs({ allLeads: data?.length });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  // console.log(newAllLeads);

  // const {
  //   data: allLeads,
  //   isLoading: loadingLeads,
  //   isFetching: isFetchingLeads,
  // } = useQuery(["allLeads", sortBy], () => getAllLeads(sortBy), {
  //   onSuccess: (data) => {
  //     setContactsTabs({ allLeads: data?.totalContacts });
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  const {
    data: segment,
    isLoading: loadingSegment,
    isFetching: isFetchingSegment,
  } = useQuery(["allSegment", sortBy], () => getAllSegments(sortBy), {
    onSuccess: (data) => {
      setContactsTabs({ segment: data?.length });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  // const {
  //   data: custom,
  //   isLoading: loadingCustom,
  //   isFetching: isFetchingCustom,
  // } = useQuery(["allCustom"], getAllCustomFields, {
  //   onSuccess: (data) => {
  //     setContactsTabs({ custom: data?.fields?.length });
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  // ========================================= Mutate Delete ====================================

  // const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation(deleteContacts, {
  //   onSuccess: () => {
  //     Toast.success("Contact(s) deleted successfully!!");
  //     queryClient.invalidateQueries(["allContacts"]);
  //     // handleDelete(2);
  //   },
  //   onError: (error) => {
  //     Toast.error(error);
  //   },
  // });

  // const deleteContactsFunc = (data) => {
  //   deleteMutate({ contactId: data });
  // };

  const { mutate: create } = useMutation(createCustom, {
    onSuccess: () => {
      Toast.success("custom field created successfully");
      navigate("/contacts/all-contacts?tab=3");
      handleModal(6);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const switchTab = (value) => {
    setTabValue(value);
    setSearchParams({ tab: value });
  };

  const toggleDropdown = (id) => {
    setDropdown(dropDown === id ? null : id);
  };

  const refer = useOutsideClick(toggleDropdown);

  const handleModal = (id) => {
    setopenModal(openModal === id ? null : id);
  };

  useEffect(() => {
    const navValue = parseInt(searchParams.get("tab") || "0");
    setTabValue(navValue);
  }, [searchParams]);

  const handleSubmit = (data) => {
    const payload = {
      fieldName: data.name,
      fieldType: data.dataType,
    };
    create(payload);
    handleModal(6);
  };

  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = contact?.totalContacts;

    let startValue = (contactCurrentPage - 1) * 10;
    startValue = startValue + 1;

    let endValue = Math.min(contactCurrentPage * 10, totalValue);
    return [startValue, endValue];
  }, [contactCurrentPage, contact?.totalContacts]);

  const handlePrev = () => {
    setContactCurrentPage(contactCurrentPage - 1);
    // setSelectArray([]);
  };

  const handleNext = () => {
    setContactCurrentPage(contactCurrentPage + 1);
    // setSelectArray([]);
    // fetchNextPage();
  };

  const displayButton = () => {
    switch (tabValue) {
      case 0:
        return (
          <div>
            <div
              className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
              onClick={() => toggleDropdown(1)}
            >
              <p className="text-sm font-medium text-white">Create new contact</p>
              <HiChevronRight />
            </div>
            {dropDown === 1 ? (
              <div
                className="bg-white shadow-activeShadow rounded-[8px] w-[150px] absolute left-[13rem] cursor-pointer z-[100] top-[2.5rem]"
                ref={refer}
              >
                <p
                  className="text-[#737373] hover:bg-gray-200 text-xs space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/create")}
                >
                  Individual entry
                </p>

                <p
                  className="text-[#737373] hover:bg-gray-200 text-xs space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/upload")}
                >
                  Upload a list
                </p>
              </div>
            ) : null}
          </div>
        );
      case 1:
        return (
          <div>
            <div
              className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
              onClick={() => toggleDropdown(1)}
            >
              <p className="text-sm font-medium text-white">Create new contact</p>
              <HiChevronDown />
            </div>
            {dropDown === 1 ? (
              <div
                className="bg-white shadow-activeShadow rounded-[8px] w-[250px] absolute left-0 cursor-pointer z-[100]"
                ref={refer}
              >
                <p
                  className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/create")}
                >
                  Individual entry
                </p>

                <p
                  className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/upload")}
                >
                  Upload a list
                </p>
              </div>
            ) : null}
          </div>
        );
      case 2:
        return (
          <div
            className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
            onClick={() => navigate("/contacts/tag/create")}
          >
            <p className="text-sm font-medium text-white">Create new tag</p>
          </div>
        );
      case 3:
        return (
          <div
            className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
            onClick={() => navigate("/contacts/segment/create")}
          >
            <p className="text-sm font-medium text-white">Create new segment</p>
          </div>
        );
      case 4:
        return (
          <div
            className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
            onClick={() => handleModal(4)}
          >
            <p className="text-sm font-medium text-white">Create new field</p>
          </div>
        );
      default:
    }
  };

  const displayTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <AllContacts
            data={contact}
            isLoading={loadingContacts}
            isFetchingContacts={isFetchingContacts}
            pageCountStart={pageCountStart}
            pageCountEnd={pageCountEnd}
            currentPage={contactCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <AllLeads
            data={newAllLeads}
            isLoading={loadingLeads}
            isFetchingContacts={isFetchingLeads}
            pageCountStart={pageCountStart}
            pageCountEnd={pageCountEnd}
            currentPage={contactCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <div>
            <Tags tag={tag} isLoading={loadingTags} isFetchingTags={isFetchingTags} />
          </div>
        );
      case 3:
        return <Segments segment={segment} isLoading={loadingSegment} isFetching={isFetchingSegment} />;
      // case 4:
      //   return <CustomFields custom={custom} isLoading={loadingCustom} isFetching={isFetchingCustom} />;
      default:
    }
  };

  return (
    <div className="relative p-5 border rounded-md bg-white overflow-x-auto h-[calc(100vh-80px)] overflow-y-auto">
      {displayButton()}
      <div className="grid grid-cols-4 gap-x-[1em]">
        {tabs.map((el, i) => {
          const IconComponent = el.icon;
          return (
            <div
              key={i}
              className={`${
                tabValue === el.value ? "bg-[#F2F5FE] border-primary-600" : ""
              } border cursor-pointer border-b-0 py-3 px-2 rounded-t-md`}
              onClick={() => switchTab(el.value)}
            >
              <div className="flex items-center space-x-3">
                <IconComponent
                  fill1={tabValue === el.value ? "#95ADFF" : "#D4D4D4"}
                  fill2={tabValue === el.value ? "#1D19AF" : "#404040"}
                />
                <div className="flex items-center space-x-5 text-[14px]">
                  <Typography.SubText className={`${tabValue === el.value ? "font-[600] text-primary-800" : ""}`}>
                    {el.name}
                  </Typography.SubText>
                  <div
                    className={`w-[24px] h-[24px] rounded flex items-center justify-center bg-gray-200 text-gray-500 ${
                      tabValue === el.value ? "font-[700] text-primary-800 bg-primary-200" : ""
                    }`}
                  >
                    <Typography.SubText>{el.count}</Typography.SubText>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="border-[1px]"></div>
      <div>{displayTab()}</div>
      {openModal === 4 ? <CreateCustomFieldModal handleSubmitClick={handleSubmit} handleModal={handleModal} /> : null}
      {openModal === 6 ? <CreateCustomSuccessModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default ContactTabs;
