import { useMutation, useQuery } from "@tanstack/react-query";
import SecondaryInput from "../../../components/Inputs/SecondaryInput";
import { queryClient } from "../../../config/gateway";
import Toast from "../../../config/toast";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { createContact, getAllTags } from "../../../services/contactService";
import PreLoader from "../../../components/Loader/PreLoader";
import { useFormik } from "formik";
// import Flag from "assets/images/flag.svg";
import { TiInfo } from "react-icons/ti";
import BlueButton from "../../../components/Button/BlueButton";
import useOutsideClick from "../../../hooks/useOutsideClick";
import dayjs from "dayjs";
import Checkbox from "../../../components/Inputs/CheckBox";
import { IoIosArrowDown, IoMdCheckmark, IoMdPie } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
// import Tooltip from "components/Tooltip";
import CreateTag from "../CreateTag";
import PhoneNumberInput from "../../../components/Inputs/PhoneNumberInput";
import { trackEvent } from "../../../config/mixpanel";
import useLoggedInUser from "../../../hooks/useLoggedInUser";
import { Link } from "react-router-dom";
import Dropdown from "components/DropDown";
import { getState } from "services/locationService";
import { getCities } from "services/locationService";
import DropDownWithSearch from "components/DropDownWithSearch";

// import { ContactListIcon } from "assets/icons/DashboardIcons";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  birthday: "",
  address: "",
  gender: "",
  state: "",
  lga: "",
};

// const presentDate = dayjs(); // Get the present date

// const eighteenYearsAgo = presentDate.subtract(18, "year");

const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Prefer not to say", value: "prefer not to say" },
];

const inputArr = [
  {
    id: 1,
    type: "text",
    text: "First Name",
    name: "firstname",
    placeholder: "Enter your first name",
    // value: values.firstname,
  },
  {
    id: 2,
    type: "text",
    text: "Last Name",
    name: "lastname",
    placeholder: "Enter your last name",
    // value: values.lastname,
  },
  {
    id: 3,
  },
  {
    id: 4,
    type: "email",
    text: "Email Address",
    name: "email",
    placeholder: "Enter your email address",
    // value: values.email,
  },
  {
    id: 5,
  },
  {
    id: 6,
    type: "date",
    name: "birthday",
    text: "Birthday",
    placeholder: "Enter Birthday",
    // min: eighteenYearsAgo.format("YYYY-MM-DD"),
    // value: values.birthday
  },
  {
    id: 7,
    // min: eighteenYearsAgo.format("YYYY-MM-DD"),
    // value: values.birthday
  },
  {
    id: 8,
  },
  {
    id: 9,
    type: "text",
    name: "address",
    text: "(Optional) Address",
    placeholder: "Enter your address",
    // value: values.address,
  },
];

const validationSchema = Yup.object().shape(
  {
    email: Yup.string().email("Enter a valid email address").required("Please complete this field."),
    firstname: Yup.string().required("Please complete this field."),
    lastname: Yup.string().required("Please complete this field."),
    birthday: Yup.date()
      .nullable()
      .notRequired()
      .max(new Date(), "Selected date must be today or earlier")
      .test("age", "Your contact must be at least 18 years old", (value) => {
        const currentDate = new Date();
        const minDate = new Date();
        minDate.setFullYear(currentDate.getFullYear() - 18);
        if (!value) return true;
        return new Date(value) <= minDate;
      }),
    phone: Yup.string().required("Please complete this field."),
  },
  [["birthday", "birthday"]],
);
const IndividualEntry = ({ outSideContact, handleModal: toggleModal }) => {
  const [globalArr] = useState(inputArr);
  const [isChecked, setIsChecked] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const [query, setQuery] = useState("");
  const { data: tagData } = useQuery(["allTags"], getAllTags);
  const navigate = useNavigate();
  const [openDrop, setOpenDrop] = useState(false);
  const [searchedArray, setSearchedArray] = useState(tagData);
  const [open, setOpen] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+234");
  const { user } = useLoggedInUser();

  const { data: stateData } = useQuery(["getState"], () => getState("Nigeria"), {});
  const { data: cityData } = useQuery(["getCity"], () => getCities("Nigeria"));
  const state = useMemo(() => {
    return stateData !== undefined
      ? stateData?.data?.states?.map((el) => ({
          label: el.name.replace(" State", ""),
          value: el.name,
        }))
      : [];
  }, [stateData]);

  const { mutate: addContact, isLoading } = useMutation(createContact, {
    onSuccess: () => {
      Toast.success("Contact added successfully!!");
      navigate("/contact");
      queryClient.invalidateQueries(["allContacts"], ["allTags"], ["allSegment"], ["onBoarding"]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: addContact1, isLoading: isLoading1 } = useMutation(createContact, {
    onSuccess: () => {
      Toast.success("Contact added successfully!!");
      toggleModal();
      queryClient.invalidateQueries(["allContacts"], ["allTags"], ["allSegment"], ["onBoarding"]);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = (data) => {
    const { state, ...restData } = data;
    trackEvent("add_contact", {
      email: user?.user?.data?.email,
      addContactMethod: "individual entry",
      date: dayjs().format(),
    });
    const userData = {
      ...restData,
      phone: `${selectedCode}${data.phone}`,
      tag: tagArr,
      address: `${data?.address}, ${data?.state}`,
    };
    if (outSideContact) {
      addContact1(userData);
    } else {
      addContact(userData);
    }

    // console.log(userData)
  };

  const handleDropdown = () => {
    setOpenDrop(!openDrop);
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const refer = useOutsideClick(handleDropdown);

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit: normalSub,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const citiesArray =
    values.state !== "" && cityData !== undefined && Array.isArray(cityData[values.state.replace(" State", "")])
      ? cityData[values.state.replace(" State", "")]?.map((el) => ({
          label: el,
          value: el,
        }))
      : [];

  const onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setSearchedArray(tagData);
  }, [setSearchedArray, tagData]);

  const handleCheck = (e, el) => {
    if (e.target.checked) {
      if (!tagArr.includes(el)) {
        setTagArr([...tagArr, el]);
      }
    } else {
      const filterArray = tagArr?.filter((elm) => elm !== el);
      setTagArr(filterArray);
    }
  };

  if (isLoading) {
    return <PreLoader text="Adding Contact" />;
  }

  const handleInputChange = (e) => {
    // console.log(e.target.value)
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    setFieldValue(name, numericValue);
  };

  // console.log(values)

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (query !== "") {
      const filteredData = tagData?.filter(
        (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
      if (filteredData.length !== 0) {
        setSearchedArray(filteredData);
      }
    } else {
      setSearchedArray(tagData);
    }
  };

  const removedFromArray = (el) => {
    const filterArray = tagArr?.filter((elm) => elm !== el);
    setTagArr(filterArray);
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in tagArr) {
      if (tagArr[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  // globalArr.splice(1, 0, newArray)

  return (
    <div className="w-full">
      <div className="border-[1px] border-primary-100 p-4 rounded-[8px]  bg-primary-50 flex space-x-2 my-4 mt-2 w-full">
        <TiInfo className="text-[#FBBF24] text-[24px]" />
        <div>
          <p className="text-[14px font-[600]">Please note:</p>

          <p className="text-s font-[400]">
            We do not send confirmation emails to your contacts. Ensure you have obtained permission before adding
            contacts, and adhere to our guidelines to prevent misuse.
          </p>
        </div>
      </div>
      <form onSubmit={normalSub} onKeyDown={onKeyDown}>
        <div className="w-full grid grid-cols-2 gap-[1em] items-start">
          {globalArr?.map((el, i) => {
            if (el.id === 5) {
              return (
                <PhoneNumberInput
                  text="Phone Number"
                  onChange={handleInputChange}
                  value={values.phone}
                  name="phone"
                  setSelectedCode={setSelectedCode}
                  selectedCode={selectedCode}
                />
                // <div className="text-primary flex flex-col w-[100%]">
                //   <label htmlFor="phone" className="text-[#292D32] text-m font-[500]">
                //     Phone Number
                //   </label>

                //   <div className="border-[#D0D5DD] border-[1px] flex items-center rounded-[8px] my-2 ">
                //     <div className="flex items-center px-3">
                //       <img src={Flag} alt="Flag" />
                //       <p className="ml-2">+234</p>
                //     </div>
                //     <input
                //       type="tel"
                //       placeholder="8025556792"
                //       onChange={handleInputChange}
                //       value={values.phone}
                //       name="phone"
                //       className=" border-l-[1px] rounded-[1px]  border-[#D0D5DD] h-[48px] pl-2 outline-none w-[70%]  bg-[#FAFAFA] text-m"
                //       maxlength="10"
                //     />
                //   </div>
                // </div>
              );
            } else if (el.id === 3) {
              return (
                <div className="mt-1">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">Gender</p>
                  </div>
                  <div className="mt-2">
                    <Dropdown
                      tag="Select your gender"
                      options={gender}
                      onSelect={(el) => setFieldValue("gender", el.value)}
                    />
                  </div>
                </div>
              );
            } else if (el.id === 7) {
              return (
                <div className="mt-1">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">State</p>
                  </div>
                  <div className="mt-2">
                    <DropDownWithSearch
                      tag="Select state"
                      options={state}
                      onSelect={(el) => setFieldValue("state", el.value)}
                    />
                  </div>
                </div>
              );
            } else if (el.id === 8) {
              return (
                <div className="mt-1">
                  <div className="flex space-x-2 items-center ">
                    <p className="text-m font-[500]">Local Government Area</p>
                  </div>
                  <div className="mt-2">
                    <DropDownWithSearch
                      tag="Select Local Government Area"
                      options={citiesArray}
                      onSelect={(el) => setFieldValue("lga", el.value)}
                    />
                  </div>
                </div>
              );
            } else if (el.id === 9) {
              return (
                <div className="flex w-full space-x-2 items-center">
                  <Fragment key={el.id} className="w-full">
                    <SecondaryInput
                      text={el.text}
                      name={el.name}
                      placeholder={el.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={el.type}
                      value={values[`${el.name}`]}
                      error={getError(`${el.name}`)}
                      // min={el?.min}
                    />
                  </Fragment>
                </div>
              );
            } else {
              return (
                <Fragment key={el.id}>
                  <SecondaryInput
                    text={el.text}
                    name={el.name}
                    placeholder={el.placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={el.type}
                    value={values[`${el.name}`]}
                    error={getError(`${el.name}`)}
                    // min={el?.min}
                  />
                </Fragment>
              );
            }
          })}

          <div>
            <div className="flex justify-between">
              <div className="flex space-x-2 items-center">
                <p className="text-s">Select tag(s)</p>
                {/* <Tooltip text="Select the category of contacts you want to send your campaign to." /> */}
              </div>

              <p className="text-s text-primary-800 font-[500] cursor-pointer" onClick={() => toggleModal(2)}>
                Create Tag
              </p>
            </div>
            <div
              className="rounded-[6px] bg-[#FAFAFA] border-[1px] border-[##D0D5DD] h-[45px] pl-2 outline-none my-2 flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%] flex items-center relative"
              onClick={handleDropdown}
            >
              <p className="text-[#A3A3A3] text-s">{tagArr.length} tags selected</p>

              <div className="text-[#101828] h-[100%] absolute top-0 right-6 flex items-center justify-center text-[18px]">
                <IoIosArrowDown />
              </div>
            </div>

            <div className=" flex-wrap gap-[1em]  py-[0.5em] max-w-[100%] flex items-center relative pb-[1em]">
              {tagArr?.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="bg-white border-[1px] border-[#BFCCDA] flex items-center px-4 rounded-[6px] h-[30px]  text-ss font-semibold cursor-pointer"
                  >
                    {el}
                    <FaTimes className="ml-2" onClick={() => removedFromArray(el)} />
                  </div>
                );
              })}
            </div>

            {openDrop && (
              <div className="rounded-[6px] shadow-secondary pb-4 h-[250px] overflow-y-scroll bg-white" ref={refer}>
                <div
                  className="bg-[#F5F5F5]  flex min-h-[45px] items-center flex-wrap gap-[1em] px-[1em] py-[5px] max-w-[100%]"
                  // onClick={handleDropdown}
                >
                  <span className="text-[#737373]">
                    <AiOutlineSearch size={20} />
                  </span>

                  <input
                    type="text"
                    className="w-auto bg-black pl-2 h-[80%] bg-transparent outline-none text-s"
                    placeholder="Search for tags"
                    value={query}
                    name="query"
                    onChange={handleQueryChange}
                  />
                </div>

                {searchedArray?.map((el) => {
                  return (
                    <div
                      className={`px-4 text-ss font-extrabold text-[#404040] border-b-[1px] border-[#D0D5DD] py-[8px] flex items-center justify-between hover:text-white hover:bg-primary-800 group ${
                        checkInArray(el?.name) ? "bg-[#E5E5E5] hover:bg-[#E5E5E5] hover:text-primary-800" : "bg-white"
                      }`}
                      key={el._id}
                      onClick={handleDropdown}
                    >
                      <div className="flex items-center space-x-4 relative w-[100%]">
                        <div>
                          <input
                            type="checkbox"
                            name="recipient"
                            id="recipient"
                            onChange={(e) => handleCheck(e, el?.name)}
                            checked={checkInArray(el?.name)}
                            className="absolute w-[100%] h-[100%] top-0 right-0 opacity-0 z-[9999]"
                          />

                          <IoMdPie
                            className={` text-[24px] ${
                              checkInArray(el?.name) ? "block group-hover:text-black" : "group-hover:text-white"
                            }`}
                          />
                        </div>
                        <div>
                          <p className="text-m font-[500]">{el?.name}</p>
                          <p className="text-ss font-[400]">{el?.contactCount} contacts</p>
                        </div>
                      </div>

                      <IoMdCheckmark
                        className={`group-hover:block text-[24px] ${
                          checkInArray(el?.name) ? "block group-hover:text-black" : "hidden group-hover:text-white"
                        }`}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div>
          <div className="flex items-start space-x-3 w-full">
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

            <div className="-mt-1">
              <p className="text-ss mt-1">
                I confirm that my customers have given permission (or have opted in to receive messages) for their data
                to be accessed for marketing purposes. By proceeding, you agree to our{" "}
                <Link to="https://usemooyi.com/terms-of-use" className="text-blue-800">
                  {" "}
                  Terms of use.{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-between my-4 w-[35%]">
          <BlueButton text="Create" type="submit" disabled={!isChecked || isLoading1} />
          <div></div>
        </div>
      </form>
    </div>
  );
};

export default IndividualEntry;
