import { useMutation, useQuery } from "@tanstack/react-query";
import PrimaryInput from "components/Inputs/PrimaryInput";
import Tooltip from "components/Tooltip";
import { CONTACT_TYPE } from "data/constants/surveyConstants";
import { genderData } from "data/gender";
import { useFormik } from "formik";
import { checkInArray } from "lib/checkInArray";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { marginError } from "services/surveyService";
import { BsPenFill } from "react-icons/bs";
import { HiOutlineChevronDown } from "react-icons/hi";
import Checkbox from "components/Inputs/CheckBox";
import Toast from "config/toast";
import { createSurveyTarget } from "services/newSurveyService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PreLoader from "components/Loader/PreLoader";
import { queryClient } from "config/gateway";
import SaveAndExitModal from "./SaveAndExitModal";
import OptionsModal from "./OptionsModal";
import BottomBar from "./BottomBar";
import Typography from "utils/typography";
import { IoCloseCircle } from "react-icons/io5";
// import EmptyState from "./EmptyState";
import emptySurvey from "assets/images/emptySurveyIcon.png";
import { getState } from "services/locationService";
import { getCities } from "services/locationService";
import { CiSearch } from "react-icons/ci";
import SavedContacts from "./Contacts/SavedContacts";
import { fetchBySurveyId } from "services/newSurveyService";
import { IoMdArrowDropdown, IoMdInformationCircle } from "react-icons/io";
import { coreListOptions } from "./dataBank/audienceData";
import { interestList } from "./dataBank/audienceData";
import { behaviourialList } from "./dataBank/audienceData";
import GreyButton from "components/Button/GreyButton";
import Modal from "components/Modal";
import SecondaryInput from "components/Inputs/SecondaryInput";
import BlueButton from "components/Button/BlueButton";
import { saveTargetedAudience } from "services/newSurveyService";
import { getSavedAudience } from "services/newSurveyService";
import SavedAudienceSettings from "./SavedAudienceSettings";
import ageIcon from "assets/icons/survey/ageIcon.svg";
import briefcase from "assets/icons/survey/briefcase.svg";
import locationIcon from "assets/icons/survey/location.svg";
import profile from "assets/icons/survey/profile-circled.svg";

const tracker = [
  { name: "Same audience", value: false },
  { name: "New audience, same attributes", value: true },
  { name: "New audience, different attributes", value: true },
];

const groups = [
  { name: "Core", value: 1 },
  { name: "Interest", value: 2 },
  { name: "Behaviours", value: 3 },
];

const TagComponent = ({ text, onDelete }) => {
  return (
    <div className="bg-gray-200 rounded-md py-2 px-4 flex items-center justify-center space-x-2">
      <Typography.MicroText>{text}</Typography.MicroText>

      <IoCloseCircle className="text-slate-400 cursor-pointer" onClick={onDelete} />
    </div>
  );
};

const TargetingOptions = ({ setStep, step, headingName, resetAudience, editSurvey }) => {
  const [open, setOpen] = useState(null);
  const [openSavedModal, setOpenSavedModal] = useState(null);
  const [showContact, setShowContact] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [filterData, setFilteredData] = useState();
  const [recipientArray, setRecipientArray] = useState([]);
  const [show, setShow] = useState(false);
  const [tabValue, setTabValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const [optionValue, setOptionValue] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAudience, setSelectedAudience] = useState();
  const [fieldLoc, setFieldLoc] = useState([]);

  const [stateName, setStateName] = useState("");
  const [audience, setAudience] = useState({
    name: "",
    description: "",
  });
  const { data: draftedData } = useQuery(["fetchBySurveyId", id], () => fetchBySurveyId(id), {
    enabled: !!id,
  });
  const { data: getAudience } = useQuery(["getAllAudience"], getSavedAudience);
  const { data: stateData } = useQuery(["getState"], () => getState("Nigeria"), {});
  const { data: cityData } = useQuery(["getCity"], () => getCities("Nigeria"));

  const locationArray = location.pathname.split("/");

  const savedData = draftedData?.data;
  const savedDemographics = savedData?.targetingOption;

  const selectedAudienceDemographics =
    selectedAudience !== undefined ? selectedAudience?.targetingOption?.demographics[0] : undefined;

  const savedtargetedAudience = selectedAudience !== undefined ? selectedAudience?.targetingOption : undefined;

  const savedAudienceData = getAudience?.data;

  const [data, setData] = useState({
    interests: locationArray.includes("field-interview") ? [] : savedDemographics?.interests || [],
    gender: savedDemographics?.gender || [],
    state: savedDemographics?.state || [],
    lga: locationArray[2] === "field-interview" ? [] : [],
    // lga: locationArray[2] === "field-interview" ? savedDemographics?.demographics?.flatMap((el) => el.lga) ?? [] : [],
  });

  const [surveyData, setSurveyData] = useState({
    lte: "",
    gte: "",
    lga: [],
    gender: [],
    country: "Nigeria",
    recipientArray: [],
    state: [],
    interests: [],
  });

  const [coreData, setCoreData] = useState({ cores: {}, interest: {}, behaviour: {} });

  const handleAudienceModal = (id) => {
    setOpenSavedModal(openSavedModal === id ? null : id);
  };

  const { mutate: saveAudience, isLoading: loadAudience } = useMutation(saveTargetedAudience, {
    onSuccess: () => {
      Toast.success("Audience Saved");
      queryClient.invalidateQueries(["getAllAudience"]);
      handleAudienceModal();
    },
    onError: (error) => {
      Toast.error(`${error}`);
    },
  });

  const handleAudienceChange = (e) => {
    const { name, value } = e.target;
    setAudience((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // =========================== State and city Selection functions ========================

  const lastArray = data?.state?.length - 1;
  const renderedStates = new Set();

  const selectAllField = (e) => {
    if (e.target.checked) {
      const updatedLGA = citiesArray.map((el) => el.value);

      setFieldLoc((prevLoc) => [
        ...prevLoc.filter((loc) => loc.state !== stateName),
        { state: stateName, lga: updatedLGA },
      ]);
    } else {
      setFieldLoc((prevLoc) =>
        prevLoc.map((loc) =>
          loc.state === stateName
            ? { ...loc, lga: [] } // Set the LGA array to empty when unchecked
            : loc,
        ),
      );
    }
  };

  const selectAllLga = (e) => {
    // Extract LGAs for the selected state or set an empty array if state doesn't exist in cityData
    const associatedLGAs = cityData[stateName]?.map((lga) => lga) || [];

    if (e.target.checked) {
      setData((prevData) => {
        // Check if the state is already selected
        const isStateSelected = prevData.state.includes(stateName);

        return {
          ...prevData,
          state: isStateSelected ? prevData.state : [...prevData.state, stateName], // Add state if not already selected
          lga: [...new Set([...prevData.lga, ...associatedLGAs])], // Add LGAs and remove duplicates
        };
      });
    } else {
      // Deselect LGAs only belonging to the selected state
      setData((prevData) => ({
        ...prevData,
        lga: prevData.lga.filter((lga) => !associatedLGAs.includes(lga)), // Remove only the LGAs belonging to the state
      }));
    }
  };

  const citiesArray = !locationArray.includes("field-interview")
    ? data?.state?.length !== 0 && cityData !== undefined && Array.isArray(cityData[data?.state[lastArray]])
      ? cityData[data?.state[lastArray]]?.map((el) => ({
          label: el,
          value: el,
        }))
      : []
    : stateName !== ""
    ? cityData[stateName]?.map((el) => ({
        label: el,
        value: el,
      }))
    : [];

  const state = useMemo(() => {
    return stateData !== undefined
      ? stateData?.data?.states?.map((el) => ({
          label: el.name,
          value: el.name,
        }))
      : [];
  }, [stateData]);

  const handleSaveAudience = () => {
    const userDetails = {
      name: audience.name,
      description: audience.description,
      audienceType: CONTACT_TYPE.MOOYI_AUDIENCE,
      reward: +values.reward,
      expectedResponse: values.expectedResponse,
      shuffleSampleSet: values.shuffleSampleSet,
      sendReminderAfter24hrs: false,
      shareToPublic: false,
      saveLeads: true,
      demographics: [
        {
          ...{ detailedTargeting: coreData },
          country: ["Nigeria"],

          ...(values.gte && values.lte
            ? {
                age: {
                  ...(values.gte ? { max: values.gte } : null),
                  ...(values.lte ? { min: values.lte } : null),
                },
              }
            : null),
          ...(values.state ? { state: [values.state] } : null),
        },
        values.shuffleSampleSet
          ? {
              ...(surveyData.gender.length > 0 ? { gender: surveyData.gender } : null),
              country: ["Nigeria"],
              ...(surveyData.gte && surveyData.lte
                ? {
                    age: {
                      ...(surveyData.gte ? { max: surveyData.gte } : null),
                      ...(surveyData.lte ? { min: surveyData.lte } : null),
                    },
                  }
                : null),

              ...(data?.state ? { state: [data?.state] } : null),
              ...(data?.lga ? { lga: [data?.lga] } : null),
            }
          : {},
      ],
    };

    saveAudience({ targetingOption: userDetails });
  };

  const flattenStateLGA = (data) => {
    return data.flatMap((item) =>
      item.lga.map((lgaItem) => ({
        country: "Nigeria",
        state: item.state,
        lga: lgaItem,
      })),
    );
  };

  const fieldLocArray = flattenStateLGA(fieldLoc);
  console.log(fieldLocArray);

  const checkInState = (stateName, stateArray) => {
    return stateArray?.some((item) => item?.state === stateName);
  };

  const fieldCheckInLoc = (value, data) => {
    // Check if the LGA exists within the selected state
    const stateObj = data?.find((el) => el.state === stateValue.replace(" State", ""));
    return stateObj?.lga.includes(value);
  };

  const handleSelection = (value, title, isFieldInterview) => {
    if (isFieldInterview) {
      // Handle field-interview mode
      setFieldLoc((prevData) => {
        if (title === "state") {
          const associatedLGAs = cityData[value]?.map((lga) => lga.replace(" State", ""));
          const existingStateIndex = prevData.findIndex((el) => el.state === value);

          if (existingStateIndex > -1) {
            // Remove the state if no LGAs are selected
            if (!associatedLGAs.some((lga) => prevData[existingStateIndex].lga.includes(lga))) {
              return prevData.filter((el) => el.state !== value);
            }
          } else {
            // Add new state with empty LGA array
            return [...prevData, { state: value, lga: [] }];
          }
        }

        // Handle LGA selection for the selected state
        if (title === "lga") {
          const stateIndex = prevData.findIndex((el) => el.state === stateValue.replace(" State", ""));
          if (stateIndex > -1) {
            // Update LGAs for the selected state
            const updatedState = {
              ...prevData[stateIndex],
              lga: prevData[stateIndex].lga.includes(value)
                ? prevData[stateIndex].lga.filter((el) => el !== value) // Remove LGA
                : [...prevData[stateIndex].lga, value], // Add LGA
            };

            return [...prevData.slice(0, stateIndex), updatedState, ...prevData.slice(stateIndex + 1)];
          }
        }
        return prevData;
      });
    } else {
      // Handle regular mode
      setData((prevData) => {
        const isValueSelected = prevData[title]?.includes(value);

        if (title === "state") {
          const associatedLGAs = cityData[value]?.map((lga) => lga.replace(" State", ""));
          const hasSelectedLGAs = associatedLGAs?.some((lga) => prevData.lga.includes(lga));

          if (isValueSelected) {
            // If state is selected and no LGAs are selected, remove the state
            return {
              ...prevData,
              state: hasSelectedLGAs ? prevData.state : prevData.state.filter((el) => el !== value),
            };
          } else {
            // If state is not selected, add it to the state array
            return {
              ...prevData,
              state: [...prevData.state, value],
            };
          }
        }

        // Handle LGA or other selections
        return {
          ...prevData,
          [title]: isValueSelected
            ? prevData[title].filter((el) => el !== value) // Remove value if selected
            : [...prevData[title], value], // Add value if not selected
        };
      });
    }
  };

  // Generalized state handler for state or LGA selection
  const handleValue = (id, title) => {
    const stateAbbr = id.replace(" State", "");
    const isFieldInterview = locationArray.includes("field-interview");

    // Set state value (toggle)

    setStateValue(title === "state" ? (stateValue === id ? null : id) : stateValue);
    setStateName(title === "state" ? stateAbbr : stateName);

    // Trigger selection based on mode
    handleSelection(stateAbbr, title, isFieldInterview);
  };

  // Handle city (LGA) selection
  const handleCityValue = (id) => {
    handleValue(id, "lga");
  };

  // Handle state selection
  const handleStateValue = (id) => {
    handleValue(id, "state");
  };

  // ================================= Detailed Targeting functions ===========================

  const areAllObjectsEmpty = (coreData) => {
    return Object.values(coreData).every((obj) => Object.keys(obj).length === 0);
  };

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleTabValue = (id) => {
    setTabValue(tabValue === id ? null : id);
  };

  const handleOptionClick = (id, title, objectValue) => {
    setOptionValue(optionValue === id ? null : id);
    setSelectedCategory(title);

    setCoreData((prevCoreData) => {
      const updatedCores = { ...prevCoreData[objectValue] };
      if (updatedCores[title] && updatedCores[title].length === 0) {
        delete updatedCores[title];
      } else {
        updatedCores[title] = updatedCores[title] || [];
      }
      return {
        ...prevCoreData,
        [objectValue]: updatedCores,
      };
    });
  };

  const checkInArrays = (value, categoryData) => {
    if (!categoryData) {
      return false;
    }
    return Object.values(categoryData).some((array) => array?.includes(value));
  };

  const handleSelectedOption = (value, title, objectValue) => {
    console.log(title, value, "handleSelect");

    setCoreData((prevState) => ({
      ...prevState,
      [objectValue]: {
        ...prevState[objectValue],
        [title]: prevState[objectValue][title]
          ? prevState[objectValue][title].includes(value)
            ? prevState[objectValue][title].filter((el) => el !== value)
            : [...prevState[objectValue][title], value]
          : [value],
      },
    }));
  };
  const transformData = (text) => {
    return text?.replace(/\s+/g, "_")?.toLowerCase();
  };

  const transformToTitleCase = (str) => {
    let formattedStr = str.replace(/_/g, " ");
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1).toLowerCase();
    return formattedStr;
  };

  const dynamicDropdown = () => {
    switch (tabValue) {
      case 1:
        return (
          <div className="w-full space-y-2">
            {coreListOptions.map((el, i) => (
              <div key={el.name}>
                <div
                  className="border-b p-2 flex items-center space-x-2"
                  onClick={() => handleOptionClick(el.value, transformData(el.name), "cores")}
                >
                  <IoMdArrowDropdown />
                  <Typography.SubText>{el.name}</Typography.SubText>
                </div>
                {optionValue === el.value
                  ? el.subcategories?.map((el) => {
                      return (
                        <div
                          key={`${el.name}`}
                          className={`text-xs cursor-pointer p-2 flex items-center space-x-3 ${
                            checkInArrays(el.value, coreData?.cores)
                              ? "border-b-[1px] border-white"
                              : "hover:bg-slate-100"
                          }`}
                          onClick={() => handleSelectedOption(el.value, selectedCategory, "cores")}
                        >
                          <Checkbox checked={checkInArrays(el.value, coreData.cores)} />

                          <p className="text-[12px]">{el.label}</p>
                        </div>
                      );
                    })
                  : null}
              </div>
            ))}
          </div>
        );
      case 2:
        return (
          <div className=" w-full space-y-2">
            {interestList.map((el, i) => (
              <div key={el.name}>
                <div
                  className="border-b p-2 flex items-center space-x-2"
                  onClick={() => handleOptionClick(el.value, transformData(el.name), "interest")}
                >
                  <IoMdArrowDropdown />
                  <Typography.SubText>{el.name}</Typography.SubText>
                </div>
                {optionValue === el.value
                  ? el.subcategories?.map((el) => {
                      return (
                        <div
                          key={`${el.name}`}
                          className={`text-xs cursor-pointer p-2 flex items-center space-x-3 ${
                            checkInArrays(el.value, coreData?.interest)
                              ? "border-b-[1px] border-white"
                              : "hover:bg-slate-100"
                          }`}
                          onClick={() => handleSelectedOption(el.value, selectedCategory, "interest")}
                        >
                          <Checkbox checked={checkInArrays(el.value, coreData.interest)} />

                          <p className="text-[12px]">{el.label}</p>
                        </div>
                      );
                    })
                  : null}
              </div>
            ))}
          </div>
        );
      case 3:
        return (
          <div className=" w-full space-y-2">
            {behaviourialList.map((el, i) => (
              <div key={el.name}>
                <div
                  className="border-b p-2 flex items-center space-x-2"
                  onClick={() => handleOptionClick(el.value, transformData(el.name), "behaviour")}
                >
                  <IoMdArrowDropdown />
                  <Typography.SubText>{el.name}</Typography.SubText>
                </div>
                {optionValue === el.value
                  ? el.subcategories?.map((el) => {
                      return (
                        <div
                          key={`${el.name}`}
                          className={`text-xs cursor-pointer p-2 flex items-center space-x-3 ${
                            checkInArrays(el.value, coreData?.behaviour)
                              ? "border-b-[1px] border-white"
                              : "hover:bg-slate-100"
                          }`}
                          onClick={() => handleSelectedOption(el.value, selectedCategory, "behaviour")}
                        >
                          <Checkbox checked={checkInArrays(el.value, coreData.behaviour)} />

                          <p className="text-[12px]">{el.label}</p>
                        </div>
                      );
                    })
                  : null}
              </div>
            ))}
          </div>
        );
      default:
    }
  };

  // =========================================================================================

  const initialValues = {
    reward:
      savedtargetedAudience?.reward === 200
        ? 0
        : savedtargetedAudience?.reward - 200 || savedData?.targetingOption?.reward === 200
        ? 0
        : savedData?.targetingOption?.reward - 200 || 0,
    expectedResponse: savedtargetedAudience?.expectedResponse || savedData?.targetingOption?.expectedResponse || 0,
    shareToPublic: savedData?.targetingOption?.shareToPublic || false,
    shuffleSampleSet: savedData?.targetingOption?.shuffleSampleSet || false,
    lte: selectedAudienceDemographics?.age?.min || savedDemographics?.age?.min || "",
    gte: selectedAudienceDemographics?.age?.max || savedDemographics?.age?.max || "",
    voucher: "",
    link: "",
    state:
      locationArray[2] === "field-interview"
        ? savedDemographics && savedDemographics.length > 0
          ? savedDemographics[0]?.state || []
          : []
        : selectedAudienceDemographics?.state[0] || savedDemographics?.state?.[0] || [],
    tracker: "Same audience", // Assuming this is a default value
    lga:
      locationArray[2] === "field-interview"
        ? Array.isArray(savedDemographics?.lga)
          ? savedDemographics?.map((el) => [...el.lga])
          : []
        : savedDemographics?.lga?.[0] || "",
  };

  console.log(surveyData, "This is the survey Da/ta");

  useEffect(() => {
    setSurveyData({
      ...surveyData,
      interests: data.interests,
      gender: data.gender,
      state: data.state,
      lga: data.lga,
    });
    setSurveyData((prevSurveyData) => {
      const updatedData = { ...prevSurveyData };

      if (recipientArray.length === 0) {
        delete updatedData.recipientArray;
      } else {
        updatedData.recipientArray = recipientArray;
      }

      return updatedData;
    });
  }, [recipientArray, data]);

  const { mutate: checkMarginError, data: marginData } = useMutation(marginError);

  const { mutate, isLoading } = useMutation(createSurveyTarget, {
    onSuccess: () => {
      Toast.success("Saved");
      setStep(step + 1);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(createSurveyTarget, {
    onSuccess: () => {
      Toast.success("Saved");

      queryClient.invalidateQueries(["onlineSurveyDraft", "brandTrackingDraft", "offline_surveyDraft"]);
      if (locationArray.includes("brand-tracking")) {
        navigate(`/research/brand-tracking`);
      } else if (locationArray.includes("online-survey")) {
        navigate(`/research/online-survey`);
      } else {
        navigate(`/research/field-interview`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleFilter = useCallback(
    (searchTerm) => {
      const filteredData = searchTerm
        ? state?.filter((item) => {
            const content = item.label.toLowerCase();
            return content.includes(searchTerm.toLowerCase());
          })
        : state;

      setFilteredData(filteredData);
    },
    [state],
  );

  useEffect(() => {
    handleFilter(query);
  }, [handleFilter, query]);

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const { handleChange, values, setFieldValue } = useFormik({
    initialValues,
    // onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const updateBrandTracker = (name, value) => {
    setFieldValue("tracker", name);
    setFieldValue("shuffleSampleSet", value);
    if (name === "New audience, same attributes") {
      setShowContact(true);
    } else {
      setRecipientArray([]);
      setShowContact(false);
      return null;
    }
  };

  const handleSave = () => {
    if (locationArray[2] === "field-interview") {
      handleAction();
      const userDetails = {
        audienceType: "offline_survey",
        reward: +values.reward,
        expectedResponse: values.expectedResponse,
        saveLeads: true,
        sendReminderAfter24hrs: false,
        shareToPublic: false,
        shuffleSampleSet: values.shuffleSampleSet,
        demographics: flattenStateLGA(fieldLoc),
      };
      mutate({
        surveyId: id,
        payload: userDetails,
      });
    } else {
      const userDetails = {
        audienceType: CONTACT_TYPE.MOOYI_AUDIENCE,
        reward: +values.reward,
        expectedResponse: values.expectedResponse,
        shuffleSampleSet: values.shuffleSampleSet,
        foreignVoucher: {
          link: values.link,
          code: values.voucher,
        },
        demographics: [
          {
            ...{ detailedTargeting: areAllObjectsEmpty(coreData) ? {} : coreData },
            country: ["Nigeria"],

            ...(values.gte && values.lte
              ? {
                  age: {
                    ...(values.gte ? { max: values.gte } : null),
                    ...(values.lte ? { min: values.lte } : null),
                  },
                }
              : null),
            ...(surveyData.state.length > 0 ? { state: surveyData.state } : null),
            ...(surveyData.lga.length > 0 ? { lga: surveyData.lga } : null),
          },
          values.shuffleSampleSet
            ? {
                ...(surveyData.gender.length > 0 ? { gender: surveyData.gender } : null),
                country: ["Nigeria"],
                ...(surveyData.gte && surveyData.lte
                  ? {
                      age: {
                        ...(surveyData.gte ? { max: surveyData.gte } : null),
                        ...(surveyData.lte ? { min: surveyData.lte } : null),
                      },
                    }
                  : null),

                ...(surveyData?.state.length > 0 ? { state: surveyData?.state } : null),
                ...(surveyData?.lga.length > 0 ? { lga: surveyData?.lga } : null),
              }
            : {},
        ],
      };

      mutate({
        surveyId: id,
        payload: userDetails,
      });
    }
  };

  const exData = [];

  const expansionArray = (state, lgaArray) => {
    lgaArray.forEach((el) => {
      exData.push({ country: "Nigeria", state: state[0], lga: el });
    });
    return exData;
  };

  const handleAction = () => {
    console.log("action");
    if (data?.lga) {
      expansionArray(data?.state, data?.lga);
    }
  };

  const handleSaveAndExit = () => {
    if (locationArray[2] === "field-interview") {
      handleAction();
      const userDetails = {
        audienceType: "offline_survey",
        reward: +values.reward,
        expectedResponse: values.expectedResponse,
        saveLeads: true,
        sendReminderAfter24hrs: false,
        shareToPublic: false,
        shuffleSampleSet: values.shuffleSampleSet,
        demographics: flattenStateLGA(fieldLoc),
      };
      saveMutate({
        surveyId: id,
        payload: userDetails,
      });
    } else {
      const userDetails = {
        audienceType: CONTACT_TYPE.MOOYI_AUDIENCE,
        reward: +values.reward,
        expectedResponse: values.expectedResponse,
        shuffleSampleSet: values.shuffleSampleSet,
        foreignVoucher: {
          link: values.link,
          code: values.voucher,
        },
        demographics: [
          {
            ...{ detailedTargeting: areAllObjectsEmpty(coreData) ? {} : coreData },
            country: ["Nigeria"],

            ...(values.gte && values.lte
              ? {
                  age: {
                    ...(values.gte ? { max: values.gte } : null),
                    ...(values.lte ? { min: values.lte } : null),
                  },
                }
              : null),
            ...(surveyData.state.length > 0 ? { state: surveyData.state } : null),
            ...(surveyData.lga.length > 0 ? { lga: surveyData.lga } : null),
          },
          values.shuffleSampleSet
            ? {
                ...(surveyData.gender.length > 0 ? { gender: surveyData.gender } : null),
                country: ["Nigeria"],
                ...(surveyData.gte && surveyData.lte
                  ? {
                      age: {
                        ...(surveyData.gte ? { max: surveyData.gte } : null),
                        ...(surveyData.lte ? { min: surveyData.lte } : null),
                      },
                    }
                  : null),

                ...(surveyData?.state.length > 0 ? { state: surveyData?.state } : null),
                ...(surveyData?.lga.length > 0 ? { lga: surveyData?.lga } : null),
              }
            : {},
        ],
      };
      saveMutate({
        surveyId: id,
        payload: userDetails,
      });
    }
  };

  useEffect(() => {
    checkMarginError(surveyData.expectedResponse);
  }, [checkMarginError, surveyData.expectedResponse]);

  const deleteEntry = ({ key, value }) => {
    if (key === "gender" || key === "interests" || key === "lga") {
      const newData = data[key].filter((el) => el !== value);
      setData({
        ...data,
        [key]: newData,
      });
    } else {
      setFieldValue(key, "");
    }
  };

  if (isLoading) {
    return <PreLoader />;
  }

  // const disabled = values?.expectedResponse === 0 || values?.expectedResponse === "" || values.state === "";

  const disabled = !values?.expectedResponse || !values?.state;

  return (
    <div>
      {open === 4 ? <SaveAndExitModal handleModal={handleModal} callBackFn={handleSaveAndExit} /> : null}

      <div className="space-y-4 ">
        <div className="w-[100%] flex justify-between">
          <div className="w-[63%] space-y-5">
            <div className="border-[1px] rounded-md p-4 space-y-6">
              <div className="flex items-center justify-between py-1">
                <div className="flex items-center space-x-1">
                  <Typography.SubText className="font-semibold">{headingName}</Typography.SubText>
                  {/* <LuDot className="text-2xl" />
                  <div className="hover:bg-gray-200 p-2 cursor-pointer rounded" onClick={editSurvey}>
                    <FaPencilAlt className="text-gray-600" />
                  </div> */}
                </div>

                <div
                  className="flex items-center justify-center text-xs  cursor-pointer border-[1px] border-primary-800 text-primary-800 w-[130px] h-[40px] rounded-md hover:bg-primary-50"
                  onClick={resetAudience}
                >
                  <p> Reset Audience</p>
                </div>
              </div>
              <div>
                <div className="flex items-center space-x-2 mb-2">
                  <img src={ageIcon} alt="" />
                  <Typography.SubText className="font-semibold">Age range</Typography.SubText>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="w-[38%]">
                    <PrimaryInput type="tel" placeholder={"18"} onChange={handleChange} value={values.lte} name="lte" />
                  </div>

                  <p className="text-xs text-gray-500">to</p>
                  <div className="w-[38%]">
                    <PrimaryInput type="tel" placeholder={"30"} onChange={handleChange} value={values.gte} name="gte" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center space-x-2 mb-2">
                  <img src={profile} alt="" />
                  <Typography.SubText className="font-semibold">Gender</Typography.SubText>
                </div>

                <ul className="flex gap-x-4 mt-1">
                  {genderData.map((elm, i) => {
                    return (
                      <div
                        className={`bg-[#EFF2F6] rounded border-[1px] text-ss border-[#DFE5EC] flex items-center justify-center w-[120px] h-[40px] cursor-pointer ${
                          checkInArray(elm.name, data.gender) ? "bg-primary-800 text-white" : null
                        }`}
                        key={`gender${i}`}
                        onClick={() => handleSelection(elm.name, "gender")}
                      >
                        <p>{elm.name}</p>
                        <div></div>
                      </div>
                    );
                  })}
                </ul>
              </div>
              {/* <div className="flex items-center gap-[1em]">
                <div className="w-[50%] ">
                  <div>
                    <Typography.SubText className="font-semibold pb-2"> State</Typography.SubText>
                    <DropDownWithSearch
                      tag={selectedStateText}
                      type="multiSelect"
                      options={[{ label: "Select State", value: "" }, ...state]}
                      // onSelect={(select) => setFieldValue("state", select.value)}
                      onSelect={(select) => handleSelection(select.value.replace(" State", ""), "state")}
                      name="state"
                      value={selectedStateText}
                    />
                  </div>
                </div>

                {data?.state?.length !== 0 ? (
                  <div className="w-[50%]">
                    <div className="flex space-x-2 items-center ">
                      <Typography.SubText className="font-semibold pb-2">Local Government area(s)</Typography.SubText>
                    </div>
                    <div className="">
                      <DropDownWithSearch
                        type="multiSelect"
                        tag={selectedLgaText}
                        options={citiesArray}
                        onSelect={(select) => handleSelection(select.value, "lga")}
                        name="lga"
                        value={selectedLgaText}
                      />
                    </div>
                  </div>
                ) : null}
              </div> */}

              <div>
                <div className="flex items-center space-x-2">
                  <div className="flex items-center space-x-2">
                    <img src={locationIcon} alt="" />
                    <Typography.SubText className="font-semibold">Location</Typography.SubText>
                  </div>

                  {/* <Tooltip className="" text="Select states and local government you want to reach" /> */}
                </div>
                <div className="mt-2">
                  <div
                    className="flex items-center justify-between border-[1px] border-gray-300 rounded p-2 py-[10px] cursor-pointer"
                    onClick={() => handleModal(10)}
                  >
                    <p className="text-xs">
                      {surveyData?.state?.length > 0 ? (
                        <>
                          {surveyData?.state.length} state selected,{" "}
                          {surveyData?.lga?.length > 0 ? <span>{surveyData?.lga?.length} LGA selected </span> : null}{" "}
                        </>
                      ) : (
                        "Select states and Local Government Area"
                      )}
                    </p>

                    <HiOutlineChevronDown />
                  </div>
                  {open === 10 ? (
                    <div className="border-[1px] border-grey-300 rounded mt-2 overflow-hidden h-[400px] overflow-y-scroll">
                      <div className="p-2">
                        <div className="flex items-center bg-gray-100 border space-x-2 rounded-[4px] h-[35px] px-2">
                          <div>
                            <CiSearch />
                          </div>
                          <div className="w-full">
                            <input
                              type="text"
                              className="w-full border-none focus:outline-none text-[14px] bg-gray-100"
                              onChange={(e) => setQuery(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full space-y-2">
                        {filterData?.map((el, i) => (
                          <div key={`state-${i}`}>
                            <div
                              className="border-b p-2 flex justify-between"
                              onClick={() => handleStateValue(el.value)}
                            >
                              <div
                                className={`text-xs cursor-pointer flex items-center space-x-3 ${
                                  checkInArray(el.value.replace(" State", ""), data?.state) ||
                                  checkInState(el.value.replace(" State", ""), fieldLoc)
                                    ? "border-b-[1px] border-white"
                                    : "hover:bg-slate-100"
                                }`}
                              >
                                <Checkbox
                                  checked={
                                    locationArray.includes("field-interview")
                                      ? checkInState(el.value.replace(" State", ""), fieldLoc)
                                      : checkInArray(el.value.replace(" State", ""), data?.state)
                                  }
                                />
                                <Typography.SubText>{el.label?.replace(" State", "")}</Typography.SubText>
                              </div>
                              <IoMdArrowDropdown />
                            </div>
                            <div>
                              {stateValue === el.value ? (
                                <div className="w-full space-y-2 pl-5">
                                  <p className="px-2 mt-3 flex items-center space-x-2">
                                    <input
                                      type="checkbox"
                                      name="inputProps"
                                      className="pl-0 w-[15px] h-[15px] accent-purple"
                                      onChange={(e) =>
                                        locationArray.includes("field-interview") ? selectAllField(e) : selectAllLga(e)
                                      }
                                    />
                                    <span className="text-xs">Select all</span>
                                  </p>
                                  {citiesArray?.map((el, i) => {
                                    return (
                                      <div
                                        key={`city-${i}`}
                                        className={`text-xs cursor-pointer p-2 flex items-center space-x-3 ${
                                          checkInArray(el.value, data?.lga) || fieldCheckInLoc(el.value, fieldLoc)
                                            ? "border-b-[1px] border-white"
                                            : "hover:bg-slate-100"
                                        }`}
                                        onClick={() => handleCityValue(el.value)}
                                      >
                                        <Checkbox
                                          checked={
                                            locationArray.includes("field-interview")
                                              ? fieldCheckInLoc(el.value, fieldLoc)
                                              : checkInArrays(el.value, data?.lga)
                                          }
                                        />

                                        <p className="text-[12px]">{el.label}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>

                {locationArray.includes("field-interview") ? null : (
                  <div className="w-[100%] mt-5">
                    <div className="flex space-x-2 items-center ">
                      <div className="flex items-center space-x-2">
                        <img src={briefcase} alt="" />
                        <Typography.SubText className="font-semibold">Detailed targeting</Typography.SubText>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div
                        className="flex items-center justify-between border-[1px] border-gray-300 rounded p-2 py-[10px] cursor-pointer"
                        onClick={() => handleModal(2)}
                      >
                        <p className="text-xs">
                          {surveyData.interests.length > 0 ? (
                            <>{surveyData.interests.length} Selected </>
                          ) : (
                            "Select demographics, interests or industries"
                          )}
                        </p>

                        <HiOutlineChevronDown />
                      </div>

                      {open === 2 ? (
                        <div className="border-[1px] border-grey-300 rounded mt-2 overflow-hidden h-[400px] overflow-y-scroll">
                          <div className="p-2">
                            <div className="flex items-center bg-gray-100 border space-x-2 rounded-[4px] h-[35px] px-2">
                              <div>
                                <CiSearch />
                              </div>
                              <div className="w-full">
                                <input
                                  type="text"
                                  className="w-full border-none focus:outline-none text-[14px] bg-gray-100"
                                  onChange={(e) => setQuery(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-full space-y-2">
                            {groups.map((el, i) => (
                              <div>
                                <div
                                  className="border-b p-2 flex justify-between"
                                  key={i}
                                  onClick={() => handleTabValue(el.value)}
                                >
                                  <Typography.SubText>{el.name}</Typography.SubText>
                                  <IoMdArrowDropdown />
                                </div>
                                <div>{tabValue === el.value ? dynamicDropdown() : null}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="border-[1px] rounded-md p-4 space-y-6">
              {/* <Typography.SubText className="font-semibold">Set target responses</Typography.SubText> */}
              <div className="flex items-center space-x-5 justify-between border-b pb-5">
                {/* <div className="flex space-x-4"> */}
                <div className="w-full">
                  <div className="flex items-center space-x-1">
                    <Typography.SubText className="font-semibold">Set your response target</Typography.SubText>
                    <Tooltip text="How many responses are you aiming for?" />
                  </div>

                  <div className="mt-1">
                    <PrimaryInput
                      type="tel"
                      onChange={handleChange}
                      value={values.expectedResponse}
                      name="expectedResponse"
                      placeholder="0"
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="w-full">
                  <div className="flex items-center space-x-1">
                    <Typography.SubText className="font-semibold">
                      (Optional) Set rewards per response
                    </Typography.SubText>
                    <Tooltip text="Respondents can cash out as airtime or to their bank accounts." />
                  </div>

                  <div className="mt-1 ">
                    <PrimaryInput
                      type="tel"
                      onChange={handleChange}
                      value={values.reward}
                      name="reward"
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
              {/* <Typography.SubText className="font-semibold">(Optional) Add a promo code</Typography.SubText> */}
              <div className="flex items-center space-x-5 justify-between">
                {/* <div className="flex space-x-4"> */}
                <div className="w-full">
                  <div className="flex items-center space-x-1">
                    <Typography.SubText className="font-semibold">(Optional) Add a promo code</Typography.SubText>
                    {/* <Tooltip text="Minimum number of responses is 50" /> */}
                  </div>

                  <div className="mt-1">
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      value={values.voucher}
                      name="voucher"
                      placeholder="0"
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="w-full">
                  <div className="flex items-center space-x-1">
                    <Typography.SubText className="font-semibold">
                      Provide instructions to redeem your offer
                    </Typography.SubText>
                    {/* <Tooltip text="Minimum number of responses is 50" /> */}
                  </div>

                  <div className="mt-1 ">
                    <PrimaryInput type="text" onChange={handleChange} value={values.link} name="link" placeholder="" />
                  </div>
                </div>
              </div>
            </div>

            {/* <hr className="w-[100%] " /> */}
          </div>

          <div className="w-[35%] bg-slate-100 p-3 space-y-4">
            <div className="border-slate-200 border-[1px] bg-white rounded-md h-[43rem]">
              <div className="p-4 border-b-[1px] flex items-center justify-between">
                <Typography.SubText className="font-semibold">Audience definition</Typography.SubText>
                <div className="flex items-center space-x-1">
                  {locationArray.includes("field-interview") ? null : (
                    <>
                      <Typography.SubText
                        className="font-semibold text-primary-800 cursor-pointer "
                        onClick={() => handleAudienceModal(2)}
                      >
                        Saved audience(s)
                      </Typography.SubText>
                      <div
                        onMouseEnter={() => setShow(true)}
                        onMouseLeave={() => setShow(false)}
                        className="cursor-pointer relative "
                      >
                        <IoMdInformationCircle className="text-sm" />
                        {show ? (
                          <span className="w-[200px] h-auto rounded-[4px] bg-white shadow-primary p-2 text-[10px] absolute top-4 z-50 right-0 border-[1px]">
                            View or edit saved audience groups
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {values.lte !== "" ||
              values.gte !== "" ||
              data.gender.length !== 0 ||
              data.state.length !== 0 ||
              data.lga.length !== 0 ||
              fieldLocArray?.length !== 0 ||
              areAllObjectsEmpty(coreData) !== true ? (
                <div className="p-4 h-[36rem] overflow-y-scroll">
                  {values.lte !== "" && values.gte !== "" ? (
                    <>
                      <div className="space-y-2">
                        <Typography.SubText className="font-semibold">Age</Typography.SubText>

                        <Typography.SubText>
                          {values.lte} to {values.gte}
                        </Typography.SubText>
                      </div>

                      <hr className="w-[100%] my-3" />
                    </>
                  ) : null}

                  {data.gender.length !== 0 ? (
                    <>
                      <div className="space-y-2">
                        <Typography.SubText className="font-semibold">Gender</Typography.SubText>

                        <div className="flex items-center flex-wrap gap-2">
                          {data.gender.map((el) => (
                            <TagComponent
                              text={el}
                              key={el}
                              onDelete={() =>
                                deleteEntry({
                                  key: "gender",
                                  value: el,
                                })
                              }
                            />
                          ))}
                        </div>
                      </div>

                      <hr className="w-[100%] my-3" />
                    </>
                  ) : null}

                  {fieldLocArray?.length !== 0 || data?.state?.length !== 0 ? (
                    <>
                      <div className="space-y-2">
                        <Typography.SubText className="font-semibold">State</Typography.SubText>

                        <div className="flex items-center flex-wrap gap-2">
                          {/* <TagComponent
                            text={values.state}
                            onDelete={() =>
                              deleteEntry({
                                key: "state",
                                value: "",
                              })
                            }
                          /> */}
                          {locationArray.includes("field-interview")
                            ? fieldLocArray?.map((el, i) => {
                                if (!renderedStates.has(el.state)) {
                                  renderedStates.add(el.state);
                                  return <TagComponent text={el.state} key={i} />;
                                }
                                return null;
                              })
                            : data?.state?.map((el) => (
                                <TagComponent
                                  text={el}
                                  key={el}
                                  onDelete={() =>
                                    deleteEntry({
                                      key: "state",
                                      value: el,
                                    })
                                  }
                                />
                              ))}
                        </div>
                      </div>
                      <hr className="w-[100%] my-3" />
                    </>
                  ) : null}

                  {fieldLocArray?.length !== 0 || data?.lga?.length !== 0 ? (
                    <>
                      <div className="space-y-2">
                        <Typography.SubText className="font-semibold">Local Government Area(s)</Typography.SubText>

                        <div className="flex items-center flex-wrap gap-2">
                          {
                            // !locationArray.includes("field-interview") ? (
                            //   <TagComponent
                            //     text={values.lga}
                            //     onDelete={() =>
                            //       deleteEntry({
                            //         key: "lga",
                            //         value: "",
                            //       })
                            //     }
                            //   />
                            // ) :
                            locationArray.includes("field-interview")
                              ? fieldLocArray?.map((el, i) => <TagComponent text={el?.lga} key={i} />)
                              : data?.lga?.map((el) => (
                                  <TagComponent
                                    text={el}
                                    key={el}
                                    onDelete={() =>
                                      deleteEntry({
                                        key: "lga",
                                        value: el,
                                      })
                                    }
                                  />
                                ))
                          }
                        </div>
                      </div>
                      <hr className="w-[100%] my-3" />
                    </>
                  ) : null}

                  <>
                    {/* Render Cores */}
                    {!isObjectEmpty(coreData.cores) && (
                      <div className="space-y-2">
                        <div className="flex items-center w-full space-x-1 mt-3">
                          <Typography.SubText className="font-bold">Cores</Typography.SubText>
                          <hr className="w-[100%]" />
                        </div>
                        {Object.keys(coreData.cores).map((key) => (
                          <div key={key}>
                            <Typography.SubText className="font-semibold">
                              {transformToTitleCase(key)}:
                            </Typography.SubText>
                            <div className="flex items-center flex-wrap gap-2 mt-2">
                              {coreData.cores[key].map((item) => (
                                <TagComponent
                                  text={item}
                                  key={item}
                                  onDelete={() =>
                                    deleteEntry({
                                      key: "cores",
                                      value: item,
                                      category: key, // to know which key to delete from
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Render Interests */}
                    {!isObjectEmpty(coreData.interest) && (
                      <div className="space-y-2">
                        <div className="flex items-center w-full space-x-1 mt-3">
                          <Typography.SubText className="font-bold">Interests</Typography.SubText>
                          <hr className="w-[100%]" />
                        </div>

                        {Object.keys(coreData.interest).map((key) => (
                          <div key={key}>
                            <Typography.SubText className="font-semibold">
                              {transformToTitleCase(key)}
                            </Typography.SubText>
                            <div className="flex items-center flex-wrap gap-2 mt-2">
                              {coreData.interest[key].map((item) => (
                                <TagComponent
                                  text={item}
                                  key={item}
                                  onDelete={() =>
                                    deleteEntry({
                                      key: "interest",
                                      value: item,
                                      category: key, // to know which key to delete from
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Render Behaviour */}
                    {!isObjectEmpty(coreData.behaviour) && (
                      <div className="space-y-2">
                        <div className="flex items-center w-full space-x-1 mt-3">
                          <Typography.SubText className="font-bold">Behavioral</Typography.SubText>
                          <hr className="w-[100%]" />
                        </div>

                        {Object.keys(coreData.behaviour).map((key) => (
                          <div key={key}>
                            <Typography.SubText className="font-semibold">
                              {transformToTitleCase(key)}
                            </Typography.SubText>
                            <div className="flex items-center flex-wrap gap-2 mt-2">
                              {coreData.behaviour[key].map((item) => (
                                <TagComponent
                                  text={item}
                                  key={item}
                                  onDelete={() =>
                                    deleteEntry({
                                      key: "behaviour",
                                      value: item,
                                      category: key,
                                    })
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                </div>
              ) : (
                // <div className="h-[100%] w-[70%] mx-auto flex items-center ">
                //   <EmptyState
                //     content="You haven’t chosen an audience yet. Select a group to send your survey and start gathering responses."
                //     icon={emptySurvey}
                //   />
                // </div>
                <div className="flex items-center flex-col justify-center h-[40vh] w-[100%] space-y-3">
                  <img src={emptySurvey} alt="target_empty" className="w-[70px] mx-auto" />
                  {/* <Typography.Text className="text-center">{text}</Typography.Text> */}
                  <Typography.SubText className="text-center text-gray-500 w-[80%]">
                    You haven’t chosen an audience yet. Select a group to send your survey and start gathering
                    responses.
                  </Typography.SubText>
                </div>
              )}
              {!areAllObjectsEmpty(coreData) ? (
                <div className="flex items-center w-full justify-end px-5 py-2 border-t">
                  <GreyButton text="Save audience" onClick={() => handleAudienceModal(1)} />
                </div>
              ) : null}
              {openSavedModal === 1 ? (
                <Modal title="Save audience" onClose={() => handleAudienceModal()}>
                  <div className="w-[516px] px-5">
                    <p className="text-m font-semibold my-2">Give your audience a unique name</p>
                    <form action="">
                      <div className="mt-5 space-y-5">
                        <div className="w-full">
                          <SecondaryInput
                            text="Name"
                            placeholder="Enter audience name"
                            onChange={(e) => handleAudienceChange(e)}
                            value={audience.name}
                            name="name"
                            type="text"
                          />
                        </div>
                        <div className="w-full">
                          <SecondaryInput
                            text="Description (optional)"
                            placeholder="Describe your audience"
                            onChange={(e) => handleAudienceChange(e)}
                            value={audience.description}
                            name="description"
                            type="text"
                          />
                          <p className="text-ss text-gray-400">
                            Describe your audience in a few words to make it easy to identify.
                          </p>
                        </div>
                      </div>
                      <div className="w-full my-5">
                        <div className="flex justify-end space-x-5 mt-[3em]">
                          <div>
                            <GreyButton text="Cancel" onClick={() => handleAudienceModal()} />
                          </div>
                          <div className="flex space-x-2">
                            <BlueButton
                              type="button"
                              text={"Save"}
                              css=""
                              onClick={handleSaveAudience}
                              disabled={audience.name === ""}
                              loading={loadAudience}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Modal>
              ) : null}
              {openSavedModal === 2 ? (
                <SavedAudienceSettings
                  audienceData={savedAudienceData}
                  onClose={handleAudienceModal}
                  setSelectedAudience={setSelectedAudience}
                  setCoreData={setCoreData}
                />
              ) : null}
            </div>
            <div className="border-slate-200 p-4 border-[1px] bg-white rounded-md space-y-3">
              <Typography.SubText className="font-semibold">Estimated reach</Typography.SubText>

              {values.lte !== "" ||
              values.gte !== "" ||
              data.gender.length !== 0 ||
              values.state !== "" ||
              values.lga !== "" ||
              data.interests.length !== 0 ? (
                <Typography.SubText>2,000 - 5,000</Typography.SubText>
              ) : (
                <Typography.SubText>3,000,000</Typography.SubText>
              )}
            </div>
          </div>
        </div>

        {!locationArray.includes("field-interview") &&
        draftedData?.data?.surveyDetails?.recurringProp?.recurringInterval !== undefined &&
        draftedData?.data?.surveyDetails?.recurringProp?.recurringInterval !== "" ? (
          <div className="border-[1px] p-4 rounded-md">
            <div>
              <div className="flex items-center space-x-1">
                <Typography.SubText className="font-semibold">Audience settings</Typography.SubText>
                <Tooltip text="Minimum number of responses is 50" />
              </div>

              <p className="text-xs text-gray-600 mt-1">Select contacts to reshare your survey to</p>

              <div className="flex items-start justify-between">
                <div className="space-y-2 mt-2">
                  {tracker.map((el) => {
                    return (
                      <div
                        className="flex items-center space-x-3 cursor-pointer"
                        key={el}
                        onClick={() => updateBrandTracker(el.name, el.value)}
                      >
                        <div
                          className={`border-[1px] flex items-center justify-center rounded-full w-[15px] h-[15px] ${
                            values.tracker === el.name ? "border-primary-800" : "border-grey-600"
                          }`}
                        >
                          <div
                            className={`rounded-full w-[9px] h-[9px] ${
                              values.tracker === el.name ? " bg-primary-800" : ""
                            }`}
                          ></div>
                        </div>
                        <Typography.SubText className="text-sm text-gray-700">{el.name}</Typography.SubText>

                        {values.tracker === "New audience, different attributes" &&
                          el.name === "New audience, different attributes" && (
                            <BsPenFill onClick={() => handleModal(1)} />
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {showContact ? (
              <div className="w-[50%] mt-5">
                <SavedContacts
                  data={surveyData}
                  setData={setSurveyData}
                  populatedArray={recipientArray}
                  setPopulatedArray={setRecipientArray}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <BottomBar
          step={step}
          setStep={setStep}
          handleSave={handleSave}
          handleModal={() => handleModal(4)}
          disableButton={disabled}
        />
      </div>
      {open === 1 ? (
        <OptionsModal
          setSurveyData={setSurveyData}
          surveyData={surveyData}
          newStateData={state}
          handleModal={() => handleModal(1)}
        />
      ) : null}
    </div>
  );
};

export default TargetingOptions;
