import SelectInput from "components/Inputs/SelectInput";
import { BsFillCloudArrowUpFill, BsImage } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { IoCaretDown } from "react-icons/io5";
import { MdOutlineCancel, MdOutlineVideoLibrary } from "react-icons/md";
import { RxSlider } from "react-icons/rx";
import Toggle from "react-toggle";
import { checkInArray } from "./checkInArray";
import { RiDashboardFill } from "react-icons/ri";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { FaCircle, FaHeart, FaStar } from "react-icons/fa";
import Dropdown from "../components/DropDown";
import { BiSolidCoin } from "react-icons/bi";

import { CoinIcon } from "assets/icons/icon";
import { IoStar } from "react-icons/io5";
import { PiNumberOneBold } from "react-icons/pi";

export const surveyIcons = (type) => {
  switch (type) {
    case "text_response":
      return (
        <div className="w-[20px] my-[6px]">
          <p className="w-[20px] h-[2px] bg-neutral-500 rounded-sm"></p>
          <div className="flex mt-[2px] space-x-[2px]">
            <p className="w-[8px] h-[px] bg-neutral-500 rounded-sm"></p>
            <p className="w-[6px] h-[2px] bg-neutral-500 rounded-sm"></p>
          </div>
        </div>
      );
    case "multi_choice":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center border-[1px] border-grey-1 rounded-[6px]">
          <IoMdCheckmark className="text-grey-1 text-[14px]" />
        </div>
      );
    case "single_choice":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center border-[1px] border-grey-1 rounded-full">
          <IoMdCheckmark className="text-grey-1 text-[14px]" />
        </div>
      );
    case "likert":
      return (
        <div className="text-[18px] w-[20px]">
          <RxSlider />
        </div>
      );
    case "drop_down":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center border-[1px] border-grey-1 rounded-[6px]">
          <IoCaretDown className="text-grey-1 text-[14px]" />
        </div>
      );
    case "image":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center  rounded-[6px]">
          <BsImage className="text-grey-1 text-[14px]" />
        </div>
      );

    case "matrix":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center  rounded-[6px]">
          <RiDashboardFill className="text-grey-1 text-[14px]" />
        </div>
      );

    case "ranking":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center  rounded-[6px]">
          <PiArrowsDownUpFill className="text-grey-1 text-[14px]" />
        </div>
      );

    case "rating":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center  rounded-[6px]">
          <FaStar className="text-grey-1 text-[14px]" />
        </div>
      );

    case "video":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center  rounded-[6px]">
          <MdOutlineVideoLibrary className="text-grey-1 text-[14px]" />
        </div>
      );

    case "audio_video":
      return (
        <div className="w-[18px] h-[18px] flex items-center justify-center  rounded-[6px]">
          <MdOutlineVideoLibrary className="text-grey-1 text-[14px]" />
        </div>
      );
    default:
  }
};

export const ratingShapes = [
  {
    name: "coin",
    icon: <CoinIcon />,
  },
  {
    name: "star",
    icon: <IoStar className="text-yellow-500 text-base" />,
  },

  {
    name: "heart",
    icon: <FaHeart className="text-red-600 text-base" />,
  },
  {
    name: "circle",
    icon: <FaCircle className="text-green-600 text-base" />,
  },

  // {
  //   name: "number",
  //   icon: <PiNumberOneBold className="text-primary-600 text-base" />,
  // },
];

export const checkRatingIconType = (x) => {
  switch (x) {
    case "coin":
      return <CoinIcon />;
    case "star":
      return <IoStar className="text-yellow-500 text-base" />;
    case "heart":
      return <FaHeart className="text-red-600 text-base" />;
    case "circle":
      return <FaCircle className="text-green-600 text-base" />;
    case "number":
      return <PiNumberOneBold className="text-primary-600 text-base" />;

    default:
  }
};

export const surveyTypes = [
  "text_response",
  "single_choice",
  "multi_choice",
  "drop_down",
  "likert",
  "matrix",
  "ranking",
  "rating",
  "image",
  "video",
];

export const surveyType = (x) => {
  switch (x) {
    case "text_response":
      return "Text Response";
    case "likert":
      return "Likert";
    case "single_choice":
      return "Single Choice";
    case "multi_choice":
      return "Multi Choice";
    case "drop_down":
      return "Drop Down";
    case "image":
      return "Image";
    case "video":
      return "Video";
    case "matrix":
      return "Matrix";
    case "ranking":
      return "Ranking";
    case "rating":
      return "Rating";
    case "audio_video":
      return "Audio / Video";

    default:
  }
};

export const cardType = (x) => {
  switch (x) {
    case "default_view":
      return "Default View";
    case "column_chart":
      return "Column Chart";
    case "pie_chart":
      return "Pie Chart";
    default:
  }
};

export const option1 = Array.from(Array(2).keys()).map((e) => ({
  label: e,
  value: e,
}));

export const option2 = Array.from(Array(9).keys()).map((e) => ({
  label: +e + 2,
  value: +e + 2,
}));

export const ratingOption = Array.from(Array(10).keys()).map((e) => ({
  label: +e + 1,
  value: +e + 1,
}));

export const shapeOption = [
  {
    label: (
      <div className={"flex items-center space-x-2"}>
        <BiSolidCoin className={"text-yellow-600"} /> <p className={"text-xs"}>Coin</p>{" "}
      </div>
    ),
    value: "coin",
  },
  {
    label: (
      <div className={"flex items-center space-x-2"}>
        <FaStar className={"text-yellow-600"} /> <p className={"text-xs"}>Star</p>{" "}
      </div>
    ),
    value: "star",
  },

  {
    label: (
      <div className={"flex items-center space-x-2"}>
        <FaHeart className={"text-red-700"} /> <p className={"text-xs"}>Heart</p>{" "}
      </div>
    ),
    value: "heart",
  },
  {
    label: (
      <div className={"flex items-center space-x-2"}>
        <FaCircle className={"text-green-700"} /> <p className={"text-xs"}>Circle</p>{" "}
      </div>
    ),
    value: "circle",
  },
];

export const surveyOption = (
  x,
  index,
  handleLikertType,
  surveyQuestion,
  createValueOptions,
  handleOptionValues,
  deleteValueOptions,
  addMooyiOptions,
  createMatrixOptions,
  deleteMatrixOptions,
  handleMatrixValues,
  handleRatingTextType,
  handleRatingSelectType,
  handleRatingDropDownType,
) => {
  switch (x) {
    case "text_response":
      return null;
    case "likert":
      return (
        <div className="w-[60%]">
          <div className="flex space-x-4 w-[70%] my-3 items-center">
            <SelectInput
              options={option1}
              value={+surveyQuestion?.options?.min.value}
              name="value"
              onChange={(e) => handleLikertType(e, index, "likertMin")}
            />
            <p className="text-xs">to</p>
            <SelectInput
              options={option2}
              value={+surveyQuestion?.options?.max.value}
              name="value"
              onChange={(e) => handleLikertType(e, index, "likertMax")}
            />
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex space-x-4">
              <p className="text-xs w-[20px]">{surveyQuestion?.options?.min.value || 0}</p>
              <input
                type="text"
                placeholder="Add label Optional"
                className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                value={surveyQuestion?.options?.min.label}
                name="label"
                onChange={(e) => handleLikertType(e, index, "likertMin")}
              />
            </div>

            <div className="flex space-x-4">
              <p className="text-xs w-[20px]">{surveyQuestion?.options?.max.value || 2}</p>
              <input
                type="text"
                placeholder="Add label Optional"
                className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                value={surveyQuestion?.options?.max.label}
                name="label"
                onChange={(e) => handleLikertType(e, index, "likertMax")}
              />
            </div>
          </div>
        </div>
      );

    case "rating":
      return (
        <div className="w-[60%]">
          <div className="flex space-x-4 w-[100%] my-3 items-center">
            <div>
              <p className={"text-xs text-gray-500"}>Set number of icons to display</p>
              <SelectInput
                options={ratingOption}
                value={+surveyQuestion?.options?.iconQuantity}
                name="iconQuantity"
                onChange={(e) => handleRatingSelectType(e, index)}
              />
            </div>

            <div className={"w-[50%] "}>
              <p className={"text-xs text-gray-500"}>Choose a shape</p>
              <div className={"my-2 w-[100%]"}>
                <Dropdown
                  options={shapeOption}
                  selectedValue={surveyQuestion.options?.shape}
                  name="shape"
                  onSelect={(e) => handleRatingDropDownType(e, index)}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4 w-[100%]">
            <div className="flex space-x-2 w-[100%]">
              <p className="text-xs w-[70px]">First Icon</p>
              <input
                type="text"
                placeholder="Add label Optional"
                className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                value={surveyQuestion?.options?.label?.first}
                name="first"
                onChange={(e) => handleRatingTextType(e, index)}
              />
            </div>

            <div className="flex space-x-2 w-[100%]">
              <p className="text-xs w-[70px]">Last Icon</p>
              <input
                type="text"
                placeholder="Add label Optional"
                className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                value={surveyQuestion?.options?.label?.last}
                name="last"
                onChange={(e) => handleRatingTextType(e, index)}
              />
            </div>
          </div>
        </div>
      );
    case "single_choice":
      return (
        <div className="w-[100%]">
          {surveyQuestion?.options?.map((el, i) => {
            return (
              <div>
                {surveyQuestion.options[i] === "MooyiOption" ? null : (
                  <div className="flex space-x-3 my-3">
                    <p className="text-s w-[20px]">{i + 1}</p>
                    <input
                      type="text"
                      placeholder="Add label Optional"
                      className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                      value={surveyQuestion.options[i]}
                      name="value"
                      onChange={(e) => handleOptionValues(e, index, i)}
                    />
                    <MdOutlineCancel
                      className="text-[24px] text-grey-1 cursor-pointer"
                      onClick={() => deleteValueOptions(index, i)}
                    />
                  </div>
                )}
              </div>
            );
          })}

          <div className="flex justify-between w-[100%]">
            <div
              className="flex space-x-2 items-center cursor-pointer my-[1em]"
              onClick={() => createValueOptions(index)}
            >
              <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
              <p className="text-ss text-primary-800">Add Option</p>
            </div>

            <div className="flex items-center justify-between space-x-2">
              <p className="text-xs">Add Other</p>

              <Toggle
                checked={checkInArray("MooyiOption", surveyQuestion.options)}
                icons={false}
                onChange={() => addMooyiOptions(index, "MooyiOption")}
                className="toggle"
              />
            </div>
          </div>
        </div>
      );
    case "multi_choice":
      return (
        <div className="w-[100%]">
          {surveyQuestion?.options?.map((el, i) => {
            return (
              <>
                {surveyQuestion.options[i] === "MooyiOption" ? null : (
                  <div className="flex space-x-3 my-3">
                    <p className="text-s w-[20px]">{i + 1}</p>
                    <input
                      type="text"
                      placeholder="Add label Optional"
                      className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                      value={surveyQuestion.options[i]}
                      name="value"
                      onChange={(e) => handleOptionValues(e, index, i)}
                    />
                    <MdOutlineCancel
                      className="text-[24px] text-grey-1 cursor-pointer"
                      onClick={() => deleteValueOptions(index, i)}
                    />
                  </div>
                )}
              </>
            );
          })}

          <div className="flex justify-between w-[100%]">
            <div
              className="flex space-x-2 items-center cursor-pointer my-[1em]"
              onClick={() => createValueOptions(index)}
            >
              <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
              <p className="text-ss text-primary-800">Add Option</p>
            </div>

            <div className="flex items-center justify-between space-x-2">
              <p className="text-xs">Add Other</p>

              <Toggle
                checked={checkInArray("MooyiOption", surveyQuestion.options)}
                icons={false}
                onChange={() => addMooyiOptions(index, "MooyiOption")}
                className="toggle"
              />
            </div>
          </div>
        </div>
      );
    case "ranking":
      return (
        <div className="w-[60%]">
          {surveyQuestion?.options?.map((el, i) => {
            return (
              <div className="flex space-x-3 my-3">
                <p className="text-s w-[20px]">{i + 1}</p>
                <input
                  type="text"
                  placeholder="Add label Optional"
                  className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                  value={surveyQuestion.options[i]}
                  name="value"
                  onChange={(e) => handleOptionValues(e, index, i)}
                />
                <MdOutlineCancel
                  className="text-[24px] text-grey-1 cursor-pointer"
                  onClick={() => deleteValueOptions(index, i)}
                />
              </div>
            );
          })}
          <div
            className="flex space-x-2 items-center cursor-pointer my-[1em]"
            onClick={() => createValueOptions(index)}
          >
            <div className="border-[1px] w-[13px] h-[13px]  rounded-[3px] border-purple"></div>
            <p className="text-ss text-primary-800">Add Option</p>
          </div>
        </div>
      );
    case "drop_down":
      return (
        <div className="w-[60%]">
          {surveyQuestion?.options?.map((el, i) => {
            return (
              <div className="flex space-x-3 my-3">
                <p className="text-s w-[20px]">{i + 1}</p>
                <input
                  type="text"
                  placeholder="Add label Optional"
                  className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                  value={surveyQuestion.options[i]}
                  name="value"
                  onChange={(e) => handleOptionValues(e, index, i)}
                />
                <MdOutlineCancel
                  className="text-[24px] text-grey-1 cursor-pointer"
                  onClick={() => deleteValueOptions(index, i)}
                />
              </div>
            );
          })}

          <div
            className="flex space-x-2 items-center cursor-pointer my-[1em]"
            onClick={() => createValueOptions(index)}
          >
            <div className="w-[13px] h-[13px] text-primary-800 flex items-center justify-center">
              <IoCaretDown />
            </div>
            <p className="text-ss text-primary-800">Add Option</p>
          </div>
        </div>
      );
    case "image":
      return (
        <div className="w-[100%] border-[1px] h-[100px] rounded-lg mt-2 flex items-center justify-center">
          <BsFillCloudArrowUpFill className="text-[42px] text-grey" />
        </div>
      );

    case "video":
      return (
        <div className="w-[100%] border-[1px] h-[100px] rounded-lg mt-2 flex items-center justify-center">
          <BsFillCloudArrowUpFill className="text-[42px] text-grey" />
        </div>
      );

    case "matrix":
      return (
        <div className="w-[100%]  rounded-lg mt-2 flex items-start justify-between gap-6">
          <div className="w-[50%]">
            <p className="text-sm">Rows</p>

            {surveyQuestion?.options?.row.map((el, i) => {
              return (
                <div className="flex space-x-3 my-3">
                  <p className="text-s flex justify-end w-[10px]">{i + 1}</p>
                  <input
                    type="text"
                    placeholder="Add label Optional"
                    className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                    value={surveyQuestion.options.row[i]}
                    name="value"
                    onChange={(e) => handleMatrixValues(e, index, i, "row")}
                  />
                  <MdOutlineCancel
                    className="text-[24px] text-grey-1 cursor-pointer"
                    onClick={() => deleteMatrixOptions(index, i, "row")}
                  />
                </div>
              );
            })}

            <div
              className="flex space-x-2 items-center cursor-pointer my-[1em]"
              onClick={() => createMatrixOptions(index, "row")}
            >
              <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
              <p className="text-ss text-primary-800">Add Rows</p>
            </div>
          </div>

          <div className="w-[50%]">
            <p className="text-sm">Columns</p>

            {surveyQuestion?.options?.column.map((el, i) => {
              return (
                <div className="flex space-x-3 my-3">
                  <p className="text-s flex justify-end w-[10px]">{i + 1}</p>
                  <input
                    type="text"
                    placeholder="Add label Optional"
                    className="border-b-[1px] outline-none bg-transparent text-xs placeholder:text-xs w-[100%]"
                    value={surveyQuestion.options.column[i]}
                    name="value"
                    onChange={(e) => handleMatrixValues(e, index, i, "column")}
                  />
                  <MdOutlineCancel
                    className="text-[24px] text-grey-1 cursor-pointer"
                    onClick={() => deleteMatrixOptions(index, i, "column")}
                  />
                </div>
              );
            })}

            <div
              className="flex space-x-2 items-center cursor-pointer my-[1em]"
              onClick={() => createMatrixOptions(index, "column")}
            >
              <div className="border-[1px] w-[13px] h-[13px] rounded-full border-purple"></div>
              <p className="text-ss text-primary-800">Add Columns</p>
            </div>
          </div>
        </div>
      );
    default:
  }
};
