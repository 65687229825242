import { OptionalIcon } from "assets/icons/survey/OptionalIcon";
import { SkipLogicIcon } from "assets/icons/survey/SkipLogicIcon";
import { cn } from "helpers/classHelpers";
import { surveyTypes } from "lib/surveyFunctions";
import { surveyType } from "lib/surveyFunctions";
import { surveyIcons } from "lib/surveyFunctions";
import React, { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { LuPlus } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const Questions = ({ surveyQuestionsArray, setSurveyQuestionsArray, surveyQuestion, newCardRef }) => {
  const [open, setOpen] = useState("");
  const location = useLocation();
  const pathArray = location.pathname.split("/");

  const createQuestion = (value) => {
    if (newCardRef.current) {
      newCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    if (value === "likert") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: {
            max: {
              value: 10,
              label: "",
            },
            min: {
              value: 1,
              label: "",
            },
          },
        },
      ]);
    } else if (value === "rating") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: {
            max: {
              value: 10,
              label: "",
            },
            min: {
              value: 1,
              label: "",
            },
          },
        },
      ]);
    } else if (value === "matrix") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: {
            row: [],
            column: [],
            multichoice: false,
          },
        },
      ]);
    } else if (value === "text_response") {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
        },
      ]);
    } else {
      setSurveyQuestionsArray([
        ...surveyQuestionsArray,
        {
          ...surveyQuestion,
          questionType: value,
          options: [],
        },
      ]);
    }

    handleOpen();
  };

  const handleOpen = (id) => {
    setOpen(open === id ? null : id);
  };

  const surveyTypeArray = pathArray.includes("field-interview") ? ["text_response"] : surveyTypes;

  const hasNonNullSkipLogic = (question) => {
    const skipLogicValues = Object.values(question.skipLogic || {});
    return skipLogicValues.some((value) => value !== null);
  };

  console.log(surveyQuestionsArray, "here");

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    console.log(result, "result");

    // If there is no destination (dropped outside the droppable area), do nothing
    if (!destination) return;

    // If the item was dropped in the same position, do nothing
    if (destination.index === source.index) return;

    // Create a copy of the surveyQuestionsArray to work with
    const reorderedArray = Array.from(surveyQuestionsArray);

    // Remove the dragged item from its source position
    const [movedItem] = reorderedArray.splice(source.index, 1);

    // Insert the dragged item into its new position
    reorderedArray.splice(destination.index, 0, movedItem);

    // Update the state with the reordered array
    setSurveyQuestionsArray(reorderedArray);
  };

  return (
    <div className="h-[100%]">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="surveyTable" direction="vertical">
          {(provided) => (
            <div className="mt-4 space-y-2" {...provided.droppableProps} ref={provided.innerRef}>
              <>
                {surveyQuestionsArray.map((el, i) => (
                  <Draggable key={`surveyTable-${i}`} draggableId={`surveyTable-${i}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className={cn(
                          "flex items-start  min-h-[35px] border-[1px] pb-2 rounded-md p-2",
                          snapshot.isDragging ? "bg-primary-50 shadow-md" : "",
                        )}
                        // ref={newCardRef}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          // boxShadow: snapshot.isDragging ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                        }}
                        key={`sideQuestion-${i}`}
                      >
                        <div className="flex ">
                          <div className="min-w-[30px]">{surveyIcons(el.questionType)}</div>
                          <p className="text-xs min-w-[30px]">{i + 1}.</p>
                        </div>

                        <p
                          className={cn(
                            "text-xs w-[70%]",
                            hasNonNullSkipLogic(surveyQuestionsArray[i]) ? "w-[70%]" : "w-[90%]",
                          )}
                        >
                          {el.question}
                        </p>

                        {hasNonNullSkipLogic(surveyQuestionsArray[i]) ? (
                          <div className="bg-primary-50 flex justify-center items-center px-2 py-1 rounded-md">
                            <SkipLogicIcon />
                          </div>
                        ) : null}

                        {el.optional ? (
                          <div className="bg-primary-50 flex justify-center items-center px-2 py-1 rounded-md h-[30px] w-[30px]">
                            <OptionalIcon />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </Draggable>
                ))}
              </>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* {surveyQuestionsArray.map((el, i) => {
          return (
            <div className="flex items-start  min-h-[35px] border-b-[1px] pb-2" ref={newCardRef} key={`sideQues-${i}`}>
              <div className="min-w-[30px]">{surveyIcons(el.questionType)}</div>
              <p className="text-xs min-w-[30px]">{i + 1}.</p>

              <p
                className={cn("text-xs w-[70%]", hasNonNullSkipLogic(surveyQuestionsArray[i]) ? "w-[70%]" : "w-[90%]")}
              >
                {el.question}
              </p>

              {hasNonNullSkipLogic(surveyQuestionsArray[i]) ? (
                <div className="bg-primary-50 flex justify-center items-center px-2 py-1 rounded-md">
                  <SkipLogicIcon />
                </div>
              ) : null}

              {el.optional ? (
                <div className="bg-primary-50 flex justify-center items-center px-2 py-1 rounded-md h-[30px] w-[30px]">
                  <OptionalIcon />
                </div>
              ) : null}
            </div>
          );
        })} */}

      <div className="relative">
        <div
          className="border-[1px] h-[35px] flex items-center px-2 rounded hover:bg-slate-50 cursor-pointer justify-between text-neutral-700 my-2"
          onClick={() => handleOpen(1)}
        >
          <div className="flex items-center space-x-2">
            <LuPlus />

            <p className="text-xs">Add question</p>
          </div>

          <IoChevronDown />
        </div>

        {open === 1 ? (
          <div className="shadow-md bg-white overflow-hidden rounded-[8px] absolute top-[2.5em] right-2 w-[230px] z-20 border-[1px] ">
            {surveyTypeArray.map((elm) => {
              return (
                <div
                  className="flex items-center space-x-2 h-[35px] text-slate-700 hover:bg-slate-50 pl-4  cursor-pointer "
                  key={elm}
                  onClick={() => createQuestion(elm)}
                >
                  <div className="w-[30px]">{surveyIcons(elm)}</div>
                  <p className="text-ss">{surveyType(elm)}</p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Questions;
